import ImageCarousel from './image-carousel';

class SitePlanModal {
    constructor(parent) {
        this.parent = parent; 
        this.handleOutsideFocus = this.handleOutsideFocus.bind(this);
    }

    initModal() {
        const gridContainer = this.parent.element.querySelector('.o-site-plan__list-container');

        gridContainer.addEventListener('click', (event) => {
            let target = event.target;
            while (target !== gridContainer) {
                if (target.matches('.o-site-plan__list-item.c-link')) {
                    event.preventDefault();
                    document.querySelector('body').classList.add('modal-open');
                    this.triggeringElement = target;
                    const propertyData = this.getPropertyData(target);
                    if (propertyData) {
                        this.populateModal(propertyData);
                        const modal = this.parent.element.querySelector('.o-site-plan__modal-wrapper');
                        setTimeout(function () {
                            modal.classList.add('active');
                        }, 10);
                    }
                    return;
                }
                target = target.parentNode;
            }
        });

        const closeButton = this.parent.element.querySelector('#o-site-plan__overlay-modal-close');
        const modalOverlay = this.parent.element.querySelector('.o-site-plan__modal-overlay');
        
        if (closeButton) {
            closeButton.addEventListener('click', this.closeModal.bind(this));
        }
        if (modalOverlay) {
            modalOverlay.addEventListener('click', this.closeModal.bind(this));
        }

        const modalWrapper = this.parent.element.querySelector('.o-site-plan__modal-wrapper');
        if (modalWrapper) {
            modalWrapper.addEventListener('click', () => {
                if (modalWrapper.classList.contains('active')) {
                    // Remove any previous trap
                    if (this.removeTrapFocus) {
                        this.removeTrapFocus();
                    }

                    // Add focus event listener to the document
                    document.addEventListener('focus', this.handleOutsideFocus, true);
                }
            });
        }
    }

    closeModal() {
        const modalWrapper = this.parent.element.querySelector('.o-site-plan__modal-wrapper');
        const modal = this.parent.element.querySelector('#o-site-plan__overlay-modal');
        modalWrapper.classList.remove('active');

        // Wait for the slide-out transition to finish before hiding the modal
        if (this.removeTrapFocus) {
            this.removeTrapFocus();
            this.removeTrapFocus = null;
        }

        setTimeout(() => {
            modalWrapper.classList.add('hidden');
            modal.classList.add('hidden');
            document.querySelector('body').classList.remove('modal-open');
        }, 500);

        document.removeEventListener('focus', this.handleOutsideFocus, true);

        // Return focus to the element that opened the modal
        if (this.triggeringElement) {
            this.triggeringElement.focus();
        }
    }

    getPropertyData(clickedLink) {
        const propertyId = clickedLink.getAttribute('data-property-id');
        return this.parent.properties.find(property => property.Id === propertyId);
    }

    handleOutsideFocus(event) {
        const modalWrapper = this.parent.element.querySelector('.o-site-plan__modal-wrapper');
        if (modalWrapper && !modalWrapper.contains(event.target)) {
            this.closeModal();
        }
    }

    populateModal(propertyData) {
        if (!propertyData) {
            console.error('No data to populate the modal with.');
            return;
        }

        const modal = this.parent.element.querySelector('#o-site-plan__overlay-modal');
        const imageCarouselContainer = modal.querySelector('.image__carousel');
        const propertyInfoContainer = modal.querySelector('.o-site-plan__property-info');

        if (!modal || !imageCarouselContainer || !propertyInfoContainer) {
            console.error('Modal, carousel, or property info elements not found.');
            return;
        }

        let mainCarouselSlides = '';
        let thumbnailCarouselSlides = '';

        if (propertyData.ImageCarousel && propertyData.ImageCarousel.length > 0) {
            propertyData.ImageCarousel.forEach(image => {
                mainCarouselSlides += `
                    <div class="c-carousel-slides__slide slide">
                        <img src="${image.ImageUrl}" alt="Main slide image" />
                    </div>
                `;
                thumbnailCarouselSlides += `
                    <div class="c-carousel-slides__slide slide cell">
                        <img src="${image.ThumbnailUrl}" alt="Thumbnail slide image" />
                    </div>
                `;
            });
        } else {
            console.error('No images or image data available for this property.');
        }

        const carouselContent = `
            <div class="main-carousel">
                <button class="c-carousel-prev slick-prev slick-arrow" aria-label="Previous" type="button"></button>
                <div class="c-carousel-slides slick-slider slick-dotted">
                    ${mainCarouselSlides}
                </div>
                <button class="c-carousel-next slick-next slick-arrow" aria-label="Next" type="button"></button>
            </div>
            <div class="thumbnail-carousel">
                <div class="c-carousel-slides">
                    ${thumbnailCarouselSlides}
                </div>
            </div>
        `;

        const price = propertyData.PlotPrice === 0
            ? `&nbsp;`
            : `&pound;${propertyData.PlotPrice.toLocaleString()}`;

        const plotBedrooms = propertyData.ExtraBedroom === true
            ? `${propertyData.PlotBedrooms}/${propertyData.PlotBedrooms + 1}`
            : `${propertyData.PlotBedrooms}`;

        const propertyInfoContent = `
            <div class="o-site-plan__modal-property-info">
                <div class="o-site-plan__modal-property-title-address">
                    <p id="overlay-development-name" class="o-site-plan__modal-development-name">${this.parent.plotLabel} ${propertyData.PlotNumber} - ${propertyData.PlotVariant.Name}</p>
                    <div class="o-site-plan__modal-address-wrapper">
                    <p id="overlay-address" class="o-site-plan__modal-address">${propertyData.Development.DevelopmentName},</p>
                    <p id="overlay-address" class="o-site-plan__modal-address">${propertyData.Development.Address1}, ${propertyData.Development.Postcode}</p>
                    <p>${plotBedrooms} Bedroom ${propertyData.PlotType.PlotTypeName}</p>
                    <p id="overlay-development-price" class="o-site-plan__modal-development-price">${price}</p>
                    </div>
                    <p id="overlay-short-description" class="o-site-plan__modal-plot-description">${propertyData.Description}</p>
                </div>
                <div class="o-site-plan__price-cta">
                <p id="overlay-development-price" class="o-site-plan__modal-development-price">${price}</p>
                <div class="o-site-plan__modal-ctas">
                    <a href="${propertyData.Url}" id="overlay-enquiry-cta" class="site-plan-button solid o-site-plan__modal-enquiry-cta"><span>${this.parent.enquiryCTALabel}</span></a>
                    <a href="${propertyData.BrochureCTA}" id="overlay-download-cta" class="site-plan-button"><span>${this.parent.brochureCTALabel}</span></a>
                </div>
            </div>
            </div>
        `;

        imageCarouselContainer.innerHTML = carouselContent;
        propertyInfoContainer.innerHTML = propertyInfoContent;

        new ImageCarousel(imageCarouselContainer);

        modal.classList.remove('hidden');

        const modalWrapper = this.parent.element.querySelector('.o-site-plan__modal-wrapper');
        modalWrapper.tabIndex = -1;
        modalWrapper.focus();

        requestAnimationFrame(() => {
            setTimeout(() => {
                modalWrapper.click();
            }, 250);
        });
    }

    trapFocus(element) {
        let focusableElements = this.getFocusableElements(element);
        let firstFocusableElement = focusableElements[0];
        let lastFocusableElement = focusableElements[focusableElements.length - 1];

        // Keep track of the element that triggered the modal for later
        let lastFocusedElement = document.activeElement;

        const trap = (e) => {
            if (e.key === 'Tab' || e.keyCode === 9) {
                // Move focus to the first element if Shift+Tab is pressed on the first focusable element
                if (e.shiftKey && document.activeElement === firstFocusableElement) {
                    e.preventDefault();
                    lastFocusableElement.focus();
                }
                // Move focus to the last element if Tab is pressed on the last focusable element
                else if (!e.shiftKey && document.activeElement === lastFocusableElement) {
                    e.preventDefault();
                    firstFocusableElement.focus();
                }
            }
            // Close modal if focus moves out of modal (for example, through a focus trap bypass mechanism)
            else if (!element.contains(document.activeElement)) {
                this.closeModal();
                lastFocusedElement.focus();
            }
        };

        // Add event listener to trap the focus within the modal
        document.addEventListener('focus', trap, true);

        // Set initial focus on the first element inside the modal
        firstFocusableElement.focus();

        // Return a function to remove the event listener
        return () => {
            document.removeEventListener('focus', trap, true);
        };
    }

    getFocusableElements(element) {
        return element.querySelectorAll('.slick-arrow, [href]');
    }
}

export default SitePlanModal;