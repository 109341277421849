﻿import $ from 'jquery';
import Component from 'Component';
import CarouselComponent from 'Carousel';

class IframeCarousel extends Component {

    Carousel;
    $nextArrow;
    $prevArrow;

    constructor(selector, trackScroll){
        super(selector, trackScroll);

        this.init(() => {
            const { $element } = this;
            
            if(this.isExperienceEditor) return; //if we are in the experience editor we want the carousel to be static;
    
            this.$nextArrow = $(".c-carousel__next-button", $element);
            this.$prevArrow = $(".c-carousel__back-button", $element);
    
            this.Carousel =
                new CarouselComponent(
                    $element,
                    false,
                    {
                        nextArrow: this.$nextArrow,
                        prevArrow: this.$prevArrow
                    }
                );    
    
            //must come after carousel has been generated        
            this.$copy = $(".c-carousel-slides__copy", $element);
    
            this.setUpDeviceHandlers('setUpSmall setUpMediumSmall setUpMediumTouch setUpMediumDesktop setUpLargeTouch setUpLargeDesktop', () => {
                this.unifyCopyHeights();
            })
            .bindDeviceHandler();
        })

    }

    unifyCopyHeights() {
        
        const { $copy } = this;

        $copy.each((_, el) => {
            el.style.height = "auto";
        });
        
        const maxHeight = Array.prototype.reduce.call($copy, (max, current) => {
            const currentHeight = parseFloat(current.offsetHeight);
            return currentHeight > max ? currentHeight : max;
        }, 0);
        
        $copy.each((_, el) => {
            el.style.height = maxHeight + "px";
        });

        return this;
        
    }

}


export default IframeCarousel;