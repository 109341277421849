﻿import Component from 'Component';
import $ from 'jquery';
import 'jquery-validation';

class Form extends Component {
    constructor(element) {
        super(element);

        this.init(() => {
            this.$form = $('form', this.$element);
            this.$selects = $('select', this.$form);
            this.$submitButton = $('button.form__submit', this.$form);

            if (this.$selects && this.$selects.length > 0) {
                this.$selects.on('change', this.handleSelectChange.bind(this));
                this.$selects.each((i, select) => this.addChosenClassIfOptionNotDefault(select));
            }

            this.$form.validate({
                rules: {
                    FirstName: 'required',
                    LastName: 'required',
                    EmailAddress: {
                        required: true,
                        email: true
                    },
                    Postcode: 'required'
                },
                messages: {
                    FirstName: 'Please enter your first name',
                    LastName: 'Please enter your last name',
                    EmailAddress: {
                        required: 'Please enter an email address',
                        email: 'Please ensure this is a correctly formatted email address'
                    },
                    Postcode: 'Please enter a postcode'
                },
                errorPlacement: (err, element) => element.next('.form__error-msg').empty().append(err),
                onfocusout: function (element) {
                    this.element(element);
                }
            });

            this.$submitButton.removeAttr('disabled');
            this.$form.on('submit', () => {
                let errorInputs = $('input.error, select.error, textarea.error', this.$form);

                if (errorInputs.length <= 0) {
                    this.$submitButton.attr('disabled', true);
                    this.$submitButton.addClass('form__submit--loading');

                    let formData = this.$form[0].children;

                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        event: formData?.submission_event?.value ?? '',
                        region: formData?.submission_region?.value ?? '',
                        development: formData?.submission_development?.value ?? '',
                        pageType: formData?.submission_pagetype?.value ?? '',
                        id: formData?.submission_id?.value ?? '',
                        price: formData?.submission_plotprice?.value ?? '',
                        bedrooms: formData?.submission_plotbedrooms?.value ?? '',
                        availability: formData?.submission_availability?.value ?? '',
                        houseType: formData?.submission_housetype?.value ?? '',
                        source: formData?.submission_source?.value ?? ''
                    })
                }
            });
        });
    }

    handleSelectChange (e) {
        this.addChosenClassIfOptionNotDefault(e.target);
    }

    addChosenClassIfOptionNotDefault(select) {
        const $select = $(select);
        const defaultOption = $('option', select).get(0);
        const selectedOption = select.options[select.selectedIndex];

        selectedOption === defaultOption ? $select.removeClass('form__select--chosen') : $select.addClass('form__select--chosen');
    }
}

export default Form;