﻿import $ from 'jquery';
import Component from 'Component';

class FooterItem extends Component {

    Footer;
    $itemsContainer;
    $mainItem;
    $link;
    $subitems
    defaultHeight;
    maxHeight;
    isOpen = false;

    constructor(selector, Footer) {
        super(selector);

        this.Footer = Footer;

        this.init(() => {
            const { $element } = this;
    
            this.$itemsContainer = $('ol', $element);
            this.$mainItem = $('li:first-child', $element);
            this.$link = $('a', this.$mainItem);
            this.$subitems = this.$mainItem.siblings();
    
            this.setUpDeviceHandlers('setUpSmall setUpMediumSmall', () => {
                this.setHeightDefault();
                this.$link.on('click', this.toggleOpen.bind(this));
            })
            .bindDeviceHandler()
            .onBeforeSizeUpdate(() => {
                this.close()
                    .deactivate()
                    .resetHeight()
                    .$link.off();
            });  
        });
    }

    setHeightDefault() {
        const { $itemsContainer } = this;

        if (!this.defaultHeight) {
            this.defaultHeight = parseInt(this.$mainItem.css('height'));
        }

        $itemsContainer.css('height', this.defaultHeight);

        return this;
    }

    toggleOpen() {

        if (this.isOpen) {
            this.close();
        }
        else {
            this.open();
        }

        return this;

    }

    open() {
        this.setExpandHeight()
            .activate()

            .Footer.closeActiveItem()
                    .activeItem = this;

        this.isOpen = true;

        return this;

    }

    close() {

        this.deactivate()
            .setHeightDefault()
            .isOpen = false;

        return this;

    }

    setExpandHeight() {
        const { $itemsContainer } = this;
        if (!this.maxHeight) {
            this.maxHeight = Array.prototype.reduce.call(this.$subitems, (accumulative, current) => {
                return accumulative + current.offsetHeight;
            }, 0) + this.defaultHeight;
        }

        $itemsContainer.css('height', this.maxHeight);

        return this;

    }

    activate() {
        this.$element.addClass('u-active');

        return this;

    }

    deactivate() {
        this.$element.removeClass('u-active');

        return this;

    }

    resetHeight() {
        this.maxHeight = undefined;
        this.defaultHeight = undefined;
        this.$itemsContainer.css('height', '');

        return this;
    }

}

export default FooterItem;