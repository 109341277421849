﻿import $ from 'jquery';
import Component from 'Component';

class ModalFloorplan extends Component {
    $buttonOpenModal;
    $buttonCloseModal;
    $modal;
    $modalContent;

    constructor(selector) {
        super(selector, true);

        this.$buttonOpenModal = $('.modal-open', this.$element);
        this.$buttonCloseModal = $('.modal-btn__close', this.$element);
        this.$modal = $('.modal');
        this.$modalBody = $('.modal__body');

        this.init(() => {

            this.$buttonOpenModal.on('click', this.openModal.bind(this));
            this.$buttonCloseModal.on('click', this.closeModal.bind(this));

        })

    }

    openModal(e) {
        e.stopPropagation();
        e.preventDefault();

        this.$modal.addClass('show');

        this.loadDataFloorModal(e);
    }

    closeModal(e) {
        e.stopPropagation();

        this.$modalBody.empty();

        this.$modal.removeClass('show');

    }

    loadDataFloorModal(e) {

        this.$dataFloorBlock = $(e.target).closest('.o-floorplans__floorplan').find('.o-floorplans__floorplan__body').clone();
        this.$dataFloorBlock.find('.modal-open').remove();
        this.$dataFloorHeading = this.$dataFloorBlock.find('h3');
        this.$dataFloorHeadingHTML = this.$dataFloorHeading.html();

        this.$dataFloorHeading.remove();

        this.dataFloorBlockHTML = this.$dataFloorBlock.html();

        this.$imageFloor = $(e.target).closest('.o-floorplans__floorplan').find('img').clone();
        
        this.$imageFloorSRC = this.$imageFloor.attr('srcset');

        this.$modalContent = this.$modalBody.html(
            `<h3 class="modal__body-title"> ${this.$dataFloorHeadingHTML}</h3>
            <div class="modal__content">
                <img loading="lazy" class="modal__content-img" src="${this.$imageFloorSRC}"> 
                <div class="modal__content-info">
                    ${this.dataFloorBlockHTML} 
                </div>
            </div>`);

    }
}

export default ModalFloorplan;