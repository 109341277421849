﻿import $ from 'jquery';
import Component from 'Component';

class AccordionItem extends Component {

    AccordionGroup;
    isOpen = false;
    $title;

    constructor(element, AccordionGroup) {
        super(element);

        this.AccordionGroup = AccordionGroup;

        const { $element } = this;

        this.$title = $('.o-accordions__accordion-title', $element);

        this.init(() => {
            this.attachEvents();
        });
    }

    attachEvents() {
        this.$title.on('click', this.toggleOpen.bind(this));
    }

    toggleOpen() {
        this.isOpen ? this.close() : this.open();
    }

    open() {
        if(!this.isOpen) {
           const { AccordionGroup } = this;
           AccordionGroup.closeActiveAccordion();
           this.$element.addClass('o-accordions__accordion--open');
           this.isOpen = true;
           AccordionGroup.setActiveAccordion(this);
        }
    }

    close() {
        if(this.isOpen) {
           this.$element.removeClass('o-accordions__accordion--open');
           this.isOpen = false;
        }
    }

}

export default AccordionItem;
