import Component from 'Component';
import utils from 'utilities';
import App from 'App';

class ExternalSitePlan extends Component {
    constructor(selector) {
        super(selector, true);

        this.init(() => {
            const anchorId = this.$element[0].getAttribute('id');

            this.addEventHandler('scrolledIntoView', () => {
                if (utils.device.isLarge()) {
                    App.setSidebarState('collapsed');
                } else {
                    App.setSidebarState('secondary');
                }

                App.setSecondaryNavState(anchorId);
            }).bindDeviceHandler();
        });
    }
}

export default ExternalSitePlan;