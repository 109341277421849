import Component from 'Component';
import $ from 'jquery';
import Dropdown from './dropdown';
import Range from './range';
import utils from 'utilities';

class DevelopmentListingsFilters extends Component {
    searchLocationValue = '';
    region = '';
    $regionSelect;
    usingUserLocation = false;

    constructor(element, DevelopmentListings) {
        super(element);

        this.DevelopmentListings = DevelopmentListings;

        this.init(() => {
            const { $element } = this;        

            this.$distance = $('.o-development-listings-filters__distance select', $element);
            this.$priceSelects = [
                $('select#minprice', $element),
                $('select#maxprice', $element)
            ];    
            this.$bedroomSelects = [
                $('select#minbedrooms', $element),
                $('select#maxbedrooms', $element)
            ];            
            this.$searchContainer= $('.c-text-input--search', $element);                       
            this.$searchInput = $('.c-text-input--search input', $element);
            this.$searchLocation = $('.development-listings__link', $element);   
            this.$submit = $('.primary-button', $element);
            this.$closeButton = $('.o-development-homes-filters__header-mobile__close', this.$element);
            this.DistanceDropDown = new Dropdown(this.$distance);    
            this.PriceRange = new Range(...this.$priceSelects);
            this.BedroomRange = new Range(...this.$bedroomSelects);
            this.searchLocationValue = this.$searchInput.val();

            this.bindEvents();
        });
    }

    bindEvents() {
        this.$searchLocation.on('click', this.updateWithUserLocation.bind(this));
        this.$searchInput.on('keyup', this.updateLocation.bind(this));

        if (this.DevelopmentListings.$regionSelect) {
            this.DevelopmentListings.$regionSelect.on('change', this.handleRegionChange.bind(this));
        }

        this.setUpDeviceHandlers('setUpSmall setUpMediumSmall setUpMediumTouch setUpMediumDesktop', () => {
            this.$closeButton.on('click', this.closeLightbox.bind(this));
            this.$element.on('submit', this.handleUpdateMobile.bind(this));
        })
        .setUpDeviceHandlers('setUpLargeTouch setUpLargeDesktop', () => {
            this.$element.on('submit', this.handleUpdate.bind(this));
        })
        .bindDeviceHandler()
        .onBeforeSizeUpdate(() => {
            this.$element.off();
            this.$closeButton.off();
        });
    }

    handleRegionChange(e) {
        const {
            value
        } = e.target;

        this.resetFilters();
        this.updateRegionQueryString(value);
        this.region = value;
        this.searchFilteredData();
    }

    closeLightbox(){
        this.$element.removeClass('o-development-listings-filters--visible');
        utils.showBodyOverflow(true, true);
    }

    disableSearchByLocation(){
        this.$searchLocation.addClass('development-listings__link--hidden');
    }

    disableSubmit() {
        this.$submit.addClass('primary-button--disabled');
    }

    enableSubmit() {
        if (this.$submit.hasClass("primary-button--disabled")) {
            this.$submit.removeClass('primary-button--disabled');
        }
    }

    handleUpdateMobile(e){
        const success = this.handleUpdate(e);
        if(success !== null)
            this.closeLightbox(); 
    }

    handleUpdate(e) {
        e.preventDefault();
        const {
            searchLocationValue
        } = this;

        if (!searchLocationValue){
            this.invalidateSearchInput();
            return null;
        }

        this.setRegionFilter('');
        this.searchFilteredData();
    }

    searchFilteredData() {
        this.resetSearchInputStyle();
        this.prepareUiForUpdate();
        this.applyFiltersToListings();
    }

    prepareUiForUpdate() {
        const {
            DevelopmentListings
        } = this;

        DevelopmentListings.hideShowMore();
        DevelopmentListings.clearCards();
        DevelopmentListings.updateLoaderText('Updating...');
        DevelopmentListings.showLoader();
    }
    
    applyFiltersToListings(){
        this.DevelopmentListings.applyFilters(
            this.searchLocationValue,
            this.region,
            !this.region ? this.DistanceDropDown.value : 0,
            [
                parseFloat(this.PriceRange.MinDropDown.value) || 0,
                parseFloat(this.PriceRange.MaxDropDown.value) || Infinity
            ],
            [
                parseFloat(this.BedroomRange.MinDropDown.value) || 0,
                parseFloat(this.BedroomRange.MaxDropDown.value) || Infinity
            ],
            this.usingUserLocation
        );
    }

    updateLocation(e) {
        this.usingUserLocation = false;
        this.enableSubmit();
        this.searchLocationValue = e.target.value;
    }

    updateWithUserLocation() {
        const { DevelopmentListings } = this;
        this.disableSubmit();

        DevelopmentListings.getUserLocation()
            .then(location => DevelopmentListings.fetchUserAdressJSON(location.latitude, location.longitude, (data) => {
                if (data && data.Success) {
                    this.usingUserLocation = true;
                    this.searchLocationValue = data.Address;
                    this.updateSearchInput(data.Address);                
                }
                this.enableSubmit();
            }))
            .catch(e => console.log(e));
    }

    updateSearchInput(value) {
        if (value !== undefined) {
            this.searchLocationValue = value;
            this.$searchInput.val(value);
        }
    }

    invalidateSearchInput(){
        this.$searchContainer.addClass('c-text-input--invalid');
    }

    resetSearchInputStyle(){
        this.$searchContainer.removeClass('c-text-input--invalid');
    }

    resetFilters() {
        this.updateSearchInput('');

        this.resetDropdown(this.$distance);
        this.resetDropdown(this.$priceSelects[0]);
        this.resetDropdown(this.$priceSelects[1], true);
        this.resetDropdown(this.$bedroomSelects[0]);
        this.resetDropdown(this.$bedroomSelects[1], true);

        this.disableSubmit();
    }

    resetDropdown(element, last) {
        element.val(element.find(last ? 'option:last' : 'option:first').val());
    }

    setDropdown(element, value) {
        element.val(value);
    }

    setRegionFilter(value) {
        const { $regionSelect } = this.DevelopmentListings;

        if (value !== undefined) {
            this.region = value;
            this.setDropdown($regionSelect, value);
            this.updateRegionQueryString(value);
        }
    }

    updateRegionQueryString(region) {
        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;
        let params = new URLSearchParams(window.location.search);
        params.delete("location");

        if (history.pushState && region) {
            params.set("region", region);
        } else {
            params.delete("region");
        }

        let search = params.toString();
        if (search) {
            newurl = newurl + '?' + search;
        }

        window.history.pushState({ path: newurl }, '', newurl);
    }
}

export default DevelopmentListingsFilters;