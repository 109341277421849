﻿import $ from 'jquery';
import Component from 'Component';
import utils from 'utilities';
import App from 'App';

class BottomBar extends Component {

    $primaryCTA;
    $parent;
    state;
    $homesFiltersCTA;
    active = true;
    isDevelopmentListings = false;

    constructor(selector, elementToUseAsParent, Sidebar) {
        super(selector);

        this.$parent = elementToUseAsParent;
        this.Sidebar = Sidebar;

        this.init(() => {
            
            this.createNewElements()
            .append();

        });
    }

    createNewElements() {       

        const { $element } = this;


        this.isDevelopmentListings = $element.find('.o-development-listings-filters').length > 0;

        if(this.isDevelopmentListings) {
            this.$primaryCTA = $('.primary-button', $element).clone(false)
                                                             .addClass('homes-filters')
                                                             .addClass('homes-filters--blue')
                                                             .text('Filter homes');

            this.$primaryCTA.on('click', this.showListingsFiltersLightBox.bind(this));
            
            this.$element = this.createBottomButton($element);

            this.$element.append(this.$primaryCTA);

            this.show();

        }
        else {
            this.$primaryCTA = $('.primary-cta', $element).clone(true);

            this.$homesFiltersCTA =  
                this.$primaryCTA.clone(true)
                                .removeAttr('href')
                                .removeClass('primary-cta')
                                .addClass('homes-filters')
                                .text('Filter homes');     

                this.$homesFiltersCTA.on('click', this.showHomesFiltersLightBox.bind(this)); 

                this.$element = this.createBottomButton($element);

                this.$element.append(this.$primaryCTA).append(this.$homesFiltersCTA);
        }    



        return this;
   
    }
    
    createBottomButton($element) {
        return $($element.get(0).cloneNode()).addClass('c-sidebar--secondary')
                                      .removeAttr('data-component');
    }

    setState(state){
        
        if(state === this.state) return;

        switch(state) {
            case 'primary':
                this.showPrimaryState();
                break;
            case 'hidden':
                this.hide();
                break;
            case 'visible':
                this.show();
                break;
            case 'homes-filters':
                this.showHomesFilters();
                break;
            default:
                throw new Error(`'${state}' is and invalid state`)
        }

        this.state = state;

    }

    append() {
        this.$element.appendTo(this.$parent);
    }

    remove() {
        this.$element.remove();
    }

    destroy() {
        this.$homesFiltersCTA && this.$homesFiltersCTA.off();
        this.isDevelopmentListings && this.$primaryCTA.off();
        this.remove();
        this.active = false;
    }

    reinit() {
        this.append();
        this.$homesFiltersCTA && this.$homesFiltersCTA.on('click', this.showHomesFiltersLightBox.bind(this));
        this.isDevelopmentListings && this.$primaryCTA.on('click', this.showListingsFiltersLightBox.bind(this));
        this.active = true;
    }

    showPrimaryState(){
    
        const { $element } = this;

        $element.addClass('c-sidebar--secondary--visible');

        this.$homesFiltersCTA && this.$homesFiltersCTA.removeClass('homes-filters--visible');
        
        this.$primaryCTA.removeClass('primary-cta--hidden')        

        return this;

    }

    hide() {
        const { $element } = this;
        
        if(!this.active) return;

        $element.removeClass('c-sidebar--secondary--visible');

        App.showNotifications();

        return this;
    }

    show() {
        const { $element } = this;

        if(!this.active) return;

        $element.addClass('c-sidebar--secondary--visible');

        App.hideNotifications();

        return this;
    }

    showHomesFilters(){
        
        this.$homesFiltersCTA.addClass('homes-filters--visible');
        
        this.$primaryCTA.addClass('primary-cta--hidden');
                
        return this;
    }

    showHomesFiltersLightBox(e){
        e.preventDefault();
        this.Sidebar.showHomesFiltersLightBox();
    }

    showListingsFiltersLightBox(e){
        e.preventDefault();
        this.Sidebar.showListingsFiltersLightBox();
    }

}


export default BottomBar;