import $ from 'jquery';
import Component from 'Component';
import lightbox from 'lightbox2';
import utils from 'utilities';
import App from 'App';

class Floorplans extends Component {
    $dataLightbox;
    anchorId;

    constructor(selector) {
        super(selector, true);

        const { $element } = this;

        this.$dataLightbox = $element.find('[data-lightbox]');
        this.anchorId = $element.attr('id');

        this.init(() => {
            this.lightBox();

            this.setUpDeviceHandlers('setUpSmall setUpMediumSmall setUpMediumDesktop setUpMediumTouch', () => {

                // Prevent body scrolling on iOS
                if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
                    this.$dataLightbox.on('click', this.stopMove);
                }

            })
                .bindDeviceHandler()
                .addEventHandler('scrolledIntoView', () => {

                    if (utils.device.isLarge()) {
                        
                        App.setSidebarState('default');
                    } else {
                        App.setSidebarState('secondary');
                    }

                    App.setSecondaryNavState(this.anchorId);
                })

        })

    }

    stopMove() {
        $('body').on('touchmove', (e) => {
            e.preventDefault();
            e.stopPropagation();
        })
    }

    lightBox() {
        lightbox.option({
            'showImageNumberLabel': false,
            'resizeDuration': 0,
            'wrapAround': false,
            'positionFromTop': 0,
            'resizeDuration': 0,
            'disableScrolling': true
        })
    }
}

export default Floorplans;