import $ from 'jquery';
import externalVideoFactory from 'externalVideoFactory';
import App from 'App';
import utils from 'utilities';
import Component from 'Component';

class MediaBlock extends Component {
    video;
    $image;
    $videoContainer;
    $container;

    constructor(selector) {
        super(selector, true);

        this.init(() => {
            const { $element } = this;
            this.$videoContainer = $('.c-video__iframe-container', $element);
            if (this.$videoContainer.length <= 0 || this.isExperienceEditor) {
                return;
            }

            this.$container = $('.o-media-block__item', $element);
            this.$image = $('.o-media-block__item img', $element);
            utils.mapImgToBackground(this.$image, this.$container);

            if (utils.device.isMediumSmall(true) || utils.device.isSmall()) {
                this.setMobileMediaBlocksHeight();
            } else {
                this.setVideoRatio();
            }
            this.video = externalVideoFactory(this.$videoContainer, ".c-video__iframe-button", '.c-video__iframe-content', undefined);
            this.video.load();
        });
    }

    setVideoRatio() {
        let waitForRatioInterval,
            ratioCheckCount = 0,
            self = this,
            ratio = 0;

        waitForRatioInterval = setInterval(function () {
            if (ratioCheckCount <= 10) { //making sure that it won't loop forever
                ratio = self.$image.height() / self.$image.width() * 100;

                //if ratio is still not set, then wait 100ms and check ratio again
                if (ratio === 0 || isNaN(ratio) || typeof ratio === 'undefined' || ratio > 100) {
                    ratioCheckCount = ratioCheckCount + 1;
                } else {
                    self.$videoContainer.css('padding-bottom', ratio + '%');
                    clearInterval(waitForRatioInterval);
                }
            } else {
                ratio = ratio > 100 ? ratio : 25; //default value if after looping 10 times image is still not loaded 
                                                  //or after looping 10 times image have ration bigger than 100(IE11 problem)
                self.$videoContainer.css('padding-bottom', ratio + '%');
                clearInterval(waitForRatioInterval);
            }  
        }, 100);
    }

    setMobileMediaBlocksHeight() {
        let waitForImageInterval,
            imageCheckCount = 0,
            self = this,
            height = 0;

        waitForImageInterval = setInterval(function () {
            if (imageCheckCount <= 10) { //making sure that it won't loop forever
                height = self.$image.height();

                if (height === 0 || isNaN(height) || typeof height === 'undefined') {
                    imageCheckCount = imageCheckCount + 1;
                } else {
                    //setting height
                    self.setHeight(height);
                    clearInterval(waitForImageInterval);
                }
            } else {
                //setting height
                self.setHeight(height);
                clearInterval(waitForImageInterval);
            }
        }, 100);
    }

    setHeight(height) {
        this.$container.css('max-height', height + 'px');
        this.$videoContainer.css('padding-bottom', height + 'px');
        this.$image.css('max-height', height + 'px');
        this.$image.addClass('object-fit');
    }
}

export default MediaBlock;