﻿import $ from 'jquery';
import Component from 'Component';
import utils from 'utilities';
import App from 'App';

class AdditionalInformation extends Component {

    anchorId;

    constructor(selector) {

        super(selector, true);

        this.init(() => {

            const { $element } = this;

            this.anchorId = $element.attr('id');

            this.addEventHandler('scrolledIntoView',
                () => {
                    if (utils.device.isLarge())
                        App.setSidebarState('collapsed');
                    else
                        App.setSidebarState('secondary');

                    App.setSecondaryNavState(this.anchorId);
                });
        });
    }
}

export default AdditionalInformation;