﻿import $ from 'jquery';
import slick from 'slick-carousel';
import Component from 'Component';
import utils from 'utilities';
import CarouselComponent from 'Carousel';

class TwoColumnCarousel extends Component {

    $slideImages;
    $slideContents;
    $pagination;
    Carousel;

    constructor(selector) {
        super(selector);

        this.init(() => {
            const { $element } = this;
            
            if(this.isExperienceEditor) return; //if we are in the experience editor we want the carousel to be static;

            this.Carousel = new CarouselComponent($element);

            this.$slideImages = $('.c-carousel-slides__content--image', $element);

            //must come after carousel has been generated
            this.$slideContents = $('.c-carousel-slides__content--copy', $element);

            this.mapImagestoBackgrounds();
 
            this.setUpDeviceHandlers('setUpSmall setUpMediumSmall setUpMediumTouch setUpMediumDesktop setUpLargeTouch setUpLargeDesktop', () => {
                this.unifyContentHeights();
             }).bindDeviceHandler();
        });
    }

    mapImagestoBackgrounds() {

        this.$slideImages.each(function () {
            const $container = $(this);
            utils.mapImgToBackground($container.find('img'), $container)
        });

        return this;
    }

    unifyContentHeights() {

        const { $slideContents } = this;

        $slideContents.each((_, el) => {
            el.style.height = "auto";
        });
        
        const maxHeight = Array.prototype.reduce.call($slideContents, (max, current) => {
            const currentHeight = parseFloat(current.offsetHeight);
            return currentHeight > max ? currentHeight : max;
        }, 0);
        
        $slideContents.each((_, el) => {
            el.style.height = maxHeight + "px";
        });

        return this;

    }

}

export default TwoColumnCarousel;

