﻿import $ from 'jquery';
import Component from 'Component';
import SearchForm from 'Search';
import Carousel from 'Carousel';

class HeroBanner extends Component {

    $searchOverlay;
    $arrow;
    SearchForm;
    HeroCarousel;

    constructor(selector) {
        super(selector);

        this.init(() => {
                
            if(this.isExperienceEditor) return; //if we are in the experience editor we want the carousel to be static;
        
            const { $element } = this;
    
            this.$carouselContainer = $('.o-carousel-container', $element);
            this.$searchOverlay = $('.c-hero-overlay', $element);
        
            this.$arrow = $('.c-scroll-prompt', $element);
    
            this.HeroCarousel = new Carousel(this.$carouselContainer, false);
            this.SearchForm = new SearchForm(this.$searchOverlay);

            this.setUpDeviceHandlers('setUpMediumTouch setUpLargeTouch', () => {
                    this.$arrow.on('click', this.scrollToNextSection.bind(this))
                })
                .setUpDeviceHandlers('setUpMediumDesktop setUpLargeDesktop', () => {
                    const { HeroCarousel } = this;
    
                    this.$element.on('mouseover', HeroCarousel.showHiddenElements.bind(HeroCarousel))
                                    .on('mouseleave', HeroCarousel.hideHiddenElements.bind(HeroCarousel));
    
                    this.$arrow.on('click', this.scrollToNextSection.bind(this));
                })
                .bindDeviceHandler()
                .onBeforeSizeUpdate(() => {
                    this.$arrow.off();
                    this.$element.off();
                });
        });
    }
    
    scrollToNextSection() {
        const { $window } = this;
        const elementsToScroll = this.$body.add('html');

        const cancelAnimation = () => {
            elementsToScroll.stop();
            $window.off('mousewheel mousedown', cancelAnimation);
        }

        $window.on('mousewheel mousedown', cancelAnimation)

        elementsToScroll.animate({
            scrollTop: this.$element.nextAll('section:first').offset().top
        }, 1000, ()=> {
            $window.off('mousewheel mousedown', cancelAnimation);
        });       

        return this;

    }

}

export default HeroBanner;