﻿import $ from 'jquery';
import Component from 'Component';
import utils from 'utilities';
import NavItem from 'NavItem';
import HeaderSearch from 'HeaderSearch';
import { throttle } from 'throttle-debounce';

class Header extends Component {

    $navContainer;
    $menuButton;
    $nav;
    $contentContainer;
    $stickyInfoBar;
    $stickyInfoBarCloseButton;
    NavItems;
    Search;
    menuOpen = false;
    activeNavItem = null
    navAnimating = false;
    slideDuration = 500;
    menuOffset = false;
    _height;
    hidden = false;
    windowScrollPosition;

    constructor(selector) {
        super(selector);

        this.init(() => {

            const { $element } = this;

            if (this.isExperienceEditor)
                return; //if we are in the experience editor we want the header to be static;

            this.$nav = $('.c-nav', $element);
            this.$navContainer = $('.c-nav__nav-items-container', $element);
            this.$menuButton = $('.c-header__menu-button', $element);
            this.$contentContainer = $('.o-header__content-container', $element);

            this.$stickyInfoBar = $('.sticky-header-infomation-bar', $element);
            this.$stickyInfoBarCloseButton = $('.close-info-bar', $element);
            this.$headerNextSibling = $('.c-header + *')

            if (this.$stickyInfoBar.length > 0) {
                this.$stickyInfoBarCloseButton.on('click', this.closeStickyDropdownBanner.bind(this));
                this.setHeaderSiblingMargin(this.$stickyInfoBar.outerHeight());
            }

            this.NavItems = $('.c-nav__nav-item', $element).get().map((element, i) => new NavItem(element, this));

            this.Search = new HeaderSearch($('.c-header-search', $element), this);

            this.setUpDeviceHandlers('setUpSmall', () => {
                this.$menuButton.on('click', this.onSmallMenuButtonClick.bind(this))

            })
                .setUpDeviceHandlers('setUpMediumSmall', () => {
                    this.$menuButton.on('click', this.onMenuButtonClick.bind(this))
                    this.shrinkNavHeight();
                })
                .setUpDeviceHandlers('setUpMediumTouch setUpMediumDesktop setUpLargeTouch setUpLargeDesktop', () => {
                    //added for touch screen desktop devices
                    this.$element.on('mouseleave', () => {
                        this.closeActiveNavItem()
                            .closeSearch();
                    });
                })
                .bindDeviceHandler()
                .onBeforeSizeUpdate(() => {
                    this.handleSizeUpdate();
                });

            const throttledHandleScroll = throttle(150, this.handleScroll.bind(this));
            this.$window.on('scroll', throttledHandleScroll);
        });
    }

    onMenuButtonClick(event) {

        const { menuOpen } = this;

        if (!menuOpen) {
            this.openMenu()
                .Search.close();
        }
        else {
            this.closeMenu();
        }

        return this;
    }

    onSmallMenuButtonClick(event) {

        const { menuOpen } = this;

        if (!menuOpen) {
            this.openNav();

        }
        else {
            this.closeNav();
        }

        return this;
    }

    openNav() {

        this.calculateWindowScrollPosition();

        this.ensureFullscreenMenu()
            .$menuButton.addClass("c-header__menu-button--active");

        this.$nav.addClass('c-nav--active');
        this.menuOpen = true;

        this.addContentContainerOverflow()

        return this;
    }

    closeNav() {

        this.$menuButton.removeClass("c-header__menu-button--active");
        this.$nav.removeClass('c-nav--active');

        utils.showBodyOverflow(true);

        this.resetWindowScrollPosition();

        this.removeContentContainerOverflow();

        this.menuOpen = false;

        return this;
    }

    closeStickyDropdownBanner(event) {
        this.$stickyInfoBar.css("display", "none")
        document.cookie = "stampDutyInfoBoxClosed=True";
        const plotStickyNav = $('.plot-sticky-nav');

        if (plotStickyNav.length > 0) {
            plotStickyNav.removeClass("info-bar-present");
        }

        this.setHeaderSiblingMargin(0);

        return this;
    }


    openMenu() {

        const { $menuButton } = this;

        this.menuOpen = true;

        if (!this.Search.isOpen)
            this.calculateWindowScrollPosition();


        $menuButton.addClass("c-header__menu-button--active");

        this.expandNavHeight()
            .activateNavContainer()
            .addContentContainerOverflow()
            .ensureFullscreenMenu();

        return this;
    }

    closeMenu() {

        const { $menuButton } = this;

        $menuButton.removeClass("c-header__menu-button--active");

        this.deactivateNavContainer()
            .shrinkNavHeight()

        this.removeContentContainerOverflow();

        if (!this.Search.isOpen)
            this.resetWindowScrollPosition();

        utils.showBodyOverflow(true);

        this.menuOpen = false;

        return this;
    }


    activateNavContainer() {
        this.$navContainer.addClass("c-nav__nav-items-container--active");

        return this;
    }

    deactivateNavContainer() {
        this.$navContainer.removeClass("c-nav__nav-items-container--active");

        return this;
    }

    closeActiveNavItem() {
        const { activeNavItem } = this;

        if (activeNavItem !== null) {
            activeNavItem.closeSubNav();
        }

        return this;
    }

    deactivateActiveNavItem() {
        const { activeNavItem } = this;
        if (activeNavItem !== null) {
            activeNavItem.deactivate();
        }

        return this;
    }

    closeSearch() {
        this.Search && this.Search.close();

        return this;
    }

    shrinkNavHeight() {
        this.$nav.css('height', '80px');

        return this;
    }

    expandNavHeight() {
        this.$nav.css('height', '');

        return this;
    }

    slideOpenNav() {

        if (this.navAnimating) return;

        this.navAnimating = true

        this.resetNavScrollPosition();

        this.activeNavItem.activate();

        this.$nav.addClass('c-nav--offleft');

        this.addConsistancyMargin()
            .removeContainerScrollable();

        setTimeout(() => {
            this.removeConsistancyMargin()
                .navAnimating = false;

        }, this.slideDuration);

        return this;

    }

    slideCloseNav() {

        if (this.navAnimating) return;

        this.navAnimating = true

        this.$nav.removeClass('c-nav--offleft');

        setTimeout(() => {
            this.makeNavScrollable();
            this.activeNavItem.resetSubNavContainerScrollPosition();
            this.navAnimating = false;
        }, this.slideDuration);

        return this;

    }

    makeNavScrollable() {
        this.$nav.css({ 'overflow-y': 'scroll', 'overflow-x': 'hidden' });

        return this;
    }

    removeContainerScrollable() {

        const { $nav } = this;

        $nav.css('overflow', 'visible');

        return this;

    }

    resetNavScrollPosition() {
        this.$nav.scrollTop(0);

        return this;
    }

    addConsistancyMargin() {
        const { $searchContainer } = this.Search;
        $searchContainer.css('margin-top', this.$nav.scrollTop() * -1 + "px");

        return this;
    }

    removeConsistancyMargin() {
        const { $searchContainer } = this.Search;
        $searchContainer.css('margin-top', '0');

        return this;
    }

    handleSizeUpdate() {
        const { $menuButton } = this;

        $menuButton.off()
            .removeClass("c-header__menu-button--active");

        this.closeActiveNavItem()
            .deactivateNavContainer()
            .activeNavItem && this.activeNavItem.deactivate();

        if (this.$stickyInfoBar.length > 0 && this.$stickyInfoBar.css('display') !== 'none') {
            this.setHeaderSiblingMargin(this.$stickyInfoBar.outerHeight());
        }

        this.$navContainer.removeClass('c-nav__nav-items-container--offleft');

        this.$nav.removeClass("c-nav--active c-nav--offleft");

        if (this.menuOpen) {
            utils.showBodyOverflow();
        }

        this.menuOpen = false;
        this.navAnimating = false;
        this.activeNavItem = null;

        this.expandNavHeight();

        this.height = undefined;

        this.windowScrollPosition = undefined;

    }

    ensureFullscreenMenu() {

        //temporarily remove window event handlers
        const reattach = this.detachWindowEvents();

        //do stuff that conflicts with window handlers
        utils.hideBodyOverflow(true);

        //add the handlers back on the window
        reattach();

        return this;

    }

    addContentContainerOverflow() {
        this.$contentContainer.css('overflow-x', 'visible');
        return this;
    }

    removeContentContainerOverflow() {
        this.$contentContainer.css('overflow-x', 'hidden');
        return this;
    }

    verticalOffset() {
        return this.$element.offset().top;
        return this;
    }

    slideToTop() {
        const offset = this.verticalOffset(); // gets vertical offset of header

        if (offset > 0) {
            this.menuOffset = true;
            this.$element.css('top', -offset)
        }

        return this;
    }

    slideToDefault(callback) {

        const { $element } = this, duration = parseFloat($element.css('transition-duration')) * 1000;

        if (this.menuOffset && !this.Search.isOpen && !this.menuOpen) {

            $element.css('top', 0);

            if (callback) {
                setTimeout(() => {
                    callback();
                    this.menuOffset = false;
                }, duration);
            }
            else {
                this.menuOffset = false;
            }

        }

        else callback();

        return this;

    }

    hide() {

        if (!this.hidden) {
            this.$element.css('top', -this.height);
            this.hidden = true;
        }

        return this;
    }

    show() {

        if (this.hidden) {
            this.$element.css('top', 0);
            this.hidden = false;
        }

        return this;
    }

    get height() {
        return this._height || this.$element.height();
    }

    set height(int) {
        this._height = int;
    }

    calculateWindowScrollPosition() {
        if (this.windowScrollPosition === undefined)
            this.windowScrollPosition = this.$window.scrollTop();
    }

    resetWindowScrollPosition() {

        const { windowScrollPosition } = this;

        if (windowScrollPosition === undefined) {
            throw new Error("windowScrollPosition is undefined")
        }

        this.$window.scrollTop(windowScrollPosition);

        this.windowScrollPosition = undefined;
    }

    handleScroll() {
        const currentScrollPosition = $(window).scrollTop();

        // Specify the scroll position at which you want to hide the header
        const scrollPositionToHideHeader = 300; // for example

        if (currentScrollPosition > scrollPositionToHideHeader) {
            this.hide(); // Hides the header by moving it up
        } else {
            this.show(); // Shows the header by moving it back to its original position
        }
    }

    setHeaderSiblingMargin(height) {
        this.$headerNextSibling.css('margin-top', height)
    }

}

export default Header;