﻿import Form from './form';
import $ from 'jquery';

class ArrangeAppointment extends Form {
    $phoneNumber;
    $development;

    constructor(element){
        super(element);

        this.init(() => {    
            this.$phoneNumber = $('#PhoneNumber', this.$form);
            this.$development = $('#Development', this.$form);

            this.$phoneNumber.rules('add', {
                required: true,
                digits: true,
                messages: {
                    required: 'Please enter a phone number',
                    digits: 'A phone number should only contain numbers'
                }
            });

            if(this.$development && this.$development.length > 0) {
                this.$development.rules('add', {
                    required: true,
                    messages: {
                        required: 'Please select a development'
                    }        
                });
            }
        });
    }
}

export default ArrangeAppointment;