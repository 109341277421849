﻿import $ from 'jquery';
import Component from 'Component';

class ExternalVideo extends Component {

    $playbutton;
    $video;
    $thumbnail;
    url;
    videoId;
    Player;

    constructor(selector, playbutton = 'overlay', video = 'video', thumbnail = 'thumbnail', url) {
        super(selector);

        const { $element } = this;

        this.$playbutton = typeof (playbutton === 'string' && !playbutton.jquery) ? $(playbutton, $element) : playbutton;
        this.$video = typeof (video === 'string' && !video.jquery) ? $(video, $element) : video;
        this.$thumbnail = typeof (thumbnail === 'string' && !thumbnail.jquery) ? $(thumbnail, $element) : thumbnail;
        this.url = url;

        let splitUrl = this.url.split('/');
        let lastSegment = splitUrl[splitUrl.length - 1].split(/[\?#]/);
        this.videoId = lastSegment[0];

        this.init();
    }

    load() { }

    stop() { }

    hideOverlayElements() {
        let container = this.$element.get(0);
        const overlay = container.querySelector('.iframe-overlay');
        const thumbnail = container.querySelector('.iframe-thumbnail');
        const playButton = container.querySelector('.iframe-button');

        if (overlay) overlay.style.display = 'none';
        if (thumbnail) thumbnail.style.display = 'none';
        if (playButton) playButton.style.display = 'none';
    }

    showOverlayElements() {
        let container = this.$element.get(0);
        const overlay = container.querySelector('.iframe-overlay');
        const thumbnail = container.querySelector('.iframe-thumbnail');
        const playButton = container.querySelector('.iframe-button');

        if (overlay) overlay.style.display = 'initial';
        if (thumbnail) thumbnail.style.display = 'initial';
        if (playButton) playButton.style.display = 'initial';
    }
}


export default ExternalVideo;