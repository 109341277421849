import Component from 'Component';

class DevelopmentForm extends Component {

    constructor(element, handleUpdatedValue) {
        super(element);

        this.checkboxSelector = 'input[type="checkbox"]';
        this.textInputSelector = 'form__input';
        this.sliderSelector = 'budget-slider';
        this.sliderValueSelector = 'budget-value';
        this.sliderLabelSelector = 'slide-container__scale__incr';
        this.formSelector = 'development-form';
        this.formTitleSelector = 'c-promotion-block__content--copy h1';
        this.progressButtonSelector = 'progress-next';
        this.fieldSetSelector = 'development-form__step';
        this.submitButton = document.querySelectorAll('[type=submit]')[0];
        this.slider = document.getElementById(this.sliderSelector);
        this.sliderValue = document.getElementById(this.sliderValueSelector);
        this.textInputs = document.querySelectorAll(`.${this.textInputSelector}`);
        this.bedroomCheckboxes = document.querySelectorAll(`.bedroom-input ${this.checkboxSelector}`);
        this.contactTimeCheckboxes = document.querySelectorAll(`.contact-time ${this.checkboxSelector}`);
        this.sliderLabels  = document.querySelectorAll(`.${this.sliderLabelSelector}`);
        this.progressButtons  = document.querySelectorAll(`.${this.progressButtonSelector}`);
        this.fieldSets  = document.querySelectorAll(`.${this.fieldSetSelector}`);
        this.form = document.getElementById(this.formSelector);
        this.formTitleEl = document.querySelectorAll(`.${this.formTitleSelector}`);
        this.formTitle = this.formTitleEl[0] ? this.formTitleEl[0].innerText : '';
        this.lastFieldTouched = null;
        this.gtmData = {};
        this.stepOneValidated = false;
        this.stepTwoValidated = false;
        this.history = [];

        this.init(() => {
          let div = document.createElement("div");
    
          div.innerHTML = document.getElementById('developmentInfo')?.innerText || '{}';
          this.gtmData = JSON.parse(div.textContent || div.innerText);

          this.bindEvents();
        });
    }

    bindEvents() {
      this.bedroomCheckboxes.forEach((checkbox) => {
        checkbox.addEventListener('change', () => {
          if(!this.numberOfCheckboxesValid(1, 2, `.bedroom-input  ${this.checkboxSelector}:checked`)) {
            event.target.checked = false;
          }
        });
      });

      this.contactTimeCheckboxes.forEach((checkbox) => {
        checkbox.addEventListener('change', () => {
          this.validateContactTime();
        });
      });

      this.slider.onchange = ({ target }) => this.processBudgetValue(target.value, '');

      this.slider.oninput = ({ target }) => this.processBudgetValue(target.value, '');
  
      this.sliderValue.onkeyup = ({ target }) => this.processBudgetValue(target.value, target.validationMessage);

      this.textInputs.forEach((input) => {
        input.addEventListener('change', (event) => {
          this.validateTextInput(event.target);
        });
      });

      this.submitButton.onclick = (event) => {
        event.preventDefault();

        if (this.validateStepTwo() && this.form.checkValidity()) {
          this.lastFieldTouched = null;
          this.createGTMEvent('formSuccess', this.formTitle)
          this.form.submit();
        } else {
          this.createGTMEvent('formError', this.formTitle)
        }
      }

      this.progressButtons[0].onclick = () => {
        this.validateStepOne();
      }

      this.form.addEventListener('change', (e) => {
        this.lastFieldTouched = e.target.name;
        this.history.push(e.target.name.split('[')[0]);

        if(this.stepOneValid()) {
          this.progressButtons[0].classList.remove("disabled");
        } else {
          this.progressButtons[0].classList.add("disabled");
        }

        if(this.stepOneValidated) {
          this.validateStepOne();
        }

        if(this.stepTwoValidated) {
          this.validateStepTwo();
        }
      })


      window.onbeforeunload = () => {
        if (this.lastFieldTouched) {
          this.createGTMEvent('formAbandonment', this.formTitle)
        }
      };
    }

    createGTMEvent(type, formTitle) {
      if (window.dataLayer) {

        let tag = {
          event: type,
          developmentName: this.gtmData.name,
          developmentStatus: this.gtmData.status,
          region: this.gtmData.region,
          geoLocation: this.gtmData.county,
          location: this.gtmData.town,
          formTitle: formTitle,
          'gtm.element': this.lastFieldTouched
        }

        if (type === 'formAbandonment') {
          tag.history = this.history.join(' > ');
        }

        window.dataLayer.push(tag);
      }
    }

    validateTextInput(input) {
      input.classList.remove('valid', 'error');
      const validity = input.validity.valid ? 'valid' : 'error';
      input.classList.add(validity);
    }

    highlightSlider(value) {
      this.sliderLabels.forEach(label => {
        label.classList.remove('active');
        const labelVal = label.getAttribute("incrval") * 1000;
        const tolerance = 20000;

        if (value > labelVal - tolerance && value < labelVal + tolerance) {
          label.classList.add('active');
        }
      })
    }

    stepOneValid() {
      return document.getElementsByName('BuyerType')[0].checkValidity()
             && this.numberOfCheckboxesValid(1, 2, `.bedroom-input  ${this.checkboxSelector}:checked`)
             && document.getElementsByName('Budget')[0].checkValidity()
             && document.getElementsByName('MovingDateRange')[0].checkValidity()
    }

    validateStepOne() {
      const fields = [
        {
          id:'buyer-type',
          message: 'Please select an option',
          isValid: document.getElementsByName('BuyerType')[0].checkValidity()
        },
        {
          id:'bedroom-number',
          message: 'Please select at least one option',
          isValid: this.numberOfCheckboxesValid(1, 2, `.bedroom-input  ${this.checkboxSelector}:checked`)
        },
        {
          id:'budget-slider',
          message: 'Invalid entry',
          isValid: document.getElementsByName('Budget')[0].checkValidity()
        },
        {
          id:'timescale',
          message: 'Please select an option',
          isValid: document.getElementsByName('MovingDateRange')[0].checkValidity()
        },
      ]

      fields.forEach(field => {
        const errEl = document.getElementById(`${field.id}__error-msg`);

        if(!field.isValid && errEl) {
          errEl.innerText = field.message;
        } else if(errEl) {
          errEl.innerText = '';
        }
      })

      this.stepOneValidated = true;
    }

    validateStepTwo() {
      let isValid = true;
      this.stepTwoValidated = true;

      this.form.elements.forEach(el => {
        if(!el.checkValidity()) {
          el.classList.add('error');
          isValid = false;
        } else {
          el.classList.remove('error');
        }
      });

      if (this.contactTimeCheckboxes.length && !this.validateContactTime()) {
        isValid = false;
      }

      return isValid;
    }

    validateContactTime() {
      if(this.numberOfCheckboxesValid(1, 4, `.contact-time  ${this.checkboxSelector}:checked`)) {
        this.contactTimeCheckboxes[0].classList.remove('error')

        return true;
      } else {
        this.contactTimeCheckboxes[0].classList.add('error')

        return false;
      }
    }

    numberOfCheckboxesValid(min, max, selector) {
      const checkBoxes = document.querySelectorAll(selector);

      return checkBoxes.length >= min && checkBoxes.length <= max;
    }

    processBudgetValue(value, validationMessage) {
      this.slider.value = value || 0;
      this.sliderValue.value = value || 0;
      this.highlightSlider(value || 0);

      const errEl = document.getElementById('budget-slider__error-msg');

      if(errEl) {
        errEl.innerText = validationMessage;
      }
    }
}

export default DevelopmentForm;