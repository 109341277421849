import $ from 'jquery';
import Component from 'Component';
import utils from 'utilities';

class ImageTextBlock extends Component {

    $image;      

    constructor(selector) {            
        super(selector);

        this.init(() => {
            const { $element } = this;

            if (this.isExperienceEditor) return;

            this.$image = $('.c-image-text-block__content--image', $element);
    
            if (this.$image && this.$image.length) {
                $(() => {
                    utils.mapImgToBackground(this.$image.find('img'), this.$image)
                });
            }
        });
    }
}

export default ImageTextBlock;
