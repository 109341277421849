﻿import $ from 'jquery';
import IframeCarousel from 'IframeCarousel';
import Tour from './Components/360-tour.js';
import App from 'App';
import utils from 'utilities';

class TourCarousel extends IframeCarousel {

    Tours;
    $slideTours;
    anchorId;

    constructor(selector) {

        const $tours = $(".c-carousel-slides__iframe-container", $(selector));

        const carouselID = (Math.random() * 100000 | 1).toString();

        super(selector, true);

        this.init(() => {

            const { $element } = this;

            if(this.isExperienceEditor) return;
            
            this.$slideTours = $tours;

            this.anchorId = this.$element.attr('id');
            
            this.Tours = this.$slideTours.map((i, el) => new Tour(el, '.c-carousel-slides__iframe-button', '.c-carousel-slides__iframe-overlay', '.c-carousel-slides__iframe-content', '.c-carousel-slides__iframe-thumbnail', carouselID, i));    

            this.addCustomSizeHandler(1015, () => {
                this.Carousel.beforeSlideChange((e, slick, current) => {
                    this.Tours[current].showOverlayingElements()
                                       .hideIframe();
                })    
            })
            .bindDeviceHandler();

            this.addEventHandler('scrolledIntoView', () => {
                if(utils.device.isLarge())
                    App.setSidebarState('collapsed');
                else
                    App.setSidebarState('secondary');

                App.setSecondaryNavState(this.anchorId);
            });      
            
        })

    }
}


export default TourCarousel;