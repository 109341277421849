﻿import $ from 'jquery'

let windowScrollPosition = undefined;

const utilities = {

    device: {
        breakpoints: {
            large: 1024,
            medium: 769,
            msmall: 375
        },
        isLarge() {
            return window.innerWidth >= this.breakpoints.large;
        },
        isMedium() {
            return window.innerWidth >= this.breakpoints.medium;
        },
        isMediumSmall(only) {

            const ret = only === true ? !this.isMedium() : true;

            return window.innerWidth >= this.breakpoints.msmall && ret;
        },
        isSmall() {
            return window.innerWidth < this.breakpoints.msmall;
        },
        isTouch() {
            return "ontouchstart" in window || navigator.maxTouchPoints;
        }
    },

    $body: $('body'),
    $htmlBody: $('html, body'),
    $document: $(document),
    $window: $(window),

    onClickOutside(comparate, ...callbacks) {
        const clickOutsideHandler = (event) => {
            if ($(event.target).closest(comparate).length === 0) {
                callbacks.forEach((func) => {
                    if (typeof func === 'function')
                        func();
                    else {
                        throw new TypeError('All callbacks to onClickOutside should be functions and passed as individual parameters')
                    }
                });
                removeListener();
            }
        }

        const $document = $(document);

        const removeListener = () => {
            $document.off('click', clickOutsideHandler);
        }

        $document.on('click', clickOutsideHandler);

    },

    hideBodyOverflow(includeHTML, rememberWindowScrollPosition) {

        const $element = includeHTML ? this.$htmlBody : this.$body;

        if (rememberWindowScrollPosition) windowScrollPosition = this.$window.scrollTop();

        $element.css({
            'overflow': 'hidden',
            'height': '100%'
        });
    },

    showBodyOverflow(includeHTML, resetSetWindowScrollPosition) {

        const $element = includeHTML ? this.$htmlBody : this.$body;

        $element.css({
            'overflow': '',
            'height': ''
        });

        if (resetSetWindowScrollPosition) this.$window.scrollTop(windowScrollPosition);

    },

    mapImgToBackground(image, elementToUpdate, classToUse) {
        if (!(image instanceof $)) image = $(image)

        if (elementToUpdate && !(elementToUpdate instanceof $)) elementToUpdate = $(elementToUpdate)

        const currentSrc = image.prop('currentSrc');

        const imgUrl = currentSrc === undefined ? image.prop('src') : currentSrc;

        if (!elementToUpdate) elementToUpdate = image;

        if (imgUrl) {
            elementToUpdate
                .css('backgroundImage', 'url(' + imgUrl + ')')
                .addClass(classToUse || 'u-compat-object-fit');
        }
    },

    isElementOverflown(element) {

        element = element.get ? element.get(0) : element;

        return element.scrollHeight > element.clientHeight;
    },

    scrollToPosition(element, adjust) {
        if (!element) {
            return false;
        }
    
        element = element.get ? element.get(0) : element;
    
        let boundingBox = element.getBoundingClientRect();
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        let infoBarHeight = $('.sticky-infomation-bar').outerHeight() || 0;
        
        let scrollPosition = adjust 
            ? boundingBox.top + scrollTop - adjust.offsetHeight - infoBarHeight 
            : boundingBox.top + scrollTop - infoBarHeight;
    
        // Calculate the distance to scroll
        let distanceToScroll = Math.abs(scrollPosition - scrollTop);
    
        // Base duration per 1000px (adjust this value as needed)
        const baseDurationPer1000px = 300; 
        let duration = (distanceToScroll / 1000) * baseDurationPer1000px;
    
        const {
            $window
        } = this;
        const elementsToScroll = this.$body.add('html');
    
        const cancelAnimation = () => {
            elementsToScroll.stop();
            $window.off('mousewheel mousedown', cancelAnimation);
        }
    
        $window.on('mousewheel mousedown', cancelAnimation);
    
        elementsToScroll.animate({
            scrollTop: scrollPosition
        }, duration, 'linear', () => {
            $window.off('mousewheel mousedown', cancelAnimation);
            elementsToScroll.stop();
        });
    
        return this;
    },

    isFullScreen() {
        return !!(document.fullscreenElement || document.msFullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement);
    },
}

export default utilities;