﻿import $ from 'jquery';
import Component from 'Component';
import App from 'App';
import utils from 'utilities';

class DepartmentsListing extends Component {
    constructor(element) {
        super(element, true);

        this.init(() => {
            const { $element } = this;

            //get the id used for anchoring
            this.anchorId = $element.attr('id');

            $(() => {
                //when the component is scrolled in to view
                this.addEventHandler('scrolledIntoView', () => {
                    App.setSidebarState('default');

                    //update the secondary nav
                    App.setSecondaryNavState(this.anchorId);
                });

                var departmentImages = $('.o-departments-listing__card__media');
                departmentImages.each(function (index, element) {
                    const $container = $(this);
                    utils.mapImgToBackground($container.find('img'), $container);
                });
            });
        });
    }
}

export default DepartmentsListing;