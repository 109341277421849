﻿import $ from 'jquery';
import Component from 'Component';
import App from 'App';
import querystring from 'query-string';
import Consts from './Components/Helpers/consts';
import DevelopmentListingsFilters from './Components/filters';
import DevelopmentListingsMap from './Components/map';

//polyfills
import 'es6-promise/auto'; // ES6 Promise Polyfill - needed by fetch
import 'fetch-polyfill';

class DevelopmentListing extends Component {
    $header;
    $cardContainer;
    $outerContainer;
    $gridViewButton
    $listViewButton
    $loader;
    $loaderText;
    $showMore;
    $regionSelect;
    location = '';
    viewportLarge = false;
    isMoreToLoad = false;
    locationFound = false;
    radius = 0;
    searchParams = ``;
    developmentsList = [];
    developmentsCount = 0;
    nextDevelopmentsPage = false;
    appliedFilters = [];
    showGridView = true;
    refreshMap = true;

    constructor(selector) {
        super(selector);

        this.init(_ => {
            if (this.isExperienceEditor) return;

            const { $element } = this;

            this.query = querystring.parse(window.location.search);
            this.$cardContainer = $('.o-development-homes__cards', $element);
            this.$outerContainer = $('.o-development-homes__cards-container', $element);
            this.$gridViewButton = $('#gridViewButton', $element);
            this.$listViewButton = $('#listViewButton', $element);
            this.$loader = $('.o-development-homes__loader', $element);
            this.$loaderText = $('.o-development-homes__loader-text', this.$loader);
            this.$showMore = $('.o-development-homes__show-more', $element);
            this.$header = $('.c-column-layout__title', $element);
            this.$regionSelect = $('.o-development-listings-refine__region select', $element);
            
            this.$filters = $('.o-development-listings-filters');
            this.$map = $('.c-development-homes-map');
            
            this.bindEvents();
            if (this.$filters.length) {
                this.Filters = new DevelopmentListingsFilters(this.$filters.eq(0), this);
            }

            if (this.$map.length) {
                this.Map = new DevelopmentListingsMap(this.$map[0], this);
            }

            this.setUpDeviceHandlers('setUpSmall setUpMediumSmall setUpMediumTouch setUpMediumDesktop', () => {
                App.setSidebarState('visible');
                this.viewportLarge = false;
            }).setUpDeviceHandlers('setUpLargeTouch setUpLargeDesktop', () => {
                App.updateSidebarMinHeight(650);
                this.viewportLarge = true;
            }).bindDeviceHandler();

            if (this.query.location === Consts.USER_LOCATION_QUERY) {
                this.updateLoaderText(Consts.FETCHING_YOUR_LOCATION);
                this.getUserLocation()
                    .then(location => {
                        if (location) {
                            this.fetchUserAdressJSON(location.latitude, location.longitude, data => {
                                this.location = Consts.USER_LOCATION_TEXT;
                                this.updateLoaderText(Consts.FINDING_DEVELOPMENTS);

                                if (data && data.Success) {
                                    this.Filters.updateSearchInput(data.Address);
                                    this.Filters.usingUserLocation = true;
                                    this.Filters.applyFiltersToListings();
                                }
                            });
                        } else {
                            this.location = Consts.USER_LOCATION_TEXT;
                            this.updateLoaderText(Consts.FINDING_DEVELOPMENTS);
                            this.Filters.applyFiltersToListings();
                        }
                    })
                    .catch(e => console.log(e));
            } else {
                this.updateLoaderText('Locating...');
                this.location = this.query.location;
                this.updateLoaderText(Consts.FINDING_DEVELOPMENTS);
                this.Filters.setRegionFilter(this.query.region);
                this.Filters.updateSearchInput(this.query.location);
                this.Filters.applyFiltersToListings();
            }
        });
    }

    bindEvents() {
        this.$gridViewButton.on("click", this.handleGridViewClick.bind(this));
        this.$listViewButton.on("click", this.handleListViewClick.bind(this));
        this.$showMore.on('click', this.handleShowMoreClick.bind(this));
        this.$cardContainer.on("click", ".o-development-homes__card__go-to-map", (event) => this.goToMapMark(event.target));
    }

    applyFilters(location, region, distance, priceRange, bedroomRange, usingUserLocation = false) {
        let searchFiltersTab = [];
        this.developmentsList = [];
        this.developmentsCount = 0;
        this.nextDevelopmentsPage = false;
        this.location = usingUserLocation ? Consts.USER_LOCATION_TEXT : location;
        this.radius = distance && parseInt(distance);

        if (region) {
            searchFiltersTab.push(`region=${region}`);
        } else {
            searchFiltersTab.push(`distance=${this.radius}`);

            if (location) {
                searchFiltersTab.push(`location=${location}`);
            }

            if (bedroomRange) {
                const {
                    [0]: minBedrooms, [1]: maxBedrooms
                } = bedroomRange;

                if (minBedrooms !== 0) {
                    searchFiltersTab.push(`minBedrooms=${minBedrooms}`);
                }
                if (maxBedrooms !== Infinity) {
                    searchFiltersTab.push(`maxBedrooms=${maxBedrooms}`);
                }
            }

            if (priceRange) {
                const {
                    [0]: minPrice, [1]: maxPrice
                } = priceRange;

                if (minPrice !== 0) {
                    searchFiltersTab.push(`minPrice=${minPrice}`);
                }
                if (maxPrice !== Infinity) {
                    searchFiltersTab.push(`maxPrice=${maxPrice}`);
                }
            }
        }

        this.appliedFilters = searchFiltersTab;
        this.fetchDevelopmentsJSON();
    }

    prepareSearchParams() {
        let searchParams = this.appliedFilters.reduce((prev, filter) => prev + filter + '&', '?').slice(0, -1);

        if (this.nextDevelopmentsPage) {
            searchParams = `${searchParams}&skip=${this.developmentsCount}`;
        }

        return searchParams;
    }

    fetchDevelopmentsJSON() {
        const url = this.showGridView ? "/bloorapi/developmentslisting" : "/bloorapi/developmentslist";

        fetch(`${url}${this.prepareSearchParams()}`)
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }

                return response.json();
            })
            .then(data => {
                if (this.nextDevelopmentsPage) {
                    this.addDevelopmentsPage(data.Developments);
                } else {
                    this.processData(data.Developments);
                }
            })
            .catch(e => console.log(e));
    }

    fetchUserAdressJSON(latitude, longitude, calback) {
        fetch(`/bloorapi/locationaddress?latitude=${latitude}&longitude=${longitude}`)
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }

                return response.json();
            })
            .then(data => {
                if (calback) {
                    calback(data);
                }

                return data;
            })
            .catch(e => console.log(e));
    }

    addDevelopmentsPage(data) {
        const {
            Developments: developments,
            IsMoreToLoad: isMoreToLoad
        } = data;
        this.isMoreToLoad = isMoreToLoad;
        this.nextDevelopmentsPage = false;

        this.$cardContainer.append(this.createGridCardsMarkup(developments));
        this.hideShowMoreIfRequired();
        this.hideLoadingOnShowMore();
    }

    processData(data) {
        const {
            Developments: developments,
            Items: items,
            IsMoreToLoad: isMoreToLoad,
            LocationFound: locationFound,
            Count: count
        } = data;
        this.isMoreToLoad = isMoreToLoad;
        this.locationFound = locationFound;

        if (locationFound) {
            const noResults = developments && developments.length === 0 || items && items.length === 0; 
            this.$cardContainer.html(this.showGridView ? this.createGridCardsMarkup(developments) : this.createListCardsMarkup(items));
            this.updateHeaderText(this.prepareHeaderText(noResults, count));
            noResults ? this.showNoResults() : this.hideNoResults();
            this.hideLoader();
            this.showShowMoreIfRequired();
        } else {
            this.$cardContainer.html(this.showGridView ? this.createGridCardsMarkup(developments) : this.createListCardsMarkup(items));
            this.updateHeaderText(this.prepareHeaderTextForAllDevelopments());
            this.hideLoader();
            this.showShowMoreIfRequired();
        }
        this.refreshMap ? this.Map.fetchMapData() : this.refreshMap = true;
        this.viewportLarge ? App.updateSidebarMinHeight(650) : App.setSidebarState('visible');
    }

    prepareHeaderTextForAllDevelopments() {
        if (!this.query.location) {
            return '<strong>All developments</strong>';
        } else if (this.location && this.location !== Consts.USER_LOCATION_TEXT) {
            return `<strong>All developments</strong> - no location found for '${this.location}'`;       
        }

        return '<strong>All developments</strong> - could not find your location';
    }

    prepareHeaderText(noResults, count) {
        if (this.Filters.region) {
            return noResults ?
                `<strong class="highlight">${count} developments</strong> in ${this.Filters.region}` :
                `<strong>${count} developments</strong> in ${this.Filters.region}`;
        }

        return noResults ?
            `<strong class="highlight">${count} developments</strong> within ${this.radius} miles of ${this.location}` :
            `<strong>${count} developments</strong> within ${this.radius} miles of ${this.location}`;
    }

    handleShowMoreClick() {
        if (!this.nextDevelopmentsPage) {
            this.nextDevelopmentsPage = true;
            this.fetchDevelopmentsJSON();
            this.showLoadingOnShowMore();
        }
    }

    handleListViewClick() {
        if (this.showGridView) {
            this.showGridView = false;
            this.refreshMap = false;

            this.setListViewButtonActive();
            this.Filters.searchFilteredData();
        }
    }

    handleGridViewClick() {
        if (!this.showGridView) {
            this.showGridView = true;
            this.refreshMap = false;

            this.setGridViewButtonActive();
            this.Filters.searchFilteredData();
        }
    }

    createListCardsMarkup(items) {
        let letters = [];

        for (var key in items) {
            letters.push(key);
        }

        return `<ul class="o-development-homes__letter-list">${letters.reduce((markup, letter) => markup + this.createLetterCard(items[letter], letter), '')}</ul>`;
    }

    createGridCardsMarkup(developments) {
        return developments.reduce((markup, development) => markup + this.createGridCard(development), '');
    }

    createGridCard(development) {

        const developmentImageXl = development.ResizedImage && development.ResizedImage.Xlarge ? development.ResizedImage.Xlarge : Consts.DEFAULT_IMAGE_URL
        const developmentImageL = development.ResizedImage && development.ResizedImage.Large ? development.ResizedImage.Large : Consts.DEFAULT_IMAGE_URL
        const developmentImageM = development.ResizedImage && development.ResizedImage.Medium ? development.ResizedImage.Medium : Consts.DEFAULT_IMAGE_URL
        const developmentImageS = development.ResizedImage && development.ResizedImage.Small ? development.ResizedImage.Small : Consts.DEFAULT_IMAGE_URL
        const developmentImageXs = development.ResizedImage && development.ResizedImage.Xsmall ? development.ResizedImage.Xsmall : Consts.DEFAULT_IMAGE_URL

        this.developmentsList.push(development);
        this.developmentsCount++;

        return `
            <div class="o-development-homes__card">
                <div class="o-development-homes__card__media" >
                    <a href="${development.Url}" title="View development">
                        <picture>
                           <!--[if IE 9]>
                           <video style='display: none;'>
                              <![endif]--><source media="(min-width: 1200px)" srcset="${developmentImageXl}"><!--[if IE 9]>
                           </video>
                           <![endif]--><!--[if IE 9]>
                           <video style='display: none;'>
                              <![endif]--><source media="(min-width: 1024px)" srcset="${developmentImageL}"><!--[if IE 9]>
                           </video>
                           <![endif]--><!--[if IE 9]>
                           <video style='display: none;'>
                              <![endif]--><source media="(min-width: 769px)" srcset="${developmentImageM}"><!--[if IE 9]>
                           </video>
                           <![endif]--><!--[if IE 9]>
                           <video style='display: none;'>
                              <![endif]--><source media="(min-width: 550px)" srcset="${developmentImageS}"><!--[if IE 9]>
                           </video>
                           <![endif]--><img loading="lazy" srcset="${developmentImageXs}" alt="Development">
                        </picture>
                    </a>
                </div>
                <h3 class="o-development-homes__card__header">${development.Name},<br>${development.Town}, ${development.PostCode}</h3>
                <div class="o-development-homes__card__divider"></div>
                <p class="o-development-homes__card__body">
                    ${development.Tagline}
                </p>
                ${this.locationFound ? `<p class="o-development-homes__card__distance">${development.Distance} miles</p>` : ``}
                <button class="o-development-homes__card__go-to-map" data-id="${development.Id}">View on map</button>
                <a href="${development.Url}" class="c-button" title="View development" tabindex="0">
                    <span>View details</span>
                </a>
            </div>
        `;
    }

    createLetterCard(letterDevelopments, letter) {
        return `
            <li class="o-development-homes__list-item">
                <h2 class="o-development-homes__list-item__header">${letter}</h2>
                <ul class="o-development-homes__list-item__body">
                    ${letterDevelopments.reduce((markup, development) => markup + this.createLetterListElement(development), '')}
                </ul>
            </li>
        `;
    }

    createLetterListElement(development) {
        return `<li><a href="${development.Url}"><span>${development.Town},</span> ${development.Name}</a></li>`;
    }

    goToMapMark(button) {
        $("html, body").animate({ scrollTop: 0 }, 500);
        this.Map.clickMapMarker($(button).data('id'));
    }

    getUserLocation() {
        return new Promise((resolve, reject) => {
            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(position => resolve({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude
                }), error => resolve(undefined));
            } else {
                reject(undefined);
            }
        });
    }

    updateLoaderText(text) {
        this.$loaderText.text(text);
    }

    updateHeaderText(text) {
        this.$header.html(text);
    }

    showShowMoreIfRequired() {
        if (this.isMoreToLoad) {
            this.$showMore.addClass('o-development-homes__show-more--visible');
        }
    }

    showLoadingOnShowMore() {
        this.$showMore.addClass('o-development-homes__show-more--loading');
    }

    showNoResults() {
        this.$outerContainer.addClass('o-development-homes__cards-container--refine');
    }

    showLoader() {
        this.$loader.removeClass('o-development-homes__loader--hidden');
    }

    hideShowMoreIfRequired() {
        if (!this.isMoreToLoad) {
            this.$showMore.removeClass('o-development-homes__show-more--visible');
        }
    }

    hideShowMore() {
        this.$showMore.removeClass('o-development-homes__show-more--visible');    
    }

    hideLoadingOnShowMore() {
        this.$showMore.removeClass('o-development-homes__show-more--loading');
    }

    hideNoResults() {
        this.$outerContainer.removeClass('o-development-homes__cards-container--refine');
    }

    hideLoader() {
        this.$loader.addClass('o-development-homes__loader--hidden');
    }

    clearCards() {
        this.$cardContainer.empty();
    }

    setGridViewButtonActive() {
        this.$listViewButton.removeClass("o-development-listings-views__button--active");
        this.$gridViewButton.addClass("o-development-listings-views__button--active");
    }

    setListViewButtonActive() {
        this.$gridViewButton.removeClass("o-development-listings-views__button--active");
        this.$listViewButton.addClass("o-development-listings-views__button--active");
    }
}

export default DevelopmentListing;