﻿import Component from 'Component';
import $ from 'jquery';
import App from 'App';
import utils from 'utilities';

//Generic component used for collapsing sidebawr and updating secondary nav
class ScrollTracked extends Component {

    constructor(element){
        super(element, true)

        this.init(() => {
            const { $element } = this;

            //get the id used for anchoring
            this.anchorId = $element.attr('id');
    
            $(()=> {
                //when the component is scrolled in to view
                this.addEventHandler('scrolledIntoView', () => {
                    //collapse the sidebar if the layout is desktop
                    if(utils.device.isLarge())
                        App.setSidebarState('collapsed');
                    //show secondary state if layout is mobile
                    else
                        App.setSidebarState('secondary');

                    //update the secondary nav
                    App.setSecondaryNavState(this.anchorId);
                });
            })
            
        });
    }

}

export default ScrollTracked;