import Component from 'Component';

class ProgressBar extends Component {

  constructor(
    selector, 
    buttonClass = 'step__link', 
    activeButtonClass = 'step__link--active', 
    clickableButtonClass = 'step__link--clickable', 
    progressStepClass = 'progress-step', 
    activeStepClass= 'progress-step--active',
    progressNext = 'progress-next',
    progressPrev = 'progress-prev',
    ) {

    super(selector);
    this.selector = selector;
    this.buttonClass = buttonClass;
    this.activeButtonClass = activeButtonClass;
    this.activeStepClass = activeStepClass;
    this.clickableButtonClass = clickableButtonClass;
    this.progressStepSelector = progressStepClass;
    this.nextButtonSelector = progressNext;
    this.prevButtonSelector = progressPrev;
    this.buttons = document.getElementsByClassName(this.buttonClass);
    this.progressSteps = document.getElementsByClassName(this.progressStepSelector);
    this.nextButton = document.getElementsByClassName(this.nextButtonSelector);
    this.prevButton = document.getElementsByClassName(this.prevButtonSelector);
    this.activeIndex = 0;
    this.progressIndex = null;


    this.init(() => {
      this.bindEvents();
      this.buttons[0].classList.add(this.clickableButtonClass)
      this.setIndex(0, true);
    })
  }

  bindEvents() {
    for(let i = 0; i < this.buttons.length; i++) {
      this.buttons[i].addEventListener('click', (event) => {
        event.preventDefault();
        this.linkClick(event);
      });
    }
    for(let i = 0; i < this.nextButton.length; i++) {
      this.nextButton[i].addEventListener('click', (event) => {
        if (!event.target.classList.contains('disabled')) {
          event.preventDefault();
          this.nextStep();
        }
      });
    }
    for(let i = 0; i < this.prevButton.length; i++) {
      this.prevButton[i].addEventListener('click', (event) => {
        event.preventDefault();
        this.prevStep();
      });
    }
  }

  nextStep() {
    const newIndex = this.activeIndex + 1;
    if (newIndex > this.progressIndex) {
      this.incrementProgressIndex();
    }

    this.setIndex(newIndex);
  }

  prevStep() {
    let newIndex = this.activeIndex - 1;
    newIndex = newIndex < 0 ? 0 : newIndex;

    this.setIndex(newIndex);
  }

  linkClick(event) {
    const indexAttr = event.srcElement && event.srcElement.getAttribute("index");
    const index = parseInt(indexAttr);

    if (index <= this.progressIndex) {
      this.setIndex(index);
    }
  }

  setIndex(index, isPageLoad) {
    if (index >= this.buttons.length) return;

    this.activeIndex = index;
    for(let i = 0; i < this.buttons.length; i++) {
      this.buttons[i].classList.remove(this.activeButtonClass)
    }

    for(let i = 0; i < this.progressSteps.length; i++) {
      this.progressSteps[i].classList.remove(this.activeStepClass)
    }
    this.buttons[this.activeIndex].classList.add(this.activeButtonClass)
    this.progressSteps[this.activeIndex].classList.add(this.activeStepClass)

    if (!isPageLoad) {
      this.buttons[this.activeIndex].scrollIntoView({ behavior: 'smooth', block: 'start'});
      const y = this.buttons[this.activeIndex].getBoundingClientRect().top + window.pageYOffset - 80;
      window.scrollTo({top: y, behavior: 'smooth'});
    }
  }


  incrementProgressIndex() {
    if (this.progressIndex > -1) {
      this.progressIndex++;
    } else {
      this.progressIndex = 0;
    }
    this.buttons[this.progressIndex].classList.add(this.clickableButtonClass)
  }
}

export default ProgressBar;