﻿import $ from 'jquery';

const services = {
    
    searchAutoComplete(query, callback) {

        const url = `https://${window.location.hostname}/bloorapi/autocomplete?query=${query}`; 

        $.get(url)
            .done((data, b, c) => callback(data))
            .fail((xhr, errorMessage, error) => { throw new Error(error) })
    }

}

export default services;
