import $ from 'jquery';
import Component from 'Component';
import utils from 'utilities';

class CtaImageBlock extends Component {

    $button;
    $image;
    $contentContainer;
    $wrapperContainer;
    isOverflowing;
    initialBtnText;
    secondaryBtnText = "Hide";
    isExpanded = false;
    overflowCalculated = true;

    constructor(selector) {            
        super(selector);

        this.init(() => {
            const { $element } = this;

            if (this.isExperienceEditor) return;
            this.$image = $('.cta-image-block-item__image', $element);

            utils.mapImgToBackground(this.$image.find('img'), this.$image);

            if ($('.c-button', $element).length) {

                this.$button = $('.c-button', $element);
                this.$contentContainer = $('.cta-image-block-item__content', $element)
                this.$wrapperContainer = $('.cta-image-block-wrapper', $element)
                this.$imageContainer = $('.cta-image-block-item__image', $element)

                $(() => {
                    this.setUpDeviceHandlers('setUpSmall setUpMediumSmall setUpMediumTouch setUpMediumDesktop setUpLargeTouch setUpLargeDesktop', () => {
                        if (!this.isExpanded) {
                            this.checkOverflow()
                                .updateStyle();
                        }
                        else {
                            this.overflowCalculated = false;
                        }
                    })
                        .bindDeviceHandler()
                        .onBeforeSizeUpdate(() => !this.isExpanded && this.$button.off());
                })
            }
 
        });
    }

    checkOverflow() {

        const contentContainer = this.$contentContainer.get(0);

        this.isOverflowing = utils.isElementOverflown(contentContainer);

        return this;

    }

    updateStyle() {

        if (this.isOverflowing) {
            this.showButton()
                .addGradient()
                .initialBtnText = this.$button.text().trim();
            this.$button.on('click', this.toggleExpanded.bind(this))
        }
        else {
            this.hideButton()
                .removeGradient();
        }

    }

    toggleExpanded() {

        if (!this.isExpanded) {
            this.expandContent()
                .updateButtonText()
                .removeGradient()
                .isExpanded = true;
        }
        else {
            this.shrinkContent()
                .resetButtonText()
                .addGradient()
                .isExpanded = false;

            if (!this.overflowCalculated) {
                this.checkOverflow()
                    .updateStyle();
                this.overflowCalculated = true;
            }

        }
    }

    showButton() {
        this.$button.addClass("c-button--visible");
        return this;
    }

    hideButton() {
        this.$button.removeClass("c-button--visible");
        return this;
    }

    addGradient() {
        this.$contentContainer.addClass('cta-image-block-item__copy--overflown');
        return this;
    }

    removeGradient() {
        this.$contentContainer.removeClass('cta-image-block-item__copy--overflown');
        return this;
    }

    expandContent() {
        this.$wrapperContainer.addClass('cta-image-block-wrapper--open');
        this.$contentContainer.addClass('cta-image-block-item__copy--open');
        this.$imageContainer.addClass('cta-image-block-item__image--open')
        return this;
    }

    shrinkContent() {
        this.$wrapperContainer.removeClass('cta-image-block-wrapper--open');
        this.$contentContainer.removeClass('cta-image-block-item__copy--open');
        this.$imageContainer.removeClass('cta-image-block-item__image--open')
        return this;
    }

    updateButtonText() {
        this.$button.text(this.secondaryBtnText);
        return this;
    }

    resetButtonText() {
        this.$button.text(this.initialBtnText);
        return this;
    }
}

export default CtaImageBlock;
