﻿import $ from 'jquery';
import Component from 'Component';

class Notifications extends Component {

    $contentContainer;
    $notificationContainer;
    $button;
    transitionDuration;
    timeout;

    constructor(selector) {
        super(selector);  

        this.init(() => {
            const { $element } = this;
            
            this.$notificationsContainer = $('.o-notifications', $element);
            this.$notifications = $('.c-banner-notification', $element);
            this.$buttons = $('.c-button', $element);
    
            if(this.$notifications.length === 0) return;
    
            this.$notifications.eq(0).addClass('u-active');
    
            this.$buttons.on('click', event => {
                const $target = $(event.target);
                const $notification = $target.closest(this.$notifications);
                const cookieName = $notification.data('cookie');
                const cookieExpiration = $notification.data('cookie-expiration');     
                
                this.setNotificationCookie(cookieName, cookieExpiration)
                        .hideNotification($notification)
                        .showNextNotification($notification)
            });
        });
    }

    setNotificationCookie(name, expiration) {
    

        var expires = new Date();
        expires.setTime(expires.getTime() +  1 * 1000 * 60 * 60 * 24 * expiration);

        document.cookie = name + '=' + true + ';expires=' + expires.toUTCString();

        return this;
    }

    hideNotification($notification) {

        clearTimeout(this.timeout);

        $notification.removeClass('u-active');

        return this;
    }

    showNextNotification($notification){

        clearTimeout(this.timeout);

        $notification.next().addClass('u-active');        

        return this;
    }

    hide() {
        this.$element.addClass("o-notifications--hidden");
    }

    show() {
        this.$element.removeClass("o-notifications--hidden");
    }

}

export default Notifications;
