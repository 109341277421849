import Component from 'Component';

class Community extends Component {

    constructor(selector) {
      super(selector);

      const improvements = document.getElementsByClassName('improvement');
      const improvementsContainer = document.getElementsByClassName('community__improvements');
      const rowCountClass = improvements.length > 4 || improvements.length % 3 === 0 ? 'three-per-row' : 'two-per-row';
      const portWidth = window.innerWidth;

        this.init(() => {
          if (180 * improvements.length > portWidth && improvementsContainer[0]) {
            improvementsContainer[0].classList.add('draggable');
          }

          for (let i = 0; i < improvements.length; i++) {
            improvements[i].classList.add(rowCountClass);
          }
        });
    }

}

export default Community;