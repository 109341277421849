﻿import $ from 'jquery';
import Component from 'Component';
import utils from 'utilities';
import App from 'App';

class BottomBar extends Component {

    $primaryCTA;
    $parent;
    state;
    active = true;

    constructor(selector, elementToUseAsParent, Sidebar) {
        super(selector);

        this.$parent = elementToUseAsParent;
        this.Sidebar = Sidebar;

        this.init(() => {

            this.createNewElements()
                .append();
        });
    }

    createNewElements() {

        const { $element } = this;

        this.$primaryCTA = $('.primary-button', $element).clone(false)
            .addClass('vacancies-filters')
            .addClass('vacancies-filters--blue')
            .text('Filter vacancies');

        this.$primaryCTA.on('click', this.showListingsFiltersLightBox.bind(this));

        this.$element = this.createBottomButton($element);

        this.$element.append(this.$primaryCTA);

        this.show();

        return this;

    }

    createBottomButton($element) {
        return $($element.get(0).cloneNode()).addClass('c-vacancies-sidebar--secondary')
            .removeAttr('data-component');
    }

    setState(state) {

        if (state === this.state) return;

        switch (state) {
            case 'hidden':
                this.hide();
                break;
            case 'visible':
                this.show();
                break;
            default:
                throw new Error(`'${state}' is and invalid state`)
        }

        this.state = state;

    }

    append() {
        this.$element.appendTo(this.$parent);
    }

    remove() {
        this.$element.remove();
    }

    destroy() {
        this.remove();
        this.active = false;
    }

    reinit() {
        this.append();
        this.$primaryCTA.on('click', this.showListingsFiltersLightBox.bind(this));
        this.active = true;
    }

    hide() {
        const { $element } = this;
        if (!this.active) return;

        $element.removeClass('c-vacancies-sidebar--secondary--visible');
        App.showNotifications();
        return this;
    }

    show() {
        const { $element } = this;

        if (!this.active) return;
        $element.addClass('c-vacancies-sidebar--secondary--visible');
        App.hideNotifications();

        return this;
    }

    showListingsFiltersLightBox(e) {
        e.preventDefault();
        this.Sidebar.showListingsFiltersLightBox();
    }

}


export default BottomBar;