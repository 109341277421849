﻿import $ from 'jquery';
import Component from 'Component';
import FooterItem from 'FooterItem';
import utils from 'utilities';
import App from 'App';
import { debounce, throttle } from 'throttle-debounce';

const { device } = utils;

class Footer extends Component {

    $menu;
    $activeItem;
    $backtotop;
    windowHeight;
    
    constructor(selector) {
        super(selector, true);

        this.init(() => {
            const { $element } = this;
            
            this.$menu = $('.o-footer-menu-main', $element);
    
            this.FooterItems = $('.c-footer-menu__item', $element).get().map((element, i) => new FooterItem(element, this));
    
            this.$backtotop = $('.c-button--back-to-top', $element);

            this.windowHeight = this.$window.height(); 

            let debounced;
    
            this.setUpDeviceHandlers('setUpSmall setUpMediumSmall', () => {
                    this.$menu.addClass('u-mobile-menu');
                    this.$backtotop.on('click', this.scrollToTop.bind(this));
                })
                .setUpDeviceHandlers('setUpMediumTouch setUpMediumDesktop setUpLargeTouch setUpLargeDesktop', () => {
                    this.$backtotop.on('click', this.scrollToTop.bind(this));
                })
                .setUpDeviceHandlers('setUpSmall setUpMediumSmall setUpMediumTouch setUpMediumDesktop', ()=> {

                    this.windowHeight = this.$window.height();                    
                                        
                    debounced = debounce(10, this.handleScroll.bind(this));
                    this.$window.on('scroll', debounced);
                    
                })
                .setUpDeviceHandlers('setUpLargeTouch setUpLargeDesktop', () => {
                    
                    this.windowHeight = this.$window.height();                    

                    debounced = debounce(100, this.handleScroll.bind(this));

                    this.$window.on('scroll', debounced);
         
                })
                .onBeforeSizeUpdate(() => {
                    this.$menu.removeClass('u-mobile-menu');
                    this.$backtotop.off();
                    if(debounced) this.$window.off('scroll', debounced);
                })
                .bindDeviceHandler();
        });

        window.Footy = this;
    }

    closeActiveItem() {
        const { activeItem } = this;

        if (activeItem && activeItem.isOpen) {
            activeItem.close();
        }

        return this;
    }

    scrollToTop() {

        const { $window } = this;
        const elementsToScroll = this.$body.add('html');        

        const cancelAnimation = () => {
            elementsToScroll.stop();
            $window.off('mousewheel mousedown', cancelAnimation);
        }

        $window.on('mousewheel mousedown', cancelAnimation)

        elementsToScroll.animate({
            scrollTop: 10
        }, 1000, ()=> {
            $window.off('mousewheel mousedown', cancelAnimation);
            elementsToScroll.stop();
        });

        return this;
    }

    handleScroll() {

        const { $window } = this;
        const threshold = device.isLarge() ? 100 : 250;
        const scrollPosition = $window.scrollTop();
        const documentHeight =  this.$document.height();

        // if the scroll position is within a certain distance (threshold) from the bottom, update the state of the sidebar so as not to obscure the footer
        if(scrollPosition + this.windowHeight + threshold >= documentHeight) {
            //if the device is large (and thus the sidebar is shown to the side) collapse it - we know device is large if threshold is 100.
            if(threshold === 100) {
                App.setSidebarState('collapsed');
            }            
            else {
                // otherwise if the sidebar is in mobile mode (shown at the bottom), hide it
                App.setSidebarState('primary');
                
                // create a throttled function to be a callback to the scroll event
                const throttled = throttle(100, () => { 

                    // if the scroll position is more than certain distance (threshold) from the bottom
                    if($window.scrollTop() + this.windowHeight + threshold <= documentHeight) {
                        // reshow the sidebar/bottombar
                        App.setSidebarState('secondary');
                            
                        // and remove this event handler
                        $window.off('scroll', throttled);
                    }

                });

                // add the listener
                $window.on('scroll', throttled);

            }

        }

    }
}

export default Footer;