import * as pdfjsLib from 'pdfjs-dist';

class SitePlanPDF {
    constructor(mapContainerSelector, zoomInSelector, zoomOutSelector) {
        this.mapImageContainer = document.querySelector(mapContainerSelector);
        this.canvas = document.querySelector('#o-site-plan__map--canvas');
        this.context = this.canvas.getContext('2d');
        this.zoomInButton = document.querySelector(zoomInSelector);
        this.zoomOutButton = document.querySelector(zoomOutSelector);
        this.scale = 1;
        this.translation = { x: 0, y: 0 };
        this.currentPage = null;
        this.renderTask = null;
        this.isPanning = false;
        this.startPan = { x: 0, y: 0 };
        this.loadingIndicator = document.querySelector('.o-site-plan__map--loading');

        this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        this.devicePixelRatio = this.isSafari ? (window.devicePixelRatio || 1) : 1;

        this.attachZoomHandlers();
        this.initPDFViewer();

        this.setupPanning();
    }

    attachZoomHandlers() {
        const ZOOM_SPEED = 0.1;

        this.zoomInButton.addEventListener('click', (event) => {
            event.preventDefault();
            this.adjustZoom(ZOOM_SPEED);
        });

        this.zoomOutButton.addEventListener('click', (event) => {
            event.preventDefault();
            this.adjustZoom(-ZOOM_SPEED);
        });
    }

    setupPanning() {
        this.canvas.addEventListener('mousedown', this.startPanning.bind(this));
        this.canvas.addEventListener('mousemove', this.pan.bind(this));
        this.canvas.addEventListener('mouseup', this.endPanning.bind(this));
        this.canvas.addEventListener('mouseleave', this.endPanning.bind(this));
        this.canvas.addEventListener('touchstart', this.startPanning.bind(this));
        this.canvas.addEventListener('touchmove', this.pan.bind(this));
        this.canvas.addEventListener('touchend', this.endPanning.bind(this));
    }

    initPDFViewer() {
        pdfjsLib.GlobalWorkerOptions.workerSrc = "/static/build/pdf/pdf.worker.min.mjs";
        
        const checkPdfjsLibLoaded = () => {
            if (typeof pdfjsLib !== 'undefined') {
                this.loadPdf(); 
            } else {
                setTimeout(checkPdfjsLibLoaded, 100);
            }
        };

        this.loadPdf = () => {
            const pdfLink = this.mapImageContainer.querySelector('.o-site-plan__map').getAttribute('data-pdf-link');

            pdfjsLib.getDocument(pdfLink).promise.then(pdfDoc => {
                pdfDoc.getPage(1).then(page => {
                    this.currentPage = page;
                    this.resizeCanvas();
                    this.renderPage(true);
                    this.loadingIndicator.style.display = 'none';
                });
            }).catch(error => {
                console.error('Error during PDF initialization:', error);
                this.loadingIndicator.style.display = 'none';
            });

        };

        try {
            checkPdfjsLibLoaded();
        } catch (error) {
            console.error('Error loading PDF.js library:', error);
        }
    }

    startPanning(event) {
        event.preventDefault();
        this.isPanning = true;
        const clientX = event.clientX || event.touches[0].clientX;
        const clientY = event.clientY || event.touches[0].clientY;
        this.startPan.x = clientX - this.translation.x;
        this.startPan.y = clientY - this.translation.y;
    }

    pan(event) {
        event.preventDefault();
        if (!this.isPanning) return;
        const clientX = event.clientX || event.touches[0].clientX;
        const clientY = event.clientY || event.touches[0].clientY;
        this.translation.x = clientX - this.startPan.x;
        this.translation.y = clientY - this.startPan.y;
        this.updateTransform();
    }

    endPanning(event) {
        event.preventDefault();
        this.isPanning = false;
    }

    updateTransform() {
        const translateX = this.isSafari ? 'calc(-25% + ' : 'calc(-50% + ';
        const translateY = this.isSafari ? 'calc(-25% + ' : 'calc(-50% + ';
        this.canvas.style.transform = `translate3d(${translateX}${this.translation.x}px), ${translateY}${this.translation.y}px), 0) scale(${this.scale})`;
    }

    adjustZoom(zoomAmount) {
        this.scale = Math.max(0.6, Math.min(6, this.scale + zoomAmount));
        this.renderPage();
    }

    renderPage(initial = false) {
        if (!this.currentPage) {
            console.warn("No current page to render");
            return;
        }
        if (this.renderTask) {
            this.renderTask.cancel();
            console.log("Previous render task canceled");
        }
    
        this.loadingIndicator.style.display = 'block';
    
        const viewport = this.currentPage.getViewport({ scale: this.scale * this.devicePixelRatio });
        this.canvas.width = viewport.width;
        this.canvas.height = viewport.height;
    
        if (this.isSafari) {
            this.canvas.style.width = `${viewport.width / this.devicePixelRatio}px`;
            this.canvas.style.height = `${viewport.height / this.devicePixelRatio}px`;
        }
    
        if (initial) {
            this.translation = { x: 0, y: 0 };
            if (this.isSafari) this.scale = 0.5;
        }
    
        this.updateTransform();
    
        const renderContext = {
            canvasContext: this.context,
            viewport: viewport,
        };
    
        this.renderTask = this.currentPage.render(renderContext);
        this.renderTask.promise.then(() => {
            this.renderTask = null;
            this.loadingIndicator.style.display = 'none';
        }).catch(err => {
            if (err.name !== 'RenderingCancelledException') {
                console.error('Error during rendering:', err);
            }
            this.loadingIndicator.style.display = 'none';
        });
    }
    

    resizeCanvas() {
        if (!this.currentPage) return;

        const containerWidth = this.mapImageContainer.clientWidth;
        const containerHeight = this.mapImageContainer.clientHeight;
        const viewport = this.currentPage.getViewport({ scale: 1 });

        const scaleX = containerWidth / viewport.width;
        const scaleY = containerHeight / viewport.height;
        this.scale = Math.max(1, Math.min(scaleX, scaleY));

        this.canvas.width = viewport.width * this.scale;
        this.canvas.height = viewport.height * this.scale;
    }
}

export default SitePlanPDF;
