﻿import $ from 'jquery';
import slick from 'slick-carousel';
import Component from 'Component';
import CarouselComponent from 'Carousel';
import App from 'App';

class ThreeQuaterCarousel extends Component {

    Carousel;

    constructor(selector){
        super(selector, true);
        
        this.init(() => {
            const { $element, $window } = this;
    
            this.setUpDeviceHandlers('setUpLargeTouch setUpLargeDesktop', () => {
                    $window.on('load', this.updateSideBarHeight.bind(this)); 
                    this.updateSideBarHeight();        
                })
                .setUpDeviceHandlers('setUpSmall setUpMediumSmall ', () => {
    
                })
                .addEventHandler('scrolledIntoView', () => {
                    App.setSidebarState('default');
                })
                .bindDeviceHandler();
                //   .onBeforeSizeUpdate();
    
            this.Carousel = new CarouselComponent($element, true);
        });
    }
    
    updateSideBarHeight(){ 
        App.updateSidebarMinHeight(this.$element.height())
    }
  
}

export default ThreeQuaterCarousel;