﻿import $ from 'jquery';
import utils from 'utilities';
import App from 'App';
import { debounce, throttle } from 'throttle-debounce';

const { device } = utils;

class Component {

    $element;    
    selector;
    $window;
    $body;
    $document;
    isExperienceEditor;
    prevScrollPosition;
    headerHeight;
    isValid;

    constructor(selector, trackScroll = false) {
        this.$element = $(selector);
        this.selector = selector;

        this.isValid = this.validate();

        if(!this.isValid) return;

        this.$window = $(window);
        this.$document = $(document);
        this.$body = $('body');
        this.isExperienceEditor = this.$body.hasClass('u-experience-editor');
        this.trackScroll = trackScroll;
        this.headerHeight = App.getHeaderHeight();
        this.viewportWidth = this.$window.width();
        this.viewportHeight = this.$window.height();

        let resizeTimeout;

        this.$window.on('orientationchange resize', debounce(300, () => {

            if(!this.windowWidthHasChanged()) return;

            clearTimeout(resizeTimeout);

            this.fireEvent('beforeSizeUpdate');

            resizeTimeout = setTimeout(() => {
                this.bindDeviceHandler();
                App.getHeaderHeight();
                this.viewportWidth = this.$window.width();
                this.viewportHeight = this.$window.height();
                }, 400);

        }));

    }

    validate() {

        if (this.$element.length === 0 || (!this.$element.get(0).nodeName && this.selector !== window)) {
            throw new Error(`'${this.selector}' was unable to select an element`);
            return false;
        }

        if (this.$element.length > 1) {
            console.log(`'${this.selector}' selected multiple elements, only first will be instantiated`);
            this.$element = this.$element.eq(0);
        }
        
        return true;
        
    }

    init(callback) {

        callback && callback();

        if(this.trackScroll) {
            this.$window.on('scroll', throttle(200, this.checkInView.bind(this)));
        }

    }

    checkInView(e){

        const { $element, $window, prevScrollPosition, headerHeight, viewportHeight } = this;    
        
        const scrollPosition = $window.scrollTop(),
              elementPosition = $element.offset().top,
              edgeThreshold = viewportHeight * 0.15,
              elementHeight = $element.height();

        let scrollDirection = '', isInView = false;   

        if(scrollPosition > prevScrollPosition){
            scrollDirection = 'down';
        }
        else if (scrollPosition < prevScrollPosition){
            scrollDirection = 'up'
        }

        this.prevScrollPosition = scrollPosition;

        if(scrollDirection === 'down'
            && scrollPosition + headerHeight + edgeThreshold > elementPosition  
            && scrollPosition < elementPosition + elementHeight - headerHeight - edgeThreshold
        ) {
            isInView = true;
        }
        else if(scrollDirection === 'up'
            && elementPosition + elementHeight > scrollPosition + viewportHeight / 2 
            && elementPosition < scrollPosition + viewportHeight / 2 
        ){
            isInView = true;
        }
       
        isInView && this.fireEvent('scrolledIntoView');

    }

    bindDeviceHandler() {

        if(device.isLarge()){
            if (device.isTouch()) {
                this.fireEvent('setUpLargeTouch');
            }
            else {
                this.fireEvent('setUpLargeDesktop');
            }
        }
        else if (device.isMedium()) {
            if (device.isTouch()) {
                this.fireEvent('setUpMediumTouch');
            }
            else {
                this.fireEvent('setUpMediumDesktop');
            }
        }
        else if(device.isMediumSmall()){
            this.fireEvent('setUpMediumSmall');
        }
        else {
            this.fireEvent('setUpSmall');
        }

        return this;
    }

    addCustomSizeHandler(breakpoint, callback, upperLimit = false) {

        const oldDeviceHandler = this.bindDeviceHandler.bind(this);

        this.bindDeviceHandler = () => {

            const winWidth = window.innerWidth;

            if(winWidth >= breakpoint && (upperLimit === false || !!upperLimit && winWidth < upperLimit)){
                callback();
            }

            return oldDeviceHandler();
        }  
        
        return this;
    }

    events = {
        setUpSmall: [],
        setUpMediumSmall: [],
        setUpMediumTouch: [],
        setUpMediumDesktop: [],
        setUpLargeTouch: [],
        setUpLargeDesktop: [],
        beforeSizeUpdate: [],
        scrolledIntoView: []
    }
    
    setUpDeviceHandlers(events, ...callbacks) {

        if (typeof events !== 'string') throw new TypeError(`'${events}' is not a valid event string. Events should be a string. For multiple events separate each event with a space`);

        if (!callbacks || !callbacks.length) throw new Error(`please specify one or more callbacks`);

        events = events.trim().split(" ");

        events.forEach((event, i) => {
            this.addEventHandler(event, ...callbacks)
        });

        return this;
    }

    addEventHandler(event, ...callbacks) {
        if (event in this.events) {
            this.events[event] = this.events[event].concat(callbacks);
        }
        else {
            throw new ReferenceError(`'${event}' is not  valid event`);
        }

        return this;
    }

    fireEvent(event) {
        if (event in this.events) {
            this.events[event].forEach(e => e());
        }
        else {
            throw new ReferenceError(`'${event}' is not  valid event`);
        }    

        return this;
    }

    onBeforeSizeUpdate(...callbacks) {
        this.events.beforeSizeUpdate = callbacks.map((callback) => {
            if (typeof callback !== 'function')
                throw new TypeError('arguments to onBeforeSizeUpdate must be functions.')
            else return callback;
        });

        return this;
    }

    detachWindowEvents(){
        
        const  windowEvents =  $._data( window, "events" );
            //copy handlers for the window
        const  windowEventsCopy = Object.assign({}, windowEvents);

        //temporarily remove those handlers
        for(let event in windowEvents){
            windowEvents[event] = null;
        }

        //return function for reattaching handlers
        const reattach = () => {
            setTimeout(()=> Object.assign(windowEvents, windowEventsCopy), 200);
        }   

        return reattach;

    }

    windowWidthHasChanged(){
        return this.viewportWidth !== this.$window.width()
    }
}

export default Component;
