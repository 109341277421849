﻿import $ from 'jquery';
import Component from 'Component';
import { Tabs } from 'foundation-sites';

class TabsNavigation extends Component {

    $dropdown;

    constructor(selector) {

        super(selector);
        

        this.init(() => {

            const { $element } = this;

            this.$dropdown = $('.tabs-mobile-dropdown', $element);
            this.$tabsTitle = $('.tabs-title', $element);

            $element.foundation();
            
            if (this.isExperienceEditor) return;

            $(document).on('click', this.$dropdown, this.toggleMobileMenu.bind(this));

            this.$tabsTitle.on('click', this.isDropdown.bind(this));

            $(window).on('load', _ => {
                this.initDropdownText();
            });

        });
    }
    toggleMobileMenu(event) {
        $(event.target).parent().toggleClass("open");
        event.preventDefault();
    }

    changeDropdownText(event) {
        const $text = $(event.target).html();
        this.$dropdown.text($text);
    }

    initDropdownText(event) {
        const $text = $('.tabs-title.is-active a').html();
        this.$dropdown.text($text);
    }

    closeDropdown(event) {
        const $wrapper = $(event.target).closest('.o-tabs-navigation');
        $wrapper.removeClass('open');
    }

    isDropdown(event) {
        this.changeDropdownText(event);
        this.closeDropdown(event);
    }
}

export default TabsNavigation;