﻿import $ from 'jquery';
import Component from 'Component';
import AccordionItem from './Components/accordion-item';

class Accordions extends Component {

    AccordionItems;
    activeAccordion = null;

    constructor(element) {
        super(element);

        const { $element } = this;

        this.init(() => {
            this.AccordionItems = $('.o-accordions__accordion', this.$element).map((_, el) => new AccordionItem(el, this));
        });

    }

    closeActiveAccordion(){

        const { activeAccordion } = this;

        if(activeAccordion){
            activeAccordion.close();
        }

    }

    setActiveAccordion(Accordion){
        this.activeAccordion = Accordion;
    }

}

export default Accordions;
