﻿import Form from './form';
import $ from 'jquery';

class EnquiryForm extends Form {
    $phoneNumber;
    $message;

    constructor(element){
        super(element);

        this.init(() => {       
            this.$phoneNumber = $('#PhoneNumber', this.$form);
            this.$message = $('.form__textarea', this.$form);

            this.$phoneNumber.rules('add', {
                required: true,
                digits: true,
                messages: {
                    required: 'Please enter a phone number',
                    digits: 'A phone number should only contain numbers'
                }
            });        

            this.$message.rules('add', {
                required: true,
                messages: {
                    required: 'Please enter a message'
                }
            });
        });
    }
}

export default EnquiryForm;