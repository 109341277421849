﻿import $ from 'jquery';
import slick from 'slick-carousel';
import Component from 'Component';

class PlotHeaderCarousel {
    constructor(selector) {
        this.$element = $(selector);
        this.isExperienceEditor = $('body').hasClass('u-experience-editor');
        this.init();
    }

    init() {
        this.$mainCarousel = this.$element.find('.main-carousel .c-carousel-slides');
        this.$thumbCarousel = this.$element.find('.thumbnail-carousel .c-carousel-slides');

        const firstImage = this.$mainCarousel.find('img').first();

        if (firstImage.length && !firstImage[0].complete) {
            firstImage.on('load', () => {
                this.initMainCarousel();
                this.initThumbnailCarousel();
            });
        } else {
            this.initMainCarousel();
            this.initThumbnailCarousel();
        }
    }

    initMainCarousel() {
        const slideCount = this.$mainCarousel.find('.c-carousel-slides__slide').length;

        if(slideCount > 0) {
            this.$mainCarousel.slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                prevArrow: this.$element.find('.c-carousel-prev'),
                nextArrow: this.$element.find('.c-carousel-next'),
                fade: true,
                infinite: !this.isExperienceEditor,
                autoplay: !this.isExperienceEditor,
                autoplaySpeed: 4000,
                dots: slideCount > 2,
                lazyLoad: 'ondemand',
                dotsClass: 'c-carousel-pager',
                asNavFor: this.$thumbCarousel,
            });
        }
    }

    initThumbnailCarousel() {
        const slideCount = this.$thumbCarousel.find('.c-carousel-slides__slide').length;
        const maxWidth = slideCount * 140 + 'px';

        if(slideCount > 0) {
            this.$thumbCarousel.slick({
                slidesToShow: Math.floor(window.innerWidth / 2 / 130),
                asNavFor: this.$mainCarousel,
                dots: false,
                variableWidth: true,
                focusOnSelect: true,
                infinite: !this.isExperienceEditor,
                swipeToSlide: true,
                centerMode: false,
                draggable: true,
            });
        }
    }
}

export default PlotHeaderCarousel;