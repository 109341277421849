﻿import Component from 'Component';
import $ from 'jquery';
import utils from 'utilities';
import App from 'App';

class OverviewContent extends Component {

    $button;
    $contentContainer;
    $content;
    contentHeight;
    isOverflowing;
    initialBtnText;
    secondaryBtnText = "Hide";
    isExpanded = false;
    overflowCalculated = true;
    anchorId;

    constructor(element){
        super(element, true)

        this.init(() => {
            const { $element } = this;
            
            this.$button = $('.c-button', $element);
            this.$contentContainer = $('.c-overview__text-container', $element);
            this.$content = $('*', this.$contentContainer);

            this.anchorId = $element.attr('id');
            this.developmentName = $element.attr('development-name');
            let div = document.createElement("div");

            div.innerHTML = document.getElementById('developmentInfo')?.innerText || '{}';
            this.gtmData = JSON.parse(div.textContent || div.innerText) || {};
    
            $(()=> {
    
                this.setUpDeviceHandlers('setUpSmall setUpMediumSmall setUpMediumTouch setUpMediumDesktop setUpLargeTouch setUpLargeDesktop', () => {
                    if(!this.isExpanded) {
                        this.checkOverflow()
                            .updateStyle();
                    }
                    else {
                        this.overflowCalculated = false;
                    }
                })
                .addEventHandler('scrolledIntoView', () => {

                    if(utils.device.isLarge()) {
                        App.setSidebarState('default');
                    } else { 
                        App.setSidebarState('secondary');
                    }

                    App.setSecondaryNavState(this.anchorId);
                })
                .bindDeviceHandler()
                .onBeforeSizeUpdate(() => !this.isExpanded && this.$button.off());
    
            })
            
        });
    }

    checkOverflow(){

       const contentContainer = this.$contentContainer.get(0);
       
       this.isOverflowing = utils.isElementOverflown(contentContainer);

       return this;

    }

    updateStyle(){

        if(this.isOverflowing){
            this.showButton()
                .addGradient()           
                .initialBtnText = this.$button.text().trim();
            this.$button.on('click', this.toggleExpanded.bind(this))
        }
        else {
            this.hideButton()
                .removeGradient();
        }

    }

    toggleExpanded() {
        if (window.dataLayer) {
          window.dataLayer.push({
            event: 'readMore',
            developmentName: this.gtmData.name,
            developmentStatus: this.gtmData.status,
            region: this.gtmData.region,
            geoLocation: this.gtmData.county,
            location: this.gtmData.town,
          });
        }

        if(!this.isExpanded){
            this.expandContent()
                .updateButtonText()
                .removeGradient()
                .isExpanded = true;
        }
        else {
            this.shrinkContent()
                .resetButtonText()
                .addGradient()
                .isExpanded = false;

            if(!this.overflowCalculated) {
                this.checkOverflow()
                    .updateStyle();
                this.overflowCalculated = true;
            }

        }
    }

    showButton(){
        this.$button.addClass("c-button--visible");
        return this;
    }

    hideButton(){
        this.$button.removeClass("c-button--visible");
        return this;
    }

    expandContent(){
        this.$contentContainer.addClass("c-overview__text-container--open");
        return this;
    }

    shrinkContent() {
        this.$contentContainer.removeClass("c-overview__text-container--open");
        return this;
    }

    updateButtonText(){
        this.$button.text(this.secondaryBtnText);
        return this;
    }

    resetButtonText(){
        this.$button.text(this.initialBtnText);
        return this;
    }

    addGradient(){
        this.$contentContainer.addClass('c-overview__text-container--overflown');
        return this;
    }

    removeGradient(){
        this.$contentContainer.removeClass('c-overview__text-container--overflown');
        return this;
    }

}

export default OverviewContent;