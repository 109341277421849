﻿import $ from 'jquery';
import externalVideoFactory from 'externalVideoFactory';
import App from 'App';
import IframeCarousel from 'IframeCarousel';
import utils from 'utilities';

class VideoCarousel extends IframeCarousel {

    $slideVideos;
    Videos;
    anchorId;

    constructor(selector) {

        const $videos = $(".c-carousel-slides__iframe-container", $(selector));

        super(selector, true);

        this.init(() => {

            const { $element } = this;

            if (this.isExperienceEditor) return; //if we are in the experience editor we want the carousel to be static;

            this.$slideVideos = $videos;

            this.anchorId = this.$element.attr('id');

            this.Videos = this.$slideVideos.map((_, el) => externalVideoFactory(el, ".c-carousel-slides__iframe-button", '.c-carousel-slides__iframe-content', undefined));

            if(this.Videos[0]) this.Videos[0].load();

            this.setUpDeviceHandlers('setUpSmall setUpMediumSmall setUpMediumTouch setUpMediumDesktop setUpLargeTouch setUpLargeDesktop', () => {
                this.Carousel.beforeSlideChange((e, slick, current) => {
                    this.Videos[current].stop();
                    this.Videos[current].showOverlayElements();
                })
                this.Carousel.afterSlideChange((e, slick, current) => {
                    this.Videos[current].load();
                })
            })
                .addEventHandler('scrolledIntoView', () => {
                    if (utils.device.isLarge())
                        App.setSidebarState('collapsed');
                    else
                        App.setSidebarState('secondary');

                    App.setSecondaryNavState(this.anchorId);
                })
                .bindDeviceHandler();

            this.$window.on('fullscreenchange msfullscreenchange mozfullscreenchange webkitfullscreenchange', () => {
                if (utils.isFullScreen()) return;

                this.$element.get(0).scrollIntoView();
            });
        })

    }
}


export default VideoCarousel;