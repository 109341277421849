﻿import Component from 'Component';
import App from 'App';
import icons from './Helpers/icons';

class DevelopmentListingsMap extends Component {
    constructor(element, DevelopmentListings) {
        super(element);

        this.element = element;
        this.DevelopmentListings = DevelopmentListings;
        this.Filters = DevelopmentListings.Filters;

        // Declare variable initial values
        this.map = null;
        this.infowindow = null;
        this.locationRange = null;
        this.google = null;
        this.markers = [];

        this.init(() => {
            this.mapElement = this.element.querySelector('.c-development-homes-map__content');
        });
    }

    bindEvents() {
        const zoomInButton = this.element.querySelector('.c-zoom-control--in');
        const zoomOutButton = this.element.querySelector('.c-zoom-control--out');

        this.google.maps.event.addDomListener(zoomInButton, 'click', () => {
            this.map.setZoom(this.map.getZoom() + 1);
        });
        this.google.maps.event.addDomListener(zoomOutButton, 'click', () => {
            this.map.setZoom(this.map.getZoom() - 1);
        });
    }

    fetchMapData() {
        fetch(`/bloorapi/developmentsmap${this.DevelopmentListings.prepareSearchParams()}`)
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then((response) => {
                if (this.google) {
                    this.processData(response);
                } else {
                    App.setupGoogleMapAPI(() => {
                        this.google = google;
                        this.processData(response);
                    });
                }
            })
            .catch((error) => {
                this.logError(error);
            });
    }

    processData(response) {
        const {
            MapItems: mapItems
        } = response.Developments;
        const mapConfig = this.prepareMapConfig(response.Developments, this.DevelopmentListings.radius);

        if (mapItems.length > 0) {
            if (!this.map) {
                this.initMap(mapConfig);
                this.bindEvents();
            } else {
                this.refreshPosition(mapConfig);
            }

            mapItems.forEach((item) => {
                this.createMarker(item);
            });

            this.showMap();
        } else {
            this.hideMap();
        }
    }

    refreshPosition(mapConfig) {
        this.clearMap();
        this.map.setCenter(mapConfig.location);
        this.map.setZoom(mapConfig.zoom);

        if (mapConfig.locationFound && !this.Filters.region) {
            this.setPositionAndRange(mapConfig);
        }
    }

    setPositionAndRange(mapConfig) {
        this.locationRange = new this.google.maps.Circle({
            strokeWeight: 0,
            fillColor: '#8cd3e2',
            fillOpacity: 0.40,
            map: this.map,
            center: mapConfig.location,
            radius: mapConfig.radius * 1609.344
        });

        let marker = new this.google.maps.Marker({
            id: "position-marker",
            map: this.map,
            position: mapConfig.location,
            icon: icons.searchLocation
        });
        this.markers.push(marker);
    }

    initMap(mapConfig) {
        this.map = new this.google.maps.Map(this.mapElement, {
            center: mapConfig.location,
            zoom: mapConfig.zoom,
            disableDefaultUI: true,
            mapTypeControl: true,
            mapTypeId: 'hybrid',
        });

        if (mapConfig.locationFound && !this.Filters.region) {
            this.setPositionAndRange(mapConfig);
        }
        this.infowindow = new this.google.maps.InfoWindow();
        google.maps.event.addListener(this.infowindow, 'domready', () => {
            var iwOuter = this.element.querySelector('.gm-style-iw');
            iwOuter.classList.add("development-homes-map__gm");
            var iwBackground = iwOuter.previousElementSibling;
            iwBackground.children[1].style.display = 'none';
            iwBackground.children[3].style.display = 'none';
        });
    }

    createMarker(development) {
        let _this = this;

        let marker = new this.google.maps.Marker({
            id: development.Id,
            map: _this.map,
            position: {
                lat: parseFloat(development.Latitude),
                lng: parseFloat(development.Longitude)
            },
            icon: {
                url: development.IsActive ? icons.currentDev : icons.coomingSoon,
                scaledSize: new this.google.maps.Size(23, 35)
            }
        });
        _this.markers.push(marker);

        _this.google.maps.event.addListener(marker, 'click', function () {
            const developmentData = _this.getDevelopmentDataFromList(development.Id);

            if (developmentData !== null) {
                _this.openInfoWindow(developmentData, this);
            } else {
                _this.fetchDevelopmentData(development, this);
            }
        });
    }

    getDevelopmentDataFromList(id) {
        let development = null;
        const {
            developmentsList
        } = this.DevelopmentListings;

        if (developmentsList) {
            developmentsList.forEach((item) => {
                if (item.Id === id) {
                    development = item;
                }
            });
        }
        return development;
    }

    openInfoWindow(development, openPopupEvent) {
        this.infowindow.setContent(this.createInfoContent(development));
        this.infowindow.open(this.map, openPopupEvent);
    }

    fetchDevelopmentData(item, openPopupEvent) {
        fetch(`/bloorapi/development?location=London&id=${item.Id}`)
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then(response => {
                this.openInfoWindow(response, openPopupEvent);
            })
            .catch(error => {
                this.logError(error);
            });
    }

    createInfoContent(development) {
        const developmentImage = development.ResizedImage && development.ResizedImage.Large ? development.ResizedImage.Large : "/-/media/Thumbnails/Feature/Plots/Home-Default.png";

        return `<div class="pin-info">
                    <div class="pin-info__left">
                        <div class="pin-info-image-box">
                            <img loading="lazy" class="pin-info-image-box__image" src="${developmentImage}" />
                        </div>
                        <a class="pin-info__link" href="${development.Url}">View development</a>
                    </div>
                    <div class="pin-info__right">
                        <h3 class="pin-info__name">${development.Name}</h3>
                        <p class="pin-info__distance">${development.Distance} miles</p>
                        <p class="pin-info__address">${development.Town} ${development.PostCode}</p>
                        <p class="pin-info__tagline">${development.Tagline}</p>
                    </div>
                </div>`;
    }

    clearMap() {
        if (this.locationRange) {
            this.locationRange.setMap(null);
        }
        this.markers.forEach((marker) => {
            marker.setMap(null);
        });

        this.markers = [];
        this.locationRange = null;
    }

    chooseZoom(radius) {
        switch (radius) {
            case 20:
            case 30:
                return 9;
            case 40:
            case 50:
            case 60:
                return 8;
            case 70:
            case 80:
            case 90:
            case 100:
                return 7;
            default: 
                return 8;
        }
    }

    prepareMapConfig(data, radius) {
        return {
            location: {
                lat: parseFloat(data.Latitude),
                lng: parseFloat(data.Longitude)
            },
            locationFound: data.LocationFound,
            zoom: data.LocationFound ? this.chooseZoom(radius) : 7,
            radius: radius,
            mapTypeControl: true,
            mapTypeId: 'hybrid',
        };
    }

    showMap() {
        this.element.classList.add('c-development-homes-map--active');
    }

    hideMap() {
        this.element.classList.remove('c-development-homes-map--active');
    }

    clickMapMarker(id) {
        let _this = this;

        _this.markers.forEach(function (marker) {
            if (marker.id === id) {
                _this.google.maps.event.trigger(marker, 'click');
            }
        });
    }

    logError(error) {
        if (error.message === 'Not Found') {
            throw Error('The local information data could not be loaded. Please try again.');
        } else {
            throw Error(`Error: ${error.message}`);
        }
    }
}

export default DevelopmentListingsMap;