import Dropdown from './dropdown';

class Range {
    constructor(minDropDown, maxDropDown) {
        this.MinDropDown  = new Dropdown(minDropDown, this.verifyMaxValue.bind(this));
        this.MaxDropDown = new Dropdown(maxDropDown, this.verifyMinValue.bind(this));
    }

    verifyMaxValue(value){
        
        const int = parseFloat(value);
            
        const {
            MaxDropDown: {
                value: maxValue
            }, MaxDropDown
        } = this;

        if(!maxValue || parseFloat(maxValue) < int) MaxDropDown.updateValue(int);
    }

    verifyMinValue(value){

        const int = parseFloat(value);
        
        const {
            MinDropDown: {
                value: minValue
            }, MinDropDown
        } = this;

        if(minValue && parseFloat(minValue) > int) MinDropDown.updateValue(int);
    }

}

export default Range;