import Component from 'Component';

class DevelopmentCardBlock extends Component {

  constructor(
    selector, 
    backCtaSelector = 'view-development-cta',
    distanceSelector = 'distance__value',
    ) {

    super(selector);
    this.selector = selector;
    this.backCtaSelector = backCtaSelector;
    this.distanceSelector = distanceSelector;
    this.backCtas = document.querySelectorAll(`.${this.backCtaSelector}`);
    this.distanceEls = document.querySelectorAll(`.${this.distanceSelector}`);
    this.gtmData = {};


    this.init(() => {
      let div = document.createElement("div");

      div.innerHTML = document.getElementById('developmentInfo')?.innerText || '{}';
      this.gtmData = JSON.parse(div.textContent || div.innerText);
    
      this.backCtas.forEach((cta, i) => {
        cta.addEventListener('click', () => {
          const distance = this.distanceEls.length ? this.distanceEls[i].innerText : 0;

          if (window.dataLayer) {
            window.dataLayer.push({
              event: 'ViewDevelopment',
              developmentName: this.gtmData.name,
              developmentStatus: this.gtmData.status,
              region: this.gtmData.region,
              geoLocation: this.gtmData.county,
              location: this.gtmData.town,
              distance: parseFloat(distance)
            });
          };
        });
      })
      
    });
  }
}

export default DevelopmentCardBlock;