﻿import $ from 'jquery';
import slick from 'slick-carousel';
import Component from 'Component';

class Carousel extends Component {
    constructor(selector, autoplay = false, options = {}) {
        super(selector);

        this.autoplay = autoplay;
        this.options = options;

        this.init(() => {
            // If we are in the experience editor, we want the carousel to be static.
            if (this.isExperienceEditor) return;

            const { $element } = this;

            this.$slidesContainer = $('.c-carousel-slides', $element);

            if (!this.$slidesContainer || this.$slidesContainer.length < 1) return;

            this.$window.on('load', () => {
                this.waitForFirstImage().then(() => {
                    this.generateCarousel()
                        .setUpDeviceHandlers('setUpSmall setUpMediumSmall setUpMediumTouch setUpLargeTouch', () => {
                            this.showPagination();
                        })
                        .setUpDeviceHandlers('setUpMediumDesktop setUpLargeDesktop', () => {
                            this.$slidesContainer.on('mouseover', this.showHiddenElements.bind(this))
                                .on('mouseleave', this.hideHiddenElements.bind(this));
                        })
                        .bindDeviceHandler()
                        .onBeforeSizeUpdate(() => this.$slidesContainer.off());
                });
            });
        });
    }

    waitForFirstImage() {
        const firstImage = this.$slidesContainer.find('img').first();
        const isHeroCarousel = this.$element.closest('.o-hero-carousel').length > 0;

        if (isHeroCarousel) {
            firstImage.removeAttr('loading');
        }

        if (firstImage.length && !firstImage[0].complete) {
            return new Promise((resolve) => {
                firstImage.on('load', resolve).on('error', resolve);
            });
        }

        return Promise.resolve();
    }

    generateCarousel() {
        let settings = {
            slidesToShow: 1,
            dots: true,
            dotsClass: 'c-carousel-pager',
            autoplay: this.autoplay,
            autoplaySpeed: 4000
        };

        const { options } = this;

        if (options) {
            settings = { ...settings, ...options };
        }

        this.$slidesContainer.slick(settings);
        this.$pagination = $('.c-carousel-pager', this.$element);

        return this;
    }

    showHiddenElements(event) {
        event.stopPropagation();
        this.$slidesContainer.addClass('u-hover');
        this.showPagination();

        return this;
    }

    hideHiddenElements(event) {
        event.stopPropagation();
        this.$slidesContainer.removeClass('u-hover');
        this.$pagination.removeClass('u-hover');

        return this;
    }

    showPagination() {
        this.$pagination.addClass('u-hover');
        return this;
    }

    afterSlideChange(callback) {
        this.$slidesContainer.on('afterChange', callback);
        return this;
    }

    beforeSlideChange(callback) {
        this.$slidesContainer.on('beforeChange', callback);
        return this;
    }
}

export default Carousel;
