﻿import $ from 'jquery';
import slick from 'slick-carousel';
import Component from 'Component';
import CarouselComponent from 'Carousel';
import App from 'App';

class SecondaryHeroBanner extends Component {

    Carousel;

    constructor(selector) {
        super(selector, true);

        this.init(() => {

            const { $element, $window } = this;

            this.Carousel = new CarouselComponent($element, true);

        });
    }

}

export default SecondaryHeroBanner;