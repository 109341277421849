﻿import $ from 'jquery';
import Component from 'Component';

class Tour extends Component {

    $button;
    $overlay;
    $content;
    $thumbnail;
    sourceSet = false;
    url;

    constructor(selector, button = 'button', overlay = 'overlay', content = 'tour', thumbnail = 'thumbnail', carouselID, tourID) {

        super(selector);

        this.init(() => {

            const { $element } = this;

            if(this.isExperienceEditor) return;

            this.uniqueId = carouselID + "_" + tourID;

            this.$button = typeof (button === 'string' && !button.jquery) ? $(button, $element) : button;
            this.$overlay = typeof (overlay === 'string' && !overlay.jquery) ? $(overlay, $element) : overlay;
            this.$content = typeof (content === 'string' && !content.jquery) ? $(content, $element) : content;
            this.$thumbnail = typeof (thumbnail === 'string' && !thumbnail.jquery) ? $(thumbnail, $element) : thumbnail;
            this.url = this.$content.data('url');

            this.addCustomSizeHandler(1015, () => {
                    this.$button.on('click', () => {
                        this.updateSource()
                            .hideOverlayingElements()
                            .showIframe();
                    })
                })
                .addCustomSizeHandler(0, () => {
                    this.$element.on('click', () => {
                        window.open(this.url, this.uniqueId);
                    })
                }, 1015)
                .bindDeviceHandler()
                .onBeforeSizeUpdate(() => {
                    
                    this.removeSource()
                        .showOverlayingElements()
                        .hideIframe()
                        .$button.off('click')
                    
                    this.$element.off('click');
                })

        })

    }

    updateSource() {

        if(!this.sourceSet) {
            this.$content.attr('src', this.url);            
            this.sourceSet = true;
        }

        return this;
    }

    hideOverlayingElements() { 
        this.$thumbnail.addClass('u-hidden');
        this.$button.addClass('u-hidden');
        this.$overlay.addClass('u-hidden');

        return this;
    }

    showOverlayingElements() {
        this.$thumbnail.removeClass('u-hidden');
        this.$button.removeClass('u-hidden');
        this.$overlay.removeClass('u-hidden');

        return this;
    }

    showIframe () {
        this.$content.addClass('c-carousel-slides__iframe-content--visible');

        return this;
    }

    hideIframe() {
        this.$content.removeClass('c-carousel-slides__iframe-content--visible');

        return this;
    }

    removeSource() {

        if(this.sourceSet) {
            this.$content.attr('src', '');
            this.sourceSet = false;            
        } 

        return this;
    }

}


export default Tour;