﻿import $ from 'jquery';
import Component from 'Component';
import services from 'services';
import utils from 'utilities';
import { debounce } from 'throttle-debounce';
import GoalFiring from '../../../../src/Foundation/SitecoreExtensions/code/Scripts/GoalFiring/goal-firing';

class Search extends Component {

    $form;
    $input;
    $button;
    $searchLocation;
    $listDevelopments;
    $linksContainer;
    $autoComplete;
    $autoCompleteResults;
    $places;
    $developments;
    $selectedResult;
    maxItems = 30;
    autoCompleteVisible = false;
    linksContainerVisible = false;
    selectedIndex = -1;

    constructor(element) {
        super(element);

        this.init(() => {
                
            const { $element } = this;
    
            this.$form = $('.c-search__form', $element);
            this.$input = $('.c-search__input', this.$form);
            this.$button = $('.c-search__button', this.$form);
            this.$linksContainer = $('.o-search__link-container', $element);
            this.$searchLocation = $('.c-search__by-location', $element);
            this.$listDevelopments = $('.c-search__list-all', $element);
    
            this.$autoComplete = $(".c-search__autocomplete", $element);
    
            this.$places = $('.c-search__places', $element);
            this.$developments = $('.c-search__developments', $element);
    
            this.$form.on('submit', this.postQuery.bind(this));

            let preDebounceFunc = this.setButtonState.bind(this);
            let debounceFunc = debounce(200, this.handleInputKeyPress.bind(this));
            
            this.$input.on('keyup', (e) => { preDebounceFunc(); debounceFunc(e); } );
            this.$element.on('keydown', this.traverseResults.bind(this));
    
            this.$input.on('click', () => {
                utils.onClickOutside(this.$input, this.hideLinksContainer.bind(this), this.hideAutoComplete.bind(this));
                this.showLinksContainer()
                    .showAutoComplete();
            });           
    
            this.$listDevelopments.on('click', this.searchDevelopments)
    
            this.checkInput()
                .bindDeviceHandler();
        });
    }

    postQuery(event) {
        event.preventDefault();

        const inputValue = this.$input.val();

        if (!inputValue || inputValue.length == 0) return;

        try {
          new GoalFiring().fireGoal(this.$form
            .closest('[data-goal-endpoint-custom-trigger]')
            .attr('data-goal-endpoint'));
        } catch { }

        window.location.href = "/developments?location=" + inputValue;
    }

    handleInputKeyPress(e) {

        const key = e.which;

        switch (key) {
            //left and right arrows and enter
            case 38:
            case 40:
            case 37:
            case 39:
            case 13:
                return;
            //other keys
            default:
                this.checkInput();
        }
    }

    setButtonState() {
        const { $input } = this,
            value = $input.val(); 

        if (!value || value.length === 0) {
            this.disableButton();
        }
        else {
            this.enableButton()
        }
    }

    checkInput() {
        const { $input } = this,
            value = $input.val();         
        
        if (value && value.length > 0) {
            this.requestAutoComplete();
        }

        return this;

    }

    enableButton() {
        this.$button.prop('disabled', false);

        return this;
    }

    disableButton() {
        this.$button.prop('disabled', true);

        return this;
    }

    searchDevelopments(e) {
        e.preventDefault();
        window.location.href = "/developments"; 

        return this;
    }

    requestAutoComplete() {

        const value = this.$input.val();

        if (value.length >= 3)
            services.searchAutoComplete(value, this.sortAutoCompleteData.bind(this))
        else {
            this.clearAutoComplete()           
                .showLinksContainer();
        }

        return this;
    }

    sortAutoCompleteData(json) {

        const categorisedElements = 
            json.sort((a, b) => {
                if (a.Name > b.Name) {
                    return 1;
                }
                if (a.Name < b.Name) {
                    return -1;
                }
                return 0;
            })
            .slice(0, this.maxItems)
            .reduce((object, current) => {

                const type = current.Type.toLowerCase();

                if (type === "place") {
                    object.places += `<li><a href="${current.URL.replace('search/#/q=', 'developments?location=')}">${current.Name}, ${current.County}</a></li>`
                }
                else if (type === "development") {
                    object.developments += `<li><a href="${current.URL}">${current.Name}, ${current.County}</a></li>`
                }

                return object;

            }, { places: '', developments: '' });

        this.updateAutoComplete(categorisedElements.places, categorisedElements.developments);      

        return this;
            
    }

    updateAutoComplete(places, developments) {

        this.clearAutoComplete()
            .resetSelectedIndex();

        if (places && places.length > 0) {
            this.$places.append(places)
            this.autoCompleteVisible = true;
            this.hideLinksContainer();
        }

        if (developments && developments.length > 0) {
            this.$developments.append(developments)
            this.autoCompleteVisible = true;
            this.hideLinksContainer();
        }         

        if (developments.length === 0 && places.length === 0) {
            this.showLinksContainer()
                .clearAutoCompleteResults();
        }
        else {
            this.updateAutoCompleteResults()
                .attachAutoCompleteItemEvents();
        }

        return this;
    }

    clearAutoComplete() {

        if (this.autoCompleteVisible) {
            this.$places.empty();
            this.$developments.empty();
            this.clearAutoCompleteResults();
        }

        this.autoCompleteVisible = false;

        return this;
    }

    updateAutoCompleteResults() {
        this.$autoCompleteResults = $('li', this.$autoComplete);

        return this;
    }

    attachAutoCompleteItemEvents() {
        this.$autoCompleteResults.on('mouseover', this.clearSelectedResult.bind(this));

        return this;
    }

    clearAutoCompleteResults() {
        this.$autoCompleteResults = null;

        return this;
    }

    clearSelectedResult() {
        if (this.$selectedResult) {
            this.$selectedResult.removeClass('u-hover');
            this.$selectedResult = null;
            this.resetSelectedIndex();
        }

        return this;
    }

    hideLinksContainer() {
        const { $linksContainer } = this;

        if (this.linksContainerVisible) {
            $linksContainer.removeClass("u-visible");
            this.linksContainerVisible = false;
        }

        return this;
    }

    showLinksContainer() {
        const { $linksContainer } = this;

        if (!this.autoCompleteVisible && !this.linksContainerVisible) { 
            $linksContainer.addClass("u-visible");
            this.linksContainerVisible = true;
        }

        return this;
    }

    hideAutoComplete() {
        this.$autoComplete.removeClass("u-visible");

        return this;
    }

    showAutoComplete() {
        this.$autoComplete.addClass("u-visible");

        return this;
    }

    traverseResults(e) {

        const key = e.which, { $autoCompleteResults } = this;

        //if not up and down arrow or no restuls to traverse return
        if ((key !== 38 && key !== 40) || !$autoCompleteResults) return;


        const moveDown = key === 40;

        if (moveDown && this.selectedIndex < $autoCompleteResults.length -1) {
            this.selectedIndex++;
        }
        else if (!moveDown && this.selectedIndex > -1) {
            this.selectedIndex--;
        }

        this.updateSelectedResult();

        e.preventDefault();
        e.stopPropagation();

        return this;

    }

    updateSelectedResult() {       
        const { selectedIndex } = this;
        const bodyOffset = this.$body.scrollTop();

        this.$selectedResult && this.$selectedResult.removeClass("u-hover");

        if (selectedIndex !== -1) {               

            this.$selectedResult = this.$autoCompleteResults.eq(selectedIndex);

            const { $selectedResult } = this;

            $selectedResult.addClass("u-hover");
            this.$input.blur();         

            $selectedResult.find('a').focus();

        }
        else {                
            this.$input.focus();                         
        }
        
        this.adjustScrollPosition(bodyOffset);

        return this;
        
    }

    resetSelectedIndex() {
        this.selectedIndex = -1;

        return this;
    }
    
    adjustScrollPosition(bodyOffset) {

        const { $body } = this;  
        const { selectedIndex } = this;

        $body.scrollTop(bodyOffset); // hold original position after focus

        if (selectedIndex === -1) {
            const formOffset = this.$form.offset().top;  

            if (formOffset < 0) {
                $body.scrollTop(bodyOffset - Math.abs(formOffset) - 30);
            }       
        }
        else {

            const windowHeight = this.$window.height();
            const { $selectedResult } = this;

            //result item vertical extremeties
            const result = {
                top: $selectedResult.offset().top,
                bottom: $selectedResult.offset().top + $selectedResult.get(0).offsetHeight
            }

            //if off bottom of screen 
            if (result.bottom > windowHeight) {
                $body.scrollTop(bodyOffset + result.bottom - windowHeight + 30);
            }
            //if off top of screen
            else if (result.top < 0) {
                $body.scrollTop(bodyOffset - Math.abs(result.top) - 30);
            }
        }

        return this;

    }

}

export default Search;
