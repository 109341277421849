﻿import $ from 'jquery'
import Component from 'Component';

class Pagination extends Component {

    currentPageIndex = 0; // the page we are on
    numberOfPages; // the number of pages - usually set by class that extends this
    maxPaginationItems; // the maxinmum number of pagination items that can be shown before showing collapsed state
    isCollapsed = false; // indcates whether pagination in collaspes state - only ever collapsed in number of pages exceeds the max number of pagination items

    // elements
    $paging; // paging elements
    $nextArrows; // arrows for navigating to next page
    $backArrows; // arrows for navigation to previous page
    $paginationItems; // collection of the pagination items
    $activePaginationitems; // the currently active pagination items (i.e page number)
    $penultimatePaginationItems; // only used in collapsed state  

    $component; 

    constructor(selector, component, trackScroll = false, nextArrows = '.c-pagination__arrow--next', backArrows = '.c-pagination__arrow--back', paging = '.c-pagination__pagin', maxPaginationItems = 10) {
        //pass component element whether the component should be tracked 
        //to the extended base 'Component' class
        super(selector, trackScroll);

        this.init(() => {

            const { $element } = this;

            this.$component = component;

            //if selector string passed, select element within component, otherwise assume element was passed
            //then store the elements on the class properties
            this.$nextArrows = typeof nextArrows === 'string' ? $(nextArrows, $element) : nextArrows;
            this.$backArrows = typeof backArrows === 'string' ? $(backArrows, $element) : backArrows;
            this.$paging = typeof paging === 'string' ? $(paging, $element) : paging;

            //when the window is small or medium small
            this.setUpDeviceHandlers('setUpSmall setUpMediumSmall', () => {
                //set the max number of pagination items to 6
                this.maxPaginationItems = 6;

                //if the number of pages exceeds the maximum number of pagination items to show
                if (this.numberOfPages > this.maxPaginationItems) {
                    //add the collapsed state
                    this.addCollapsedState();
                }

            })
                //when the window is medium or large
                .setUpDeviceHandlers('setUpMediumDesktop setUpMediumTouch setUpLargeDesktop setUpLargeTouch', () => {
                    //set the max number of pagination items to the param passed to constructor
                    this.maxPaginationItems = maxPaginationItems;

                    //if the number of pages exceeds the maximum number of pagination items to show
                    if (this.numberOfPages > this.maxPaginationItems) {
                        //add the collapsed state
                        this.addCollapsedState();
                    }

                })
                //when the wwindow is resized, before setiing up appropriate functionality for the viewport
                .onBeforeSizeUpdate(() => {
                    //uncollapse the pagination
                    this.$paging.removeClass(this.$component+'__pagination-paging--collapsed');
                    this.isCollapsed = false;
                })
                .bindDeviceHandler();

            //set up event listeners for arrows to navigate forward and backwards through pages
            this.$nextArrows.on('click', this.goNextPage.bind(this));
            this.$backArrows.on('click', this.goPrevPage.bind(this));

        });

    }

    goNextPage() {

        //if we are not on the last page
        if (this.currentPageIndex < this.numberOfPages - 1) {
            //increase current page index
            const newIndex = this.currentPageIndex + 1;
            //load new page
            this.goToPage(newIndex);
        }

    }

    goPrevPage() {

        //if we are not on the first page
        if (this.currentPageIndex > 0) {
            //decrease current page index
            const newIndex = this.currentPageIndex - 1;
            //load the new page
            this.goToPage(newIndex);
        }

    }

    goToPage(pageIndex = this.currentPageIndex, override = false) {

        //if we are trying to load the current page, dont continue unless forcd to override
        if (pageIndex !== this.currentPageIndex || override) {

            //set the curent index to the new page
            this.currentPageIndex = pageIndex;

            //load the new page - functionality usually defined on the class that extends this
            this.loadPage();

            //deactiveate the old pagination items
            this.$activePaginationitems.removeClass('u-active');

            //activate the new pagination items;
            this.$activePaginationitems = this.$paginationItems.filter(`[data-page-index="${pageIndex}"]`).addClass('u-active');

            //if we are on the first page 
            if (pageIndex === 0) {
                //disable the back arrows
                this.$backArrows.addClass(this.$component+'__arrow--disabled')
            }
            else {
                //otherwise enable them
                this.$backArrows.removeClass(this.$component+'__arrow--disabled')
            }

            //if we are on the last page
            if (pageIndex === this.numberOfPages - 1) {
                //disable the next arrows
                this.$nextArrows.addClass(this.$component+'__arrow--disabled')

                //if we are in the collased state
                if (this.isCollapsed) {
                    //show the second to last pagination iten
                    this.$penultimatePaginationItems.addClass('u-visible');
                }

            }
            else {
                //otherwise enable the next arrows
                this.$nextArrows.removeClass(this.$component+'__arrow--disabled')

                //if we are in the collased state
                if (this.isCollapsed) {
                    //hide the second to last pagination iten
                    this.$penultimatePaginationItems.removeClass('u-visible');
                }

            }
        }

    }

    //load page methhod, functionality usually specified on class that extends this
    loadPage() { }

    createPagination() {

        const { $paging, numberOfPages } = this;

        //generate a string of markup for the pagination items based on the number of pages
        const pagintationMarkup = new Array(this.numberOfPages).fill('').reduce((string, _, i) => string + this.createPaginationItem(i, i + 1), '');

        //insert the mark up in to each of the pagination elements
        $paging.each((i, e) => e.innerHTML = pagintationMarkup);

        //grab a collection of the pagination items
        this.$paginationItems = $('li[data-page-index]', $paging);

        const { $paginationItems } = this;

        //if the number of pages exceeds the maximum number of pagination items to show
        if (numberOfPages > this.maxPaginationItems) {
            //add the collapsed state
            this.addCollapsedState();
        }

        //add the events listener for when they're click - ie should go the appropriate page.
        $paginationItems.on('click', this.handlePaginationClick.bind(this));

        //filter the active pagination items from the collection and cache them
        this.$activePaginationitems = $paginationItems.filter(`.u-active`);

    }

    addCollapsedState() {
        // implement collapsed state on pagination
        this.$paging.addClass(this.$component+'__pagination-paging--collapsed');

        //cache the second to last pagination items, so we can update styling later
        this.$penultimatePaginationItems = this.$paginationItems.filter(`[data-page-index="${this.numberOfPages - 2}"]`);

        // store that it is collapsed on the class
        this.isCollapsed = true;
    }

    //create the mark up for the individual pagination item
    createPaginationItem(pageIndex, pageNumber) {
        return `<li ${pageIndex === this.currentPageIndex ? `class="u-active"` : ``} data-page-index="${pageIndex}"><button>${pageNumber}</button></li>`;
    }

    handlePaginationClick(e) {

        //get the 'li' pagination item element
        const $paginationItem = $(e.delegateTarget);

        //get the page index it pertains to
        const pageIndex = $paginationItem.data('page-index');

        //got to the appropriate page
        this.goToPage(pageIndex);

    }

}

export default Pagination;
