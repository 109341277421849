﻿import $ from 'jquery';
import Component from 'Component';
import App from 'App';
import querystring from 'query-string';
import VacanciesListingFilters from './Components/filters';

//polyfills
import 'es6-promise/auto'; // ES6 Promise Polyfill - needed by fetch
import 'fetch-polyfill';

class VacanciesSearch extends Component {

    viewportLarge = false;
    sidebar;
    BottomBar;
    
    constructor(selector) {
        super(selector);

        this.init(_ => {
         
            if(this.isExperienceEditor) return;

            this.query = querystring.parse(window.location.search);

            const { $element, query } = this;

            this.$filters = $('.o-vacancies-listing-filters');

            if(this.$filters.length)
                this.Filters = new VacanciesListingFilters(this.$filters.eq(0), this);

            this.setUpDeviceHandlers('setUpSmall setUpMediumSmall setUpMediumTouch setUpMediumDesktop', () => {
                App.setSidebarState('default');                              
                this.viewportLarge = false;

            })
            .setUpDeviceHandlers('setUpLargeTouch setUpLargeDesktop', () => {
                this.viewportLarge = true;
            })
            .bindDeviceHandler()
        })

    }
}

export default VacanciesSearch;