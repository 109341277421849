class SitePlanSVG {
    constructor(mapContainerSelector, zoomInSelector, zoomOutSelector) {
        this.mapImageContainer = document.querySelector(mapContainerSelector);
        this.zoomInButton = document.querySelector(zoomInSelector);
        this.zoomOutButton = document.querySelector(zoomOutSelector);
        this.scale = 1; 
        this.translation = { x: 0, y: 0 };

        if (this.mapImageContainer) {
            const mapImage = this.mapImageContainer.querySelector('img');
            this.imageZoom(mapImage);
            this.imageDrag(mapImage);
        }
    }

    imageZoom(mapImage) {
        const ZOOM_SPEED = 0.3;
        const MAX_SCALE = 4;
        const MIN_SCALE = 0.125;

        const zoomInButton = this.zoomInButton;
        const zoomOutButton = this.zoomOutButton;

        const applyTransform = (scale) => {
            const transitionDuration = '0.3s';
            mapImage.style.transition = `transform ${transitionDuration} ease`;
            mapImage.style.transform = `scale(${scale})`;
        };

        const zoomIn = () => {
            this.scale = Math.min(this.scale + ZOOM_SPEED, MAX_SCALE);
            applyTransform(this.scale);
        };

        const zoomOut = () => {
            this.scale = Math.max(this.scale - ZOOM_SPEED, MIN_SCALE);
            applyTransform(this.scale);
        };

        const handleZoomInteraction = (event, action) => {
            if (event.type === 'click' || event.key === 'Enter' || event.key === ' ') {
                event.preventDefault();
                action();
            }
        };

        zoomInButton.addEventListener('click', (event) => handleZoomInteraction(event, zoomIn));
        zoomOutButton.addEventListener('click', (event) => handleZoomInteraction(event, zoomOut));

        zoomInButton.addEventListener('keydown', (event) => handleZoomInteraction(event, zoomIn));
        zoomOutButton.addEventListener('keydown', (event) => handleZoomInteraction(event, zoomOut));
    }

    imageDrag(mapImage) {
        let start = { x: 0, y: 0 };
        let isDragging = false;

        const applyTransform = (scale, translation, transition = false) => {
            mapImage.style.transition = transition ? 'transform 0.3s ease' : 'none';
            mapImage.style.transform = `translate(${translation.x}px, ${translation.y}px) scale(${scale})`;
        };

        this.mapImageContainer.addEventListener('mousedown', (event) => {
            if (event.button === 0) {
                isDragging = true;
                start = {
                    x: event.clientX - this.translation.x,
                    y: event.clientY - this.translation.y
                };
                mapImage.style.cursor = 'grabbing';
                event.preventDefault(); // Prevent default drag behavior
            }
        });

        this.mapImageContainer.addEventListener('mousemove', (event) => {
            if (isDragging) {
                this.translation = {
                    x: event.clientX - start.x,
                    y: event.clientY - start.y
                };
                applyTransform(this.scale, this.translation);
            }
        });

        window.addEventListener('mouseup', (event) => {
            if (isDragging && event.button === 0) {
                isDragging = false;
                mapImage.style.cursor = 'grab';
            }
        });
    }
}

export default SitePlanSVG;