﻿import $ from 'jquery';
import Component from 'Component';
import utils from 'utilities';
import App from 'App';

class HomesFilters extends Component {

    DevelopmentHomes;//reference to the homes module we will be filtering

    // elements
    $radioButtons; // status radion buttons
    $selects; // selects for price range and bedroom range
    $homesFound; // span to dynamically update with total homes found
    $developmentName; //span containing name of development
    $closeButton; // button used to close lightbox on mobile
    $lightboxArea; // the lightbox on mobile
    $filterCTA; // cta in lightbox
    $viewSitePlan; // link to jump to site plan

    // params for filtering and defaults
    status = 'all';
    minPrice = 0;
    maxPrice = Infinity;
    minBedrooms = 0;
    maxBedrooms = Infinity;

    constructor(selector, DevelopmentHomes) {
        super(selector);

        this.init(() => {

            const { $element } = this;            

            //store reference to Homes moule on class
            this.DevelopmentHomes = DevelopmentHomes;
            
            //cache status radio buttons
            this.$radioButtons = $(`.o-development-homes-filters__radio-buttons input[type="radio"]`, $element);
            //cache range selects
            this.$selects = $(`.c-range select`, $element);
            
            //spans displaying the number of hoems found in fitler results
            this.$homesFound = $('.o-development-homes-filters_homes-found', $element);

            //cache the develppment name span
            this.$developmentName = $('.o-development-homes-filters_development-name', $element);

            //get values initially selected in form elements
            this.getInitialParams();          

            //set up status radio buttons change handler
            this.$radioButtons.on('change', this.handleRadioButtonChange.bind(this));

            //set up selects change handler
            this.$selects.on('change', this.handleSelectChange.bind(this));
            
            //select the area to act as lightbox on mobile
            this.$lightboxArea = $('.o-development-homes-filters__inner', $element);

            //the closebutton on the lightbox.
            this.$closeButton = $('.o-development-homes-filters__header-mobile__close', this.$lightboxArea);   
            
            this.$filterCTA = $('.c-button', this.$lightboxArea);

            this.$viewSitePlan = $('[href="#site-plan"]', $element);

            this.setUpDeviceHandlers('setUpSmall setUpMediumSmall setUpMediumTouch setUpMediumDesktop', () => {
                this.$closeButton.on('click', this.closeLightbox.bind(this));
                this.$filterCTA.on('click', this.closeLightbox.bind(this));
            })
            .setUpDeviceHandlers('setUpLargeTouch setUpLargeDesktop', () => {
                this.$viewSitePlan.on('click', this.handleViewSitePlanClick.bind(this));                               
            })
            .onBeforeSizeUpdate(() => {
                this.$closeButton.off();
                this.$filterCTA.off();
                this.$viewSitePlan.off();
            })
            .bindDeviceHandler();

        }) 

    }

    handleRadioButtonChange(e) {
        //get the value of the radio button and set it on the class
       this.status = e.delegateTarget.value;

       //update the homes visible based on the new filter settings
       this.updateDevelopmentHomes();

    }

    handleSelectChange(e) {
        let { 
            value,
            id
        } = e.delegateTarget
       
      // get the value of the select or set to undefined if it's ''
       value = value ? parseInt(value) : undefined;

       //update the appropriate value on the class based on the element which change, using id
       switch(id) {
           case 'minprice': this.minPrice = value; break;
           case 'maxprice': this.maxPrice = value; break;
           case 'minbedrooms': this.minBedrooms = value; break;
           case 'maxbedrooms': this.maxBedrooms = value; break;
           default: throw new Error(`${id} is an unrecognised filter id`);
       }

       //check the new values are valing
       this.verifyValues(id);

    }

    handleViewSitePlanClick(e) {
        
        const link = e.delegateTarget;
        const anchorTargetId = link.getAttribute('href');
        const anchorTarget = $(anchorTargetId);

        e.preventDefault()

        utils.scrollToPosition(anchorTarget, document.querySelector('.c-header'));
    }

    getInitialParams() {

        //loop though the selects
        this.$selects.each((e, select) => {

            const { id } = select;

            let value;
            
            //using the id, get the value of the selected option
            //and update the appropriate value on this class
            switch(id){
                case 'minprice':
                    value = select.querySelector('option[selected]').value;    

                    this.minPrice = value === '' ?  0 : parseInt(value);
                    
                    break;
                case 'maxprice':

                    value = select.querySelector('option[selected]').value;    

                    this.maxPrice = value === '' ?  Infinity : parseInt(value);

                    break;
                case 'minbedrooms':
                    
                    value = select.querySelector('option[selected]').value;    

                    this.minBedrooms = value === '' ?  0 : parseInt(value);

                    break;
                case 'maxbedrooms':

                    value = select.querySelector('option[selected]').value;    

                    this.maxBedrooms = value === '' ?  Infinity : parseInt(value);

                    break;
                default:
                    throw new Error(`${id} is an invalid select id`)
            }
        })
    }

    verifyValues(selectId) {

        let {
            minPrice,
            maxPrice,
            minBedrooms,
            maxBedrooms
        } = this;

        //if the min price is greater than the max price
        if(minPrice > maxPrice) {
            //if the item that changed was the min price select
            if(selectId === 'minprice') {
                //make the max price equal to the min price
                this.maxPrice = minPrice;
                // and make sure the select reflects this
                this.$selects.filter('#maxprice').get(0).value = minPrice;
            }
            else {
                //make min max price equal to the max price
                this.minPrice = maxPrice;
                // and make sure the select reflects this
                this.$selects.filter('#minprice').get(0).value = maxPrice;
            }
        }

        //if the min bedrooms is greater than the max bedrooms
        if(minBedrooms > maxBedrooms) {
            //if the item that changed was the min bedrooms selecte
            if(selectId === 'minbedrooms') {
                //make the max bedrooms equal to the min bedrooms
                this.maxBedrooms = minBedrooms;
                // and make sure the select reflects this
                this.$selects.filter('#maxbedrooms').get(0).value = minBedrooms;
            }
            else {
                //make the min bedrooms equal to the max bedrooms
                this.minBedrooms = maxBedrooms;
                // and make sure the select reflects this
                this.$selects.filter('#minbedrooms').get(0).value = maxBedrooms;
            }
        }

        //update the homes visible based on the new filter settings
        this.updateDevelopmentHomes();

    }

    //set the filter values to values provided from another component
    setValues(minPrice, maxPrice, minBedrooms, maxBedrooms) {
        const { $selects } = this;

        // update the properties on out class
        this.minPrice = parseInt(minPrice) || undefined;
        this.maxPrice = parseInt(maxPrice) || undefined;
        this.minBedrooms = parseInt(minBedrooms) || undefined;
        this.maxBedrooms = parseInt(maxBedrooms) || undefined;

        //update the select elements
        $selects.filter('#minprice').get(0).value = minPrice || '';
        $selects.filter('#maxprice').get(0).value = (!maxPrice || maxPrice === Infinity) ? '' : maxPrice;
        $selects.filter('#minbedrooms').get(0).value = minBedrooms || '';
        $selects.filter('#maxbedrooms').get(0).value = (!maxBedrooms || maxBedrooms === Infinity) ? '' : maxBedrooms;

        //ensure the homes display match the new selections
        this.updateDevelopmentHomes();
        
    }

    updateDevelopmentHomes() {
        const { DevelopmentHomes } = this;
        
        //filter the development homes based on the params
        DevelopmentHomes
            .filterPlots(this.status, this.minPrice, this.maxPrice, this.minBedrooms, this.maxBedrooms)
            .then(plots => {
                if(plots.length > 0) {
                    DevelopmentHomes.hideNoResults();
                }
                else {
                    DevelopmentHomes.showNoResults();
                }

                DevelopmentHomes.updatePagination();

            });
    }

    updateHomesFound(numberFound) {
        //update the text with the humner of results found
        this.$homesFound.text(numberFound);
    }

    updateDevelopmentName(developmentName){
        this.$developmentName.text(developmentName);
    }

    closeLightbox() {
        this.$lightboxArea.removeClass('o-development-homes-filters__inner--visible');
        utils.showBodyOverflow(true, true);
    }
}

export default HomesFilters;