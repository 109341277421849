﻿import $ from 'jquery';
import YouTubeVideo from 'YouTubeVideo';
import VimeoVideo from 'VimeoVideo';

const externalVideoFactory = (element, playbutton = 'overlay', video = 'video', thumbnail = 'thumbnail') => {

    const $video = typeof (video === 'string' && !video.jquery) ? $(video, element) : video;
    const url = $video.data('url');

    if (url == undefined) {
        return;
    }//added

    else if (/youtube|youtu\.be/.test(url)) {
        return new YouTubeVideo(element, playbutton, $video, thumbnail, url);
    }

    else if (/vimeo/.test(url)) {
        return new VimeoVideo(element, playbutton, $video, thumbnail, url);
    }

    throw new Error(`'${url}' is neither a youtube or vimeo video`);

}

export default externalVideoFactory;