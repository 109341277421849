﻿import $ from 'jquery';
import slick from 'slick-carousel';
import Component from 'Component';
import utils from 'utilities';
import CarouselComponent from 'Carousel';
import externalVideoFactory from 'externalVideoFactory';

class TestimonialCarousel extends Component {

    $container;
    $slideMedia;
    $slideContents;
    Carousel;
    Videos;

    constructor(selector) {
        super(selector);

        this.init(() => {
            const { $element } = this;
            
            if(this.isExperienceEditor) return;

            this.Carousel = new CarouselComponent($element);

            this.$container = $('.c-testimonial-block', $element);
            this.$slideMedia = $('.c-carousel-slides__content--media', $element);
            this.$slideContents = $('.c-carousel-slides__content--copy', $element);

            this.mapImagestoBackgrounds();

            this.Videos = this.$slideMedia.map((_, el) => externalVideoFactory(el, ".c-carousel-slides__iframe-button", '.c-carousel-slides__iframe-content', undefined));

            for (let i = 0; i < this.Videos.length; i++) {
                let video = this.Videos[i];
                video.load();
            }
                      
            this.setUpDeviceHandlers('setUpSmall setUpMediumSmall setUpMediumTouch setUpMediumDesktop setUpLargeTouch setUpLargeDesktop', () => {
                this.unifyContentHeights();
                this.Carousel.beforeSlideChange((e, slick, current) => {
                    this.Videos[current].stop();
                    this.Videos[current].showOverlayElements();
                });
                this.Carousel.afterSlideChange((e, slick, current) => {
                    this.Videos[current].load();
                });
             }).bindDeviceHandler();
        });
    }

    mapImagestoBackgrounds() {

        this.$slideMedia.each(function () {
            const $container = $(this);
            utils.mapImgToBackground($container.find('img'), $container)
        });

        return this;
    }

    unifyContentHeights() {

        const { $slideContents } = this;

        $slideContents.each((_, el) => {
            el.style.height = "auto";
        });
        
        const maxHeight = Array.prototype.reduce.call($slideContents, (max, current) => {
            const currentHeight = parseFloat(current.offsetHeight);
            return currentHeight > max ? currentHeight : max;
        }, 0);
        
        $slideContents.each((_, el) => {
            el.style.height = maxHeight + "px";
        });

        return this;

    }

}

export default TestimonialCarousel;

