﻿import $ from 'jquery';
import Component from 'Component';
import utils from 'utilities';
import BottomBar from './Components/bottom-bar';

class Sidebar extends Component {

    $contentContainer;
    height = 0;
    wasFullscreen = false;
    BottomBar;
    currentState = 'default';
    viewportLarge = false;
    $developmentDetails;
    $homesFilters;

    constructor(selector, collapsable = true) {
        super(selector);

        this.collapsable = collapsable;

        this.init(() => {

            const { $element } = this;

            this.$contentContainer = $('.c-sidebar__content', $element);
            this.$developmentDetails = $('.c-development-details', $element);
            this.$homesFilters = $('.o-development-homes-filters', $element);
            this.$homesFiltersLightBox = $('.o-development-homes-filters__inner', this.$homesFilters);
            this.$developmentListingsFilters = $('.o-development-listings-filters', $element);
            this.detailsCtas = document.querySelectorAll('.details-cta');

            let div = document.createElement("div");

            div.innerHTML = document.getElementById('developmentInfo')?.innerText || '{}';
            this.gtmData = JSON.parse(div.textContent || div.innerText) || {};

            this.detailsCtas.forEach((cta) => {
                cta.addEventListener('click', (event) => {
                    this.createGTMEvent(event);
                });
            });

            this.setUpDeviceHandlers('setUpSmall setUpMediumSmall setUpMediumTouch setUpMediumDesktop', () => {

                this.removeHeight();

                if (!this.BottomBar) this.BottomBar = new BottomBar($element, $element.parent(), this);
                else this.BottomBar.reinit();

            })
                .setUpDeviceHandlers('setUpLargeTouch setUpLargeDesktop', () => {

                    this.viewportLarge = true;

                    if (this.checkContentOverflown()) {
                        this.alignContentTop();
                    }

                    if (this.BottomBar) this.BottomBar.destroy();

                    utils.showBodyOverflow(true, true);

                    this.$document.on('fullscreenchange webkitfullscreenchange mozfullscreenchange MSFullscreenChange', () => {
                        this.wasFullscreen = true;
                    })
                })
                .setUpDeviceHandlers('setUpSmall setUpMediumSmall setUpMediumDesktop setUpMediumTouch', () => {
                    this.viewportLarge = false;
                })
                .bindDeviceHandler()
                .onBeforeSizeUpdate(() => {
                    if (!this.wasFullscreen) {
                        //  this.removeHeight();
                        this.resetContentAlignment();
                    }
                    this.wasFullscreen = false;
                });
        });
    }

    setHeight(value = this.height) {

        const fadeIn = !this.height;

        this.height = value;

        this.$contentContainer.css('height', `${value}px`);

        fadeIn && this.fadeIn();
    }

    fadeIn() {
        this.$element.addClass("c-sidebar--visible");
    }

    removeHeight() {
        this.$contentContainer.css('height', '');
    }

    checkContentOverflown() {
        return utils.isElementOverflown(this.$contentContainer);
    }

    alignContentTop() {
        this.$contentContainer.css('justify-content', 'start');
    }

    resetContentAlignment() {
        this.$contentContainer.css('justify-content', '');
    }

    setState(state) {
        //if the state of the sidebar is already the requested state... BAIL!
        if (state === this.currentState) return;

        const { viewportLarge } = this;

        switch (state) {
            case 'default':
                this.hideSticky();
                //if the device is large, the default state should be expanded and show the development details
                if (viewportLarge) this.showDevelopmentDetails().expand();
                break;
            case 'collapsed':
                this.showSticky();
                //if the device is large, the collapsed state should be collapsed and show the development details
                if (viewportLarge && this.collapsable) this.showDevelopmentDetails().collapse();
                break;
            case 'primary':
                // the bottom bar only exists if the viewport is less than large breakpoint
                // its primary state is hidden
                this.BottomBar && this.BottomBar.setState('hidden');
                break;
            case 'visible':
                // the bottom bar only exists if the viewport is less than large breakpoint
                // its primary state is hidden
                this.BottomBar && this.BottomBar.setState('visible');
                break;
            case 'secondary':
                // the bottom bar only exists if the viewport is less than large breakpoint
                // if it exists show it
                this.BottomBar && this.BottomBar.setState('primary');
                break;
            case 'homes-filters':
                //if it is a large device, show the homes filters and ensure the sidebar is expanded
                if (viewportLarge) {
                    this.showHomesFilters().expand();
                }
                else {
                    this.BottomBar && this.BottomBar.setState('homes-filters');
                }
                break;
            case 'development-filters':
                //if it is a large device, show the homes filters and ensure the sidebar is expanded
                if (viewportLarge) {
                    this.showHomesFilters().expand();
                }
                else {
                    this.BottomBar && this.BottomBar.setState('homes-filters');
                }
                break;
            default:
                throw new Error(`'${state}' is an invalid state for the sidebar component`)
        }

        //update current state
        this.currentState = state;

    }


    collapse() {

        const { $element } = this;

        $element.addClass('c-sidebar--collapsed');

        return this;

    }

    expand() {

        const { $element } = this;

        $element.removeClass('c-sidebar--collapsed');

        return this;

    }

    show() {
        document.querySelector('.c-sidebar').classList.add('c-sidebar--visible');
    }

    hide() {
        document.querySelector('.c-sidebar').classList.remove('c-sidebar--visible');
    }

    showDevelopmentDetails() {
        // reorder the sidebar items to ensure development details are shown
        this.$developmentDetails.css('order', '0');
        this.$homesFilters.css('order', '1');

        return this;
    }

    showHomesFilters() {
        this.showSticky();

        // reorder the sidebar items to ensure homes filters are shown
        this.$homesFilters.css('order', '0');
        this.$developmentDetails.css('order', '1');

        return this;
    }

    showSticky() {
        const development = document.querySelector('.c-development-sticky-cta');
        const plot = document.querySelector('.c-plot-sticky-cta');

        if (development) {
            development.classList.remove('c-development-sticky-cta__hidden');
        }

        if (plot) {
            plot.classList.remove('c-plot-sticky-cta__hidden');
        }
    }

    hideSticky() {
        const development = document.querySelector('.c-development-sticky-cta');
        const plot = document.querySelector('.c-plot-sticky-cta');

        if (development) {
            development.classList.add('c-development-sticky-cta__hidden');
        }

        if (plot) {
            plot.classList.add('c-plot-sticky-cta__hidden');
        }
    }

    showHomesFiltersLightBox() {
        this.$homesFiltersLightBox.addClass('o-development-homes-filters__inner--visible');
        utils.hideBodyOverflow(true, true);
    }

    showListingsFiltersLightBox() {
        this.$developmentListingsFilters.addClass('o-development-listings-filters--visible');
        utils.hideBodyOverflow(true, true);
    }

    createGTMEvent(e) {
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'detailsCTA',
                developmentName: this.gtmData.name,
                developmentStatus: this.gtmData.status,
                region: this.gtmData.region,
                geoLocation: this.gtmData.county,
                location: this.gtmData.town,
            });
        }
    }

}

export default Sidebar;