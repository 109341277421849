﻿import App from 'App';
import ExternalVideo from 'ExternalVideo';

class VimeoVideo extends ExternalVideo {
    Player;

    constructor(...args) {
        super(...args);

        this.init(() => {
            if (this.isExperienceEditor) return; //if we are in the experience editor we want the carousel to be static;

            App.setUpVimeoAPI();
        })
    }

    load() {
        if (this.isLoaded) return;

        if (!this.Player) {
            this.Player = new Vimeo.Player(this.$video.get(0), {
                id: this.videoId
            })

            this.Player.on('loaded', () => {
                const { $video: $videoPlaceHolder } = this;

                this.$video = $videoPlaceHolder.find('iframe');

                this.$playbutton.on('click', (e) => {
                    this.hideOverlayElements();
                    if(this.Player)
                        this.Player.play().catch((e) => console.log(e));
                });
            })
        }

        this.isLoaded = true;
    }

    stop() {
        if(!this.Player)
            return;
        this.Player.setCurrentTime(0);
        this.Player.pause();
    }
}


export default VimeoVideo;