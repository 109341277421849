class LocationLink {

  initialiseGtmListener() {
    window.addEventListener('load', () => {
      document.querySelectorAll('.location-list__location, a.location-list__title').forEach((link) => {
        link.addEventListener('click', event => {
          window.dataLayer.push({
            event: 'LocationClick',
            clickText: event.target.innerText,
          });
        });
      });
    });
  }

}

export default LocationLink;