class ReadMoreComponent {
    constructor(element) {
        this.element = element;
        if (!this.element) return;

        this.readMoreButton = this.element.querySelector('.c-local-area-read-more--button');
        this.readMoreTextSpan = this.readMoreButton.querySelector('.c-local-area-read-more--button-text');
        if (!this.readMoreButton || !this.readMoreTextSpan) return;

        this.textContentElement = this.element.querySelector('.c-local-area-copy__read-more--wrap');
        this.originalText = this.textContentElement.textContent;
        this.charLimit = parseInt(this.element.getAttribute('data-char-length')) || 400;
        this.readLessText = this.readMoreButton.getAttribute('data-read-less-text') || 'Read Less';

        this.isExpanded = false;
        this.toggleButtonText();
        this.initializeState();

        this.readMoreButton.addEventListener('click', () => this.toggleText());
        window.addEventListener('resize', () => this.initializeState());
    }

    initializeState() {
        const visibleText = this.textContentElement.textContent || this.textContentElement.innerText;
        if (window.innerWidth <= 1024 && visibleText.length > this.charLimit) {
            this.readMoreButton.style.display = 'block';
            this.textContentElement.classList.add('collapsed'); 
        } else {
            this.readMoreButton.style.display = 'none';
            this.textContentElement.classList.remove('collapsed');
        }
    }

    toggleText() {
        this.isExpanded = !this.isExpanded;
        this.textContentElement.classList.toggle('collapsed', !this.isExpanded);
        this.toggleButtonText();
        this.element.classList.toggle('expanded', this.isExpanded);
    }

    toggleButtonText() {
        this.readMoreTextSpan.textContent = this.isExpanded ? this.readLessText : 'Read More';
    }
}

export default ReadMoreComponent;
