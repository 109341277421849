﻿import $ from 'jquery';
import externalVideoFactory from 'externalVideoFactory';
import App from 'App';
import utils from 'utilities';
import Component from 'Component';

class FullWidthMediaCopyBlock extends Component {

    Video;
    $image;

    constructor(selector) {
        const $video = $(".c-video__iframe-container", $(selector));    
        super(selector, true);

        this.init(() => {
            const { $element } = this;

            if ($video.length > 0) {
                this.$image = $('.c-media-block img', $element);
                this.$ratio = (this.$image.get(0).naturalHeight / this.$image.get(0).naturalWidth) * 100;
                $video.css('padding-bottom', this.$ratio + '%');
            }

            if (this.isExperienceEditor) return;

            if ($video.length > 0) {
                this.Video = externalVideoFactory($video, ".c-video__iframe-button", '.c-video__iframe-content', undefined);
                this.Video.load();
            }

            this.addEventHandler('scrolledIntoView', () => {
                if (utils.device.isLarge())
                    App.setSidebarState('collapsed');
            });
        });
    }
}

export default FullWidthMediaCopyBlock;