﻿import $ from 'jquery';
import Component from 'Component';
import utils from 'utilities';

class PromoItem extends Component {

    $image;      

    constructor(selector) {            
        super(selector);

        this.init(() => {
            const { $element } = this;
            
            this.$image = $('.c-promotion-block__content--image', $element);
    
            if (this.$image && this.$image.length) {
                $(() => {  //wait until doc is ready to ensure picturefill has executed
                    utils.mapImgToBackground(this.$image.find('img'), this.$image)
                });
            }
        });
    }

}

export default PromoItem;
