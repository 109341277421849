﻿import $ from 'jquery';
import Component from 'Component';
import Accordions from 'Accordions';
import App from 'App';
import utils from 'utilities';

class Specification extends Component {

    Accordions;
    anchorId

    constructor(element) {
        super(element, true);

        const { $element } = this;

        this.anchorId = $element.attr('id');

        this.Accordions = $('.c-accordions', this.$element).map((_, el) => new Accordions(el, this));

        this.init(() => {
            this.addEventHandler('scrolledIntoView', () => {
                if(utils.device.isLarge())
                    App.setSidebarState('collapsed');
                else
                    App.setSidebarState('secondary');

                App.setSecondaryNavState(this.anchorId);
            })
        })

    }

}

export default Specification;
