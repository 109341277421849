﻿import $ from 'jquery';
import Component from 'Component';
import utils from 'utilities';

const { device } = utils;

class NavItem extends Component {

    Header;
    $container;
    $subNavContainer;
    $backButton;
    $link;
    slideDuration = 500;
    isOpen = false;
    hasSubnav = true;
    eventTriggered = false;
    eventTriggeredTimeout;

    constructor(element, Header) {
        super(element);
        this.Header = Header;
        this.$container = Header.$navContainer;
        
        this.init(() => {

            const { $element } = this;
            
            this.$subNavContainer = $(".o-nav__subnav-container", $element);
            this.$backButton = $(".c-nav-subnav-back-btn", $element);
    
            this.$link = $element.children('a');
    
            this.hasSubnav = !$element.hasClass('u-no-subnav');
    
            this.setUpDeviceHandlers('setUpSmall', () => {
                    const { $element } = this;
    
                    $element.on('click', this.slideOpenNav.bind(this));
                    this.$backButton.on('click', this.slideCloseNav.bind(this));
                })
                .setUpDeviceHandlers('setUpMediumSmall', () => {
                    const { $element } = this;
    
                    $element.on('click', this.slideOpenSubNav.bind(this));
                    this.$backButton.on('click', this.slideCloseSubNav.bind(this));
                })
                .setUpDeviceHandlers('setUpMediumTouch setUpLargeTouch', () => {
    
                    const { $element } = this;
    
                    this.$link.on('click', this.toggleOpen.bind(this));
                    this.$element.on('focus', this.openSubNav.bind(this)); // Add focus event listener
                    this.$element.on('blur', this.closeSubNavOnBlur.bind(this)); // Add blur event listener
                    this.$subNavContainer.on('focusin', this.cancelClose.bind(this)); // Prevent closing when focusing into subnav
                    this.$subNavContainer.on('focusout', this.closeSubNavOnBlur.bind(this)); // Close when focusing out of subnav
    
                    //added for touchscreen desktop devices
                    $element.on('mouseover', this.openSubNav.bind(this));
                })
                .setUpDeviceHandlers('setUpMediumDesktop setUpLargeDesktop', () => {
    
                    const { $element } = this;
    
                    $element.on('mouseover', this.openSubNav.bind(this));
                    this.$element.on('focus', this.openSubNav.bind(this)); // Add focus event listener
                    this.$element.on('blur', this.closeSubNavOnBlur.bind(this)); // Add blur event listener
                    this.$subNavContainer.on('focusin', this.cancelClose.bind(this)); // Prevent closing when focusing into subnav
                    this.$subNavContainer.on('focusout', this.closeSubNavOnBlur.bind(this)); // Close when focusing out of subnav
                })
                .bindDeviceHandler()
                .onBeforeSizeUpdate(() => {
                    this.$element.off();
                    this.$backButton.off();
                    this.$link.off();
                    this.isOpen = false;
                });
        });
    }

    openSubNav(event) {
        
        if (this.checkEventTriggered()) return;

        this.eventTriggered = true;    

        const { Header } = this;

        Header.closeSearch()
                .closeActiveNavItem()
                .activeNavItem = this;

        this.$element.addClass("c-nav__nav-item--active");
        this.activate()
            .isOpen = true;  

        if (device.isMedium() && device.isTouch()) {
            utils.onClickOutside(this.Header.$element, this.closeSubNav.bind(this));
        }

        this.eventTriggeredTimeout = setTimeout(() => {      
            this.eventTriggered = false;
        }, 1);

        return this;

    }

    closeSubNav(event) {            

        if (this.checkEventTriggered()) return;

        this.eventTriggered = true;     

        if (this.isOpen) {
            this.$element.removeClass("c-nav__nav-item--active");
            this.deactivate();
            this.isOpen = false;
            this.Header.activeNavItem = null;
        }     
        
        this.eventTriggeredTimeout = setTimeout(() => {
            this.eventTriggered = false;
        }, 1);

        return this;
    }

    closeSubNavOnBlur(event) {
        setTimeout(() => {
            if (!this.$element.has(document.activeElement).length && !this.$subNavContainer.has(document.activeElement).length) {
                this.closeSubNav();
            }
        }, 1);
    }

    cancelClose() {
        clearTimeout(this.eventTriggeredTimeout);
        this.eventTriggered = false;
    }

    slideOpenNav() {

        const { Header } = this;

        if (!this.hasSubnav) return;

        Header.deactivateActiveNavItem()
                .activeNavItem = this;

        Header.slideOpenNav();

        return this;
    }

    slideCloseNav(event) {

        event && event.stopPropagation();

        const { Header } = this;         
        
        Header.slideCloseNav();

        return this;

    }

    toggleOpen(event) {  

        if (this.checkEventTriggered()) return;

        const { Header } = this;      

        if (this.isOpen) {
            this.closeSubNav();    
            this.Header.activeNavItem = null;
        }
        else {              
            Header.closeActiveNavItem()
                    .activeNavItem = this;
            this.openSubNav();
            Header.closeSearch();
        }

        this.eventTriggeredTimeout = setTimeout(() => {
            this.eventTriggered = false;
        }, 1);

        return this;

    }

    slideOpenSubNav() { 
        const { Header } = this;

        if (Header.navAnimating || !this.hasSubnav) return;

        Header.navAnimating = true;

        this.removeContainerScrollable();

        Header.deactivateActiveNavItem()
                .activeNavItem = this;

        this.activate()
            .$container.addClass("c-nav__nav-items-container--offleft");
        this.isOpen = true;

        setTimeout(() => {
            this.resetContainerScrollPosition();
            Header.navAnimating = false;

        }, this.slideDuration);

        return this;

    }

    slideCloseSubNav(event) {
        const { Header } = this;

        if (Header.navAnimating) return;

        event.stopPropagation();               
        
        Header.navAnimating = true;

        this.$container.removeClass("c-nav__nav-items-container--offleft");
        this.isOpen = false;            

        setTimeout(() => {
            this.makeContainerScrollable()
                .resetSubNavContainerScrollPosition();
            Header.navAnimating = false;

        }, this.slideDuration);

        return this;
    }

    activate() {
        this.$subNavContainer.addClass("o-nav__subnav-container--active");
        this.$subNavContainer.attr('aria-hidden', false);
        
        return this;
    }
    
    deactivate() {
        this.$subNavContainer.removeClass("o-nav__subnav-container--active");
        this.$subNavContainer.attr('aria-hidden', true);

        return this;
    }

    makeContainerScrollable() {
        this.$container.css({ 'overflow-y': 'auto', 'overflow-x': 'hidden' });
        this.resetContainerScrollPosition();

        return this;
    }

    removeContainerScrollable() {
        this.$container.css('overflow', 'visible');

        return this;
    }

    resetContainerScrollPosition() {
        this.$container.get(0).scrollTop = 0;

        return this;
    }

    resetSubNavContainerScrollPosition() {
        this.$subNavContainer.get(0).scrollTop = 0;

        return this;
    }

    checkEventTriggered() {
        if (this.eventTriggered) {
            this.eventTriggered = false;
            clearTimeout(this.eventTriggeredTimeout);
            return true;
        }
        else return false;
    }

}

export default NavItem;
