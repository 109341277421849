﻿import $ from 'jquery';
import Component from 'Component';
import App from 'App';
import utils from 'utilities';

const { device } = utils;

class SubNav extends Component {  

    $menuBtn;
    menuOpen = false;
    initalOffset;
    initalState = true;
    scrollDirection;
    prevScrollPosition = 0;
    distanceScrolled = 0;
    $links;
    state;
    $activeLink;

    constructor(element) {
        super(element);
        this.init(() => {
            const { $element } = this;        
            
            this.$menuBtn = $(".c-sub-nav__menu-button", $element);
            this.$links = $('.c-sub-nav__links-container .c-sub-nav__sub-link', $element);
            
            const { $menuBtn } = this;
    
            this.setUpDeviceHandlers('setUpSmall setUpMediumSmall setUpMediumDesktop setUpMediumTouch', () => {
                    $menuBtn.on('click', this.toggleMenu.bind(this));
    
                    this.$window.on('scroll', this.handleScroll.bind(this));
    
                    $(() => this.calculateInitialOffset());
            
                })
                .setUpDeviceHandlers('setUpLargeTouch setUpLargeDesktop', () => {
                    this.$window.on('scroll', this.handleScroll.bind(this));
                    
                    $(() => this.calculateInitialOffset());
                    
                })
                .bindDeviceHandler()
                .onBeforeSizeUpdate(() => {
                    $menuBtn.off();
                });
        });

    }

    toggleMenu() {     

        this.menuOpen ? this.closeMenu() : this.openMenu();

        return this;
    }

    openMenu() {
        this.$element.addClass("c-sub-nav--open");

        this.menuOpen = true;

        return this;
    }

    closeMenu() {        
        this.$element.removeClass("c-sub-nav--open");

        this.menuOpen = false;

        return this;
    }

    handleScroll(e) {

        const scrollPosition = this.$window.scrollTop(),
              { prevScrollPosition, scrollDirection } = this;    

        // if the scroll position is greater than previous scroll position, we are scrolling down
        if(scrollPosition > prevScrollPosition){
            //if we were already scrolling down, update the distance scrolled
            if(scrollDirection === 'down') {
                this.distanceScrolled += scrollPosition - prevScrollPosition;
            }
            //otherwise, update the direction and reset the distance
            else {
                this.scrollDirection = 'down';
                this.distanceScrolled = 0;
            }    
        }
        // otherwise, we are scrolling up
        else if(scrollPosition < prevScrollPosition) {
           //if we were already scrolling up, update the distance scrolled
            if(scrollDirection === 'up') {
                this.distanceScrolled += prevScrollPosition - scrollPosition;
            }
            //otherwise, update the direction and reset the distance
            else {
                this.scrollDirection = 'up';
                this.distanceScrolled = 0;
            } 

        }

        this.prevScrollPosition = scrollPosition;

        if(this.withinHeaderArea() && this.scrollDirection === 'down') {
            this.applyScrollingState();
        } 
        else if (!this.withinHeaderArea() || (this.scrollDirection === 'up' && this.distanceScrolled >= 1000) && !this.menuOpen) {
            this.applyInitialState();
            
            !this.withinHeaderArea() && App.setSidebarState('primary');
        }

    }

    withinHeaderArea(){        
        return this.$window.scrollTop() >= this.initalOffset - App.getHeaderHeight()
    }

    applyScrollingState(){
        if(this.initalState) {
            this.setTop(App.getHeaderHeight());
            App.hideHeader()
            this.setFixed()
                .setTop(0)
                .initalState = false;     
        }

        return this;
    }

    applyInitialState() {
        if(!this.initalState) {
            App.showHeader()
            this.removeFixed()
                .setTop()
                .initalState = true;
        }

        return this;
    }
    
    setFixed() {
        this.$element.addClass("c-sub-nav--fixed");

        return this;
    }

    removeFixed() {
        this.$element.removeClass("c-sub-nav--fixed");
        
        return this;
    }

    setTop(top = '') {
        this.$element.css('top', top);
        
        return this;
    }

    removeTransition(){
        this.$element.css('transition', 'none');
        return this;
    }

    resetTransition(){
        this.$element.css('transition', '');

        return this;
    }

    calculateInitialOffset() {

        if(this.initalState) {
            this.initalOffset = this.$element.offset().top;
        }
        else {
            this.removeTransition()
                .applyInitialState()
            this.initalOffset = this.$element.offset().top;
            this.applyScrollingState();
                
            setTimeout(() => {
                this.resetTransition();
            })
        }

        return this;
    }

    setState(state) {
        if(this.state === state) return;

        this.updateActiveLink(state);

        this.state = state;

    }
}

export default SubNav;
