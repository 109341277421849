﻿import $ from "jquery";
import App from "App";
import ExternalVideo from "ExternalVideo";

class YouTubeVideo extends ExternalVideo {
    Player;
    isLoaded = false;
    isReady = false;

    constructor(...args) {
        super(...args);

        this.init(() => {
            if (this.isExperienceEditor) return; //if we are in the experience editor we want the carousel to be static;

            App.setupYouTubeAPI();

            // Handling an exceptional scenario for Youtube links
            // This will match the URL you get if you just copy and paste
            // directly from the browser's search bar
            let queryParamMatch = this.url.match(/watch\?v=(.+)/);

            if (queryParamMatch !== null) this.videoId = queryParamMatch[1];
        });
    }

    load() {
        if (this.isLoaded) return;

        if (!this.Player) {
            if (window.YT && window.YT.Player) {
                this.initPlayer();
            } else {
                App.addToYouTubeAPIReadyQueue(this.initPlayer.bind(this));
            }
        }

        this.isLoaded = true;
    }

    initPlayer() {
        this.Player = new YT.Player(this.$video.get(0), {
            videoId: this.videoId,
            events: {
                onReady: this.onYtPlayerReady.bind(this),
            },
        });
    }

    onYtPlayerReady() {
        if (this.isReady) return;

        this.isReady = true;
        this.$video = $(this.Player.getIframe());

        this.$playbutton.on("click", () => {
            this.hideOverlayElements();
            this.Player.playVideo();
        });
    }

    stop() {
        if(this.Player && this.Player.stopVideo)
            this.Player.stopVideo();
    }
}

export default YouTubeVideo;
