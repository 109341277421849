class GoalFiring {

  initialiseGoalFiringListener() {
    window.addEventListener('load', () => {
      document
        .querySelectorAll('[data-goal-endpoint]:not([data-goal-endpoint-custom-trigger])')
        .forEach(element => {
          if (element.hasAttribute('data-goal-endpoint-redirect') && element.hasAttribute('href')) {
            element.setAttribute('data-goal-endpoint-redirect', element.getAttribute('href'));
          }

          element.addEventListener('click', (event) => {
            if (element.getAttribute('data-goal-endpoint-redirect')) {
              event.stopPropagation();
              event.preventDefault();

              this.fireGoal(
                element.getAttribute('data-goal-endpoint'),
                element.getAttribute('data-goal-endpoint-redirect')
              );
            } else {
              this.fireGoal(element.getAttribute('data-goal-endpoint'));
            }
          });
      });
    });
  }

  fireGoal(goalEndpoint, redirectUrl) {
    var xhr = new XMLHttpRequest();
    xhr.open('POST', goalEndpoint, true);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send();
    xhr.addEventListener('loadend', () => {
      if (redirectUrl) {
        window.location.href = redirectUrl;
      }
    });
  }

}

export default GoalFiring;