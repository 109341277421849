import Form from './form';
import $ from 'jquery';

class DownloadBrochure extends Form {
    $development;

    constructor(element){
        super(element);

        this.init(() => {
            this.$development = $('#Development', this.$form);
            
            if(this.$development && this.$development.length > 0) {
                this.$development.rules('add', {
                    required: true,
                    messages: {
                        required: 'Please select a development',
                    }        
                });
            }
        });
    }
}

export default DownloadBrochure;