﻿import $ from 'jquery'
import Component from 'Component';
import App from 'App';
import Pagination from 'Pagination';
import utils from 'utilities';
import VacanciesListingFilters from '../VacanciesSearch/Components/filters';

//polyfills
import 'es6-promise/auto'; // ES6 Promise Polyfill - needed by fetch
import 'fetch-polyfill';

import 'intl'; // ES6 intl Polyfill
import 'intl/locale-data/jsonp/en.js';

//method for inserting and sorting
import 'array.prototype.insert';
import 'array.prototype.fill';

class VacanciesListing extends Pagination {

    url; //url for the api
    linkUrl;
    numberOfJobs; // total number of jobs available under the current filter
    itemsPerPage = 9;
    noResults = false; // indicates if no results found
    startParams;

    //organisation of jobs

    filteredJobs = []; //list of jobs to displayed. A combo of both arrays above with items filters out based on current filters
    actualJobs = [];

    //elements
    $cardsContainer; // element which containers all cards
    $jobsShown // span to dynamically update with range of jobs shown e.g "1-9"
    $totaljobs // span to dynamically update with total jobs found
    $subHeader;  // sub header element
    $noResults; // no results found text
    $pagination;

    anchorId;

    noJobs = false; //indicates whether no jobs are found

    $loader;

    //filters
    location;
    department;
    keyword;

    constructor(selector) {

        super(selector, 'o-vacancies-listing', true, '.o-vacancies-listing__arrow--next', '.o-vacancies-listing__arrow--prev', '.o-vacancies-listing__pagination-paging');

        this.init(() => {

            const {
                $element
            } = this;

            this.anchorId = $element.attr('id');
            this.linkUrl = $element.data('href');
            this.url = $element.data('api');

            this.startParams = this.checkStartParams(window.location.href);

            //cache the container element for the cards
            this.$cardsContainer = $('.o-vacancies-listing__cards', $element);

            this.$loader = $('.o-vacancies-listing__loader', $element)
            this.$loaderText = $('.o-vacancies-listing__loader-text', this.$loader);
            //cache subheader element
            this.$subHeader = $('.o-vacancies-listing__sub-header', $element);

            //cache no results fdound element
            this.$noResults = $('.o-vacancies-listing__none-found', $element);

            //cache the jobs shown span
            this.$jobsShown = $('.o-vacancies-listing__jobs-shown', $element);

            //cache the total jobs found spans
            this.$totalJobs = $('.o-vacancies-listing__total-jobs', $element);

            this.$pagination = $('.o-vacancies-listing__pagination', $element);

            //when this is scrolled into view tell the sidebar to show the filters
            this.addEventHandler('scrolledIntoView', () => {
                App.setSidebarState('default');
                App.setSecondaryNavState(this.anchorId);
            })
            this.showLoader();

            this.fetchJSON(this.startParams);
        });

    }

    fetchJSON(params = '') {
        const apiAddress = `${this.url + params}`
        fetch(apiAddress)
            .then((response) => {
                if (!response.ok) {
                    this.handleApiError();
                    throw Error(response.statusText);
                }

                return response.json();
            })
            .then((json) => {
                this.hideLoader();

                history.pushState({}, null, this.updateUrl(window.location.href, params));

                if (params != undefined && params != '') {
                    this.scrollToAnchor('vacancies-anchor');
                }

                return this.processData(json);
            })

    }

    processData(json) {
        const jobs = json;
        this.actualJobs = json;

        if (!jobs || !jobs.length) {
            this.noJobs = true;
            return this.$element.addClass('o-vacancies-listing--hidden');
        }

        this.numberOfJobs = jobs.length;

        this.$totalJobs.text(this.numberOfJobs);

        this.numberOfPages = Math.ceil(this.numberOfJobs / this.itemsPerPage);
        this.createPagination();  
        this.loadPage();
        this.goToPage(0, true);

    }

    hidePreviousResults() {
        if (this.$pagination.length) {
            this.$pagination.hide();
        }
        this.$subHeader.hide();

    }
    showPreviousResults() {
        this.$pagination.fadeIn();
        this.$subHeader.fadeIn();
    }

    handleApiError() {
        this.hideLoader();
        return this.$element.addClass('o-vacancies-listing--hidden');
    }

    loadPage() {

        const { itemsPerPage, actualJobs } = this;
        const fromIndex = this.currentPageIndex * itemsPerPage;
        let toIndex = fromIndex + itemsPerPage;

        if (!actualJobs[toIndex]) {
            toIndex = actualJobs.length;
        }
        const jobsToShow = actualJobs.slice(fromIndex, toIndex);

        const cardsMarkup = this.createCards(jobsToShow);

        const prevScrollTop = this.$window.scrollTop();
        const win = this.$window.get(0);

        const oldHeight = Math.max(document.documentElement.scrollHeight, document.documentElement.offsetHeight);

        this.$cardsContainer.html(cardsMarkup);

        const newHeight = Math.max(document.documentElement.scrollHeight, document.documentElement.offsetHeight);

        const elementPosition = this.$element.offset().top;

        if (utils.device.isLarge()) {

            //const reattach = this.detachWindowEvents();

            if (prevScrollTop > elementPosition) {
                if (oldHeight > newHeight) {
                    const newScrollPosition = prevScrollTop - (oldHeight - newHeight);
                    if (newScrollPosition > elementPosition - 80) {
                        win.scrollTo(0, newScrollPosition)
                    }
                    else {
                        win.scrollTo(0, elementPosition - 80);
                    }
                }
                else {
                    win.scrollTo(0, prevScrollTop + (newHeight - oldHeight));
                }
            }
            //reattach();
        }

        this.$jobsShown.text(`${fromIndex + 1}-${toIndex}`);
        this.showPreviousResults();
        this.setCardsHeight();
    }

    createCards(jobs) {

        return jobs.reduce((string, job) => string + this.createCard(job), '');
    }

    clearCards() {
        this.$cardsContainer.empty();
    }

    scrollToAnchor(anchor) {
        let el = $("[name='" + anchor + "']");
        let range = el.offset().top - 200;
        $('html,body').stop().animate({ scrollTop: range }, 'slow');

    }

    createCard(job) {

        const {
            Id: id,
            Title: title,
            Location: location,
            County: county,
            SalaryDescription: salary,
            ClosingDate: date

        } = job;
        
        let closingDateHtml = date !== "" ? `<strong>Closing Date: </strong>${date}<br/>` : "";
        return `
            <div class="o-vacancies-listing__card">
                
                <h3 class="o-vacancies-listing__card__header">${title}</h3>
                <div class="o-vacancies-listing__card__divider"></div>
                <p class="o-vacancies-listing__card__body">
                    ${location}, ${county}<br/><br/>                    
                    <strong>Salary: </strong>${salary}<br/>
                    ${closingDateHtml}
                </p>
                <a href="${this.linkUrl}?id=${id}" class="c-button" title="View role" tabindex="0">
                    <span>View role</span>
                </a>
            </div>
        `
    }

    hideLoader() {
        this.$loader.addClass('o-vacancies-listing__loader--hidden');
    }

    showLoader() {
        this.$loader.removeClass('o-vacancies-listing__loader--hidden');
    }

    updateLoaderText(text) {
        this.$loaderText.text(text);
    }

    checkStartParams(uri) {
        let newQuery;
        if (uri.indexOf('?') !== -1) {
            newQuery = uri.substring(uri.indexOf('?'), uri.length);
            console.log(newQuery);
        } else {
            newQuery = '';
        }
        return newQuery;
    }

    updateUrl(uri, query) {
        let newQuery;

        if (uri.indexOf('?') !== -1) {
            let newUri = uri.substring(0, uri.indexOf('?'))
            newQuery = newUri + query;
        } else {
            newQuery = uri + query;
        }
        return newQuery;
    }

    applyFilters(keyword, department, location) {
        this.location = location;
        this.keyword = keyword;
        this.department = department;

        this.updateSearch();
    }

    updateSearch() {
        let locationParam;
        let keywordParam;
        let departmentParam;
        let paramTab = [];
        let fetchParams = '';

        if (this.location && this.location != undefined) {
            locationParam = 'location=' + this.location;
            paramTab.push(locationParam);
        }
        if (this.keyword && this.keyword != undefined) {
            keywordParam = 'keyword=' + this.keyword;
            paramTab.push(keywordParam);
        }
        if (this.department && this.department != undefined) {
            departmentParam = 'department=' + this.department;
            paramTab.push(departmentParam);
        }
        if (paramTab.length > 0) {
            fetchParams = '?' + paramTab.join("&");
        }
        this.hidePreviousResults();
        this.clearCards();
        this.$element.removeClass('o-vacancies-listing--hidden');
        this.updateLoaderText('Updating...');
        this.showLoader();
        this.fetchJSON(fetchParams);
        this.scrollToListing();
    }

    setCardsHeight() {
        //set styles for boxes
        if (window.innerWidth < 1025) {
            return;
        }
        var cardList = $('.o-vacancies-listing__cards > .o-vacancies-listing__card');
        var cardHeaderMaxHeight = 0;
        var cardHeaderMinHeight = $('.o-vacancies-listing__cards > .o-vacancies-listing__card > .o-vacancies-listing__card__header:first-child').innerHeight();
        var cardBodyMaxHeight = 0;
        var cardBodyMinHeight = $('.o-vacancies-listing__cards > .o-vacancies-listing__card > .o-vacancies-listing__card__body').innerHeight();

        cardList.each(function (index, element) {
            var header = $(this).find('.o-vacancies-listing__card__header');
            var headerHeight = header.innerHeight();
            if (headerHeight > cardHeaderMaxHeight) {
                cardHeaderMaxHeight = headerHeight;
            }
            if (headerHeight < cardHeaderMinHeight) {
                cardHeaderMinHeight = headerHeight;
            }

            var body = $(this).find('.o-vacancies-listing__card__body');
            var bodyHeight = body.innerHeight();
            if (bodyHeight > cardBodyMaxHeight) {
                cardBodyMaxHeight = bodyHeight;
            }
            if (bodyHeight < cardBodyMinHeight) {
                cardBodyMinHeight = bodyHeight;
            }
        });

        var headerList = $('.o-vacancies-listing__cards .o-vacancies-listing__card__header');
        if (cardHeaderMaxHeight > cardHeaderMinHeight) {
            headerList.each(function (index, element) {
                this.style.minHeight = cardHeaderMaxHeight + 'px';
            });
        }

        var bodyList = $('.o-vacancies-listing__cards .o-vacancies-listing__card__body');
        if (cardBodyMaxHeight > cardBodyMinHeight) {
            bodyList.each(function (index, element) {
                this.style.minHeight = cardBodyMaxHeight + 'px';
            });
        }
    }

    scrollToListing() {
        const elementPosition = this.$element.offset().top;
        const win = this.$window.get(0);
        if (!utils.device.isLarge()) {
            utils.showBodyOverflow(true, true);
        }
        win.scrollTo(0, elementPosition - 80);
        if (!utils.device.isLarge()) {
            utils.hideBodyOverflow(true, true);
        }
    }
}

export default VacanciesListing;