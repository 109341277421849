﻿import $ from 'jquery';
import Component from 'Component';
import utils from 'utilities';
import Search from 'Search';

const { device } = utils;

class HeaderSearch extends Component {

    $searchContainer;
    $searchFormContainer;
    $link;
    Header;
    isOpen = false;
    eventTriggered = false;
    eventTriggeredTimeout;
    windowScrollPosition;

    constructor(element, Header) {
        super(element);
        this.Header = Header;

        this.init(() => {
            
            const { $element } = this;
        
            this.$searchContainer = $(".o-header-search__container", $element);           
            this.$link = $element.children('a');
            this.$searchFormContainer = $('.o-header-search__form-container', $element);
    
            this.SearchForm = new Search(this.$searchFormContainer);              
    
            this.setUpDeviceHandlers('setUpMediumSmall', () => {
                    this.$link.on("click", this.toggleOpen.bind(this));
                    $element.on('focus', this.open.bind(this));
                    $element.on('focusout', this.closeOnBlur.bind(this));
                    this.$searchContainer.on('focusin', this.cancelClose.bind(this));
                    this.$searchContainer.on('focusout', this.closeOnBlur.bind(this));
                })
                .setUpDeviceHandlers('setUpMediumTouch setUpLargeTouch', () => {
                    const { $element } = this;
    
                    this.$link.on('click', this.toggleOpen.bind(this));
                    $element.on('focus', this.open.bind(this));
                    $element.on('focusout', this.closeOnBlur.bind(this));
                    this.$searchContainer.on('focusin', this.cancelClose.bind(this));
                    this.$searchContainer.on('focusout', this.closeOnBlur.bind(this));
                    $element.on('mouseover', this.open.bind(this));
                })
                .setUpDeviceHandlers('setUpMediumDesktop setUpLargeDesktop', () => {
                    const { $element } = this;
                    $element.on('mouseover', this.open.bind(this));
                    $element.on('focus', this.open.bind(this));
                    $element.on('focusout', this.closeOnBlur.bind(this));
                    this.$searchContainer.on('focusin', this.cancelClose.bind(this));
                    this.$searchContainer.on('focusout', this.closeOnBlur.bind(this));
                })
                .bindDeviceHandler()
                .onBeforeSizeUpdate(() => {
                    this.$element.off();
                    this.$link.off();
                    this.$searchContainer.off();
                    this.$element.removeClass("c-header-search--active");
                    this.$searchContainer.removeClass("o-header-search__container--active");
                    this.isOpen = false;      
                });
        });
    }

    toggleOpen() {
        if (this.isOpen) {
            this.close();            
        } else {
            this.open();
            if (device.isMedium() & device.isTouch())
                this.Header.closeActiveNavItem();
        }            

        return this;
    }

    open() {              
        if (this.checkEventTriggered()) return;

        this.eventTriggered = true;

        const { Header } = this;

        this.$element.addClass("c-header-search--active");
        this.$searchContainer.addClass("o-header-search__container--active");
        this.$searchContainer.attr('aria-hidden', false);

        this.isOpen = true;
        
        if(Header.menuOpen) Header.closeMenu();       

        if (device.isMedium())
            Header.closeActiveNavItem();    

        if (device.isMediumSmall(true)) {
            const reattach = this.detachWindowEvents();
            this.Header.expandNavHeight();

            Header.calculateWindowScrollPosition();

            utils.hideBodyOverflow(true);
            Header.addContentContainerOverflow();

            reattach();
        }

        if (device.isMedium() && device.isTouch()) {
            utils.onClickOutside(this.Header.$element, this.close.bind(this));
        }

        this.eventTriggeredTimeout = setTimeout(() => {
            this.eventTriggered = false;
        }, 1);

        return this;
    }

    close() { 
        if (this.checkEventTriggered()) return;

        this.eventTriggered = true;

        if (this.isOpen) {             
            const { Header }  = this;

            this.isOpen = false;
                
            this.$element.removeClass("c-header-search--active");
            this.$searchContainer.removeClass("o-header-search__container--active");
            this.$searchContainer.attr('aria-hidden', true);

            if (device.isMediumSmall(true) && !this.Header.menuOpen) {
                Header.shrinkNavHeight();
                utils.showBodyOverflow(true);

                Header.resetWindowScrollPosition();

                Header.removeContentContainerOverflow();
            }                

            this.SearchForm.hideAutoComplete();
        }

        this.eventTriggeredTimeout = setTimeout(() => {
            this.eventTriggered = false;
        }, 1);

        return this;
    }

    closeOnBlur(event) {
        setTimeout(() => {
            if (!this.$element.has(document.activeElement).length && !this.$searchContainer.has(document.activeElement).length) {
                this.close();
            }
        }, 1);
    }

    cancelClose() {
        clearTimeout(this.eventTriggeredTimeout);
        this.eventTriggered = false;
    }

    checkEventTriggered() {
        if (this.eventTriggered) {
            this.eventTriggered = false;
            clearTimeout(this.eventTriggeredTimeout);
            return true;
        } else return false;
    }

}

export default HeaderSearch;
