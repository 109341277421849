import Component from 'Component';
import $ from 'jquery';
import utils from 'utilities';
import App from 'App';


class VacanciesListingFilters extends Component {

    keywordQuery = '';
    locationQuery = '';
    departmentQuery = '';

    constructor(element, VacanciesListing) {
        super(element);

        this.VacanciesListings = VacanciesListing;

        this.init(() => {
            const { $element } = this;

            this.$keyword = $('#keyword', $element);
            this.$location = $('#location', $element);
            this.$department = $('select#department', $element);

            this.$submit = $('.primary-button', $element);

            this.$keyword.on('keyup', this.updateKeyword.bind(this));
            this.$location.on('keyup', this.updateLocation.bind(this));
            this.$department.on('change', this.updateDepartment.bind(this));

            this.$closeButton = $('.o-vacancies-listing-filters__header-mobile__close', this.$element);


            this.setUpDeviceHandlers('setUpSmall setUpMediumSmall setUpMediumTouch setUpMediumDesktop', () => {
                this.$closeButton.on('click', this.closeLightbox.bind(this));
                this.$element.on('submit', this.handleUpdateMobile.bind(this));
            })
            .setUpDeviceHandlers('setUpLargeTouch setUpLargeDesktop', () => {
                this.$element.on('submit', this.handleUpdate.bind(this));
            })
            .bindDeviceHandler()
            .onBeforeSizeUpdate(() => {
                this.$element.off();
                this.$closeButton.off()
            });
        });
    }

    closeLightbox() {
        this.$element.removeClass('o-vacancies-listing-filters--visible');
        utils.showBodyOverflow(true, true);
    }
    updateDepartment(e) {
        let target = e.target;
        let index = target.selectedIndex;
        this.departmentQuery = target.options[index].value;
    }
    updateKeyword(e) {
        this.keywordQuery = e.target.value;
    }
    updateLocation(e) {
        this.locationQuery = e.target.value;
    }
    handleUpdate(e) {
        const {
            keywordQuery,
            locationQuery,
            departmentQuery,
            VacanciesListings
        } = this;

        let searchQuery

        e.preventDefault();

        this.applyFiltersToListings(keywordQuery, departmentQuery, locationQuery);

    }

    handleUpdateMobile(e) {
        const success = this.handleUpdate(e)
        if (success !== null)
            this.closeLightbox();
    }

    applyFiltersToListings(k, d, l) {
        App.applyVacanciesFilters(k, d, l);
    }

    clearForm() {
        this.$keyword.val('');
        this.$location.val('');
        this.$department.val('');
    }


}

export default VacanciesListingFilters;