﻿import $ from 'jquery';
import Component from 'Component';
import App from 'App';
import utils from 'utilities';

class FullWidthContent extends Component {
    $contentContainer;
    $image;

    constructor(selector) {
        super(selector, true);

        this.init(() => {

            const { $element } = this;

            // Remove trailing space in the selector
            this.$contentContainer = $('.c-promotion-block__content', $element);
            this.$image = $('.content-background-image', this.$contentContainer);
            
            if (this.$image && this.$image.length) {
                // Wait until document is ready to ensure picturefill has executed
                $(() => {
                    utils.mapImgToBackground(this.$image, this.$contentContainer);
                });
            }

            this.setUpDeviceHandlers('setUpMediumTouch setUpLargeTouch', () => {
                this.$element.css('cursor', 'pointer');
            })
            .bindDeviceHandler()
            .onBeforeSizeUpdate(() => this.$element.css('cursor', ''));

            this.addEventHandler('scrolledIntoView', () => {
                if (utils.device.isLarge()) {
                    App.setSidebarState('collapsed');
                }
            });
        });
    }     
}

export default FullWidthContent;
