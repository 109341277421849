import $ from 'jquery';
import Component from 'Component';
import utils from 'utilities';
import SitePlanPDF from '../../Developments/SitePlan/Components/site-plan-pdf';


class PlotSitePlan extends Component {
    constructor(selector) {
        super(selector, true);

        this.init(() => {
            this.element = this.$element[0];
            const zoomInSelector = '.o-site-plan__map-controls--zoom--in';
            const zoomOutSelector = '.o-site-plan__map-controls--zoom--out';
            const showPdf = this.element.querySelector('.o-site-plan__map-container')
            if (showPdf) {
                this.SitePlanPDF = new SitePlanPDF('.o-site-plan__map-container', zoomInSelector, zoomOutSelector);
            }
        });
    }

}

export default PlotSitePlan;
