﻿import $ from 'jquery';
import Component from 'Component';
import utils from 'utilities';
import BottomBar from './Components/bottom-bar';

class VacanciesSidebar extends Component {

    $contentContainer;
    height = 0;
    wasFullscreen = false;
    BottomBar;
    currentState = 'default';
    viewportLarge = false;
    $sidebar;

    constructor(selector, collapsable = true) {
        super(selector);
      
        this.collapsable = collapsable;

        this.init(() => {

            const { $element } = this;
            this.$contentContainer = $('.c-vacancies-sidebar__content', $element);
            this.$developmentListingsFilters = $('.o-vacancies-listing-filters', $element);
            this.$sidebarForm = $('.o-vacancies-listing-filters__form', $element);
            this.$sidebarFormSubmit = $('.o-vacancies-listing-filters__submit', $element);

            //this.setHeight();
            this.setUpDeviceHandlers('setUpSmall setUpMediumSmall setUpMediumTouch setUpMediumDesktop', () => {

                this.removeHeight();

                if (!this.BottomBar) this.BottomBar = new BottomBar($element, $element.parent(), this);
                else this.BottomBar.reinit();

            })
                .setUpDeviceHandlers('setUpLargeTouch setUpLargeDesktop', () => {

                    this.viewportLarge = true;

                    if (this.checkContentOverflown()) {
                        this.alignContentTop();
                    }

                    if (this.BottomBar) this.BottomBar.destroy();

                    utils.showBodyOverflow(true, true);

                    this.$document.on('fullscreenchange webkitfullscreenchange mozfullscreenchange MSFullscreenChange', () => {
                        this.wasFullscreen = true;
                    })
                })
                .setUpDeviceHandlers('setUpSmall setUpMediumSmall setUpMediumDesktop setUpMediumTouch', () => {
                    this.viewportLarge = false;
                })
                .bindDeviceHandler()
                .onBeforeSizeUpdate(() => {
                    if (!this.wasFullscreen) {
                        this.resetContentAlignment();
                    }
                    this.wasFullscreen = false;
                });
        });
    }

    showListingsFiltersLightBox(){
        this.$developmentListingsFilters.addClass('o-vacancies-listing-filters--visible');
        utils.hideBodyOverflow(true, true);
    }


    setHeight(value = this.height) {

        const fadeIn = !this.height;

        this.height = value;

        this.$contentContainer.css('height', `${value}px`);

        fadeIn && this.fadeIn();
    }

    fadeIn() {
        this.$element.addClass("c-vacancies-sidebar--visible");
    }

    removeHeight() {
        this.$contentContainer.css('height', '');
    }

    checkContentOverflown() {
        return utils.isElementOverflown(this.$contentContainer);
    }

    alignContentTop() {
        this.$contentContainer.css('justify-content', 'start');
    }

    resetContentAlignment() {
        this.$contentContainer.css('justify-content', '');
    }

    setState(state) {

        //if the state of the sidebar is already the requested state... BAIL!
        if (state === this.currentState) return;

        const { viewportLarge } = this;

        switch (state) {
            case 'default':
                //if the device is large, the default state should be expanded and show the development details
                if (viewportLarge) {
                    this.showVacanciesSearchSidebar().expand();
                    this.BottomBar && this.BottomBar.setState('visible');
                }
                break;
            case 'collapsed':
                //if the device is large, the collapsed state should be collapsed and show the development details
                if (viewportLarge && this.collapsable) this.showVacanciesSearchCTA().collapse();
                this.BottomBar && this.BottomBar.setState('visible');
                break;
            case 'primary':
                // the bottom bar only exists if the viewport is less than large breakpoint
                // its primary state is hidden
                this.BottomBar && this.BottomBar.setState('hidden');
                break;
            case 'visible':
                // the bottom bar only exists if the viewport is less than large breakpoint
                // its primary state is hidden
                this.BottomBar && this.BottomBar.setState('visible');
                break;
        }

        //update current state
        this.currentState = state;

    }


    collapse() {

        const { $element } = this;

        $element.addClass('c-vacancies-sidebar--collapsed');

        return this;

    }

    expand() {

        const { $element } = this;

        $element.removeClass('c-vacancies-sidebar--collapsed');

        return this;

    }


    showVacanciesSearchSidebar() {

        // display sidebar search form
        this.$sidebarForm.css('display', 'block'); 
        this.$sidebarFormSubmit.removeClass('submit-top');

        return this;
    }

    showVacanciesSearchCTA() {

        // hide sidebar search form and display only button for search
        this.$sidebarForm.css('display', 'none');
        this.$sidebarFormSubmit.addClass('submit-top');

        return this;
    }
}

export default VacanciesSidebar;