import Component from 'Component';

class Dropdown extends Component {
    value;

    constructor(element, handleUpdatedValue) {
        super(element);

        this.handleUpdatedValue = handleUpdatedValue;

        this.init(() => {
            this.value = this.$element.val();
            this.$element.on('change', this.handleChange.bind(this));
        });
    }

    handleChange(e) {
        const {
            value
        } = e.target;

        this.value = value;
        this.handleUpdatedValue && this.handleUpdatedValue(value);
    }

    updateValue(value) {
        this.value = value;
        this.$element.get(0).value = value;
    }
}

export default Dropdown;