import $ from 'jquery';

class ImageCarousel {
    constructor(selector) {
        this.$element = $(selector);
        this.isExperienceEditor = $('body').hasClass('u-experience-editor');
        this.$nextArrow = this.$element.find('.c-carousel-next');
        this.$prevArrow = this.$element.find('.c-carousel-prev');
        this.init();
    }

    init() {
        const $mainCarousel = this.$element.find('.main-carousel .c-carousel-slides');
        const $thumbCarousel = this.$element.find('.thumbnail-carousel .c-carousel-slides');

        const slideCount = $thumbCarousel.find('.c-carousel-slides__slide').length;
        const maxWidth = slideCount * 140 + 'px';

        $mainCarousel.slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          prevArrow: this.$prevArrow,
          nextArrow: this.$nextArrow,
          fade: true,
          infinite: false,
          autoplay: !this.isExperienceEditor,
          autoplaySpeed: 4000,
          dots: slideCount > 2,
          lazyLoad: 'ondemand',
          dotsClass: 'c-carousel-pager',
          asNavFor: '.thumbnail-carousel .c-carousel-slides',
        });

        $thumbCarousel.slick({
          slidesToShow: Math.floor(window.innerWidth / 2 / 130),
          asNavFor: '.main-carousel .c-carousel-slides',
          dots: false,
          variableWidth: true,
          focusOnSelect: true,
          infinite: !this.isExperienceEditor,
          swipeToSlide: true,
          centerMode: false,
          draggable: true,
        });
    }
}

export default ImageCarousel;